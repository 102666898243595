export type TEndPointsManagerComponentOptions = {
    data?: any,
    meta?: any,
    variants?: any,
    onClick?: Function
}

export const FIELDS = {
    NAME: "$name",
    ACCESS: "$access"
}