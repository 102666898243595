import { ROLES } from "../../dto";
import Logger from "../../helpers/logger";
import { createSlice } from "@reduxjs/toolkit";
import { Profile_I } from "../../types/intarfaces";

const initialState = {
    data: <Profile_I>{
        role_id: ROLES.ANY
    }
}

export const profileSlice = createSlice({
    name: 'profile',
    initialState,
    reducers: {
        set( state, { payload }) {
            /* debug */ Logger.reducer( "profileSlice.set([ payload ])", { state, payload }) ;
            state.data.name = payload?.name ;
            state.data.id = payload?.uid ;
            state.data.$id = +payload?.id ;
            state.data.role_id = payload?.role_id ;
            state.data.position = payload?.position ;
            state.data.fullname = payload?.fullname ;
            state.data.companies = payload?.companies ;
            state.data.signer = payload?.signer ;
            state.data.dep_id = payload?.dep_id ;
            state.data.dep_name = payload?.dep_name ;
            state.data.dep_title = payload?.dep_title ;
            state.data.dep_description = payload?.dep_description ;
        },
        destroy( state ) {
            /* debug */ Logger.reducer( "profileSlice.destroy([ state ])", state ) ;
            state.data = initialState.data ;
        }
    }
})

const { reducer, actions } = profileSlice;
export const profileActions = actions;
export default reducer;
