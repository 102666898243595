import React from "react";
import Menu from "../menu"
import { isNil } from "lodash"
import { Outlet } from "react-router-dom";
import "../../../styles/components/ui-kit/layout.scss";
import { useReduxSelector } from "../../../hooks/redux.hook";

function Layout({ children }) {

    const { permissions:{ data: permissions } } = useReduxSelector( ({ permissions }) => ({ permissions }) ) ;

    function getMenuItems() {

        let items = [] ;
        for( let i = 0 ; i < permissions.length ; i++ ) {
            const perm = permissions[i] ;

            // если меню скрыто
            if( perm?.json?.hidden === true ) continue ;

            // если меню является вложенным в другое (меню) ;
            if( ! isNil( perm?.parent_id ) ) continue ;

            items.push({
                path: perm?.path,
                title: perm?.description,
                i18n: perm?.i18n
            }) ;

        }

        return items ;
    }

    const menuItems = getMenuItems() ;

    return (
        <div className="Layout">
            <Menu items={ menuItems } />
            { children }
            <Outlet/>
        </div>
    )
}

Layout.defaultProps = {}
Layout.propTypes = {}

export default Layout ;
