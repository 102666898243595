import {createNewUser, getAllUsers, updateUsers, changeUserPassword } from "../helpers/api.services";
import Logger from "../helpers/logger";
import { useReduxDispatch, useReduxSelector } from "./redux.hook";
import { NotificationManager } from "react-notifications";
import { usersActions } from "../store/slices/users.slice";
import {useTranslation} from "react-i18next";

export type TUsersHook = {
    load: Function,
    update: Function,
    create: Function,
    changePassword: Function,
    isReady: boolean,
    data: any,
    error: any
}
export default function useUsersHook(): TUsersHook {
    const { t } = useTranslation() ;

    const dispatch = useReduxDispatch();

    const { data, error, fetched, fetching } = useReduxSelector( ({ users }) => users ) ;
    const isReady = fetched && ! fetching ;

    async function load( forced: boolean = true ) {
        /* debug */ Logger.saga( "useUsersHook.load([ forced ])", forced ) ;

        if( ! forced && fetched && ! fetching ) return ;

        dispatch( usersActions.setFetch({ fetching: true, fetched: false }) ) ;

        let payload: any,
            error: any ;

        try { payload = await getAllUsers() }
        catch ( exception: any ) {
            const message = exception?.message || "[ useUsersHook ] unhandled error" ;
            error = { message }
            /* error */ Logger.error( "useUsersHook.load([ error ])", { error } ) ;
        }

        if( error ) {
            NotificationManager.error( error.message )
            dispatch( usersActions.setData({ error, data: [] }) ) ;
        } else {
            const { data, meta } = payload?.data ;
            /* debug */ Logger.success( "useUsersHook.load([ data ])", data ) ;
            dispatch( usersActions.setData({ data, meta, error: null }) ) ;
        }

        dispatch( usersActions.setFetch({ fetching: false, fetched: true }) ) ;
    }
    async function update( id: number, data: any ) {
        let payload: any ;

        try { payload = await updateUsers( id, data ) }
        catch ( error: any ) {
            const message = error?.message || "[ useUsersHook.update ] unhandled error" ;
            NotificationManager.error( message ) ;
            return Promise.resolve({ error:{ message } })
        }

        /* debug */ Logger.success( "useUsersHook.update([ payload ])", payload ) ;

        const { data: $data } = payload ;
        if( $data ) {
            if( $data?.error ) NotificationManager.error( $data?.error.message ) ;
            dispatch( usersActions.update( $data ) ) ;
        }

        return Promise.resolve( $data ) ;
    }
    async function create( data: any ) {
        /* debug */ Logger.warn( "useUsersHook.create([ data ])", data ) ;

        let payload: any ;

        try { payload = await createNewUser( data ) }
        catch ( error: any ) {
            const message = error?.message || "[ useUsersHook.create ] unhandled error" ;
            NotificationManager.error( message ) ;
            return Promise.resolve({ error:{ message } })
        }

        /* debug */ Logger.success( "useUsersHook.create([ payload ])", payload ) ;

        const { data: $data } = payload ;
        if( $data ) {
            if( $data?.error ) NotificationManager.error( $data?.error.message ) ;
            dispatch( usersActions.create( $data ) ) ;
        }

        return Promise.resolve( $data ) ;
    }
    async function changePassword( id: number, data: any ) {
        let payload: any ;

        try { payload = await changeUserPassword( id, data ) }
        catch ( error: any ) {
            const message = error?.message || "[ useUsersHook.changePassword ] unhandled error" ;
            NotificationManager.error( message ) ;
            return Promise.resolve({ error:{ message } })
        }

        /* debug */ Logger.success( "useUsersHook.changePassword([ payload ])", payload ) ;

        const { data: $data } = payload ;

        /* debug */ Logger.log( "useUsersHook.changePassword([ $data ])", $data ) ;

        if( $data ) {
            const error = $data?.error?.message ;

            ! error
            ? NotificationManager.info( `${ t( "Users.modal.changePassword.success" ) }` )
            : NotificationManager.error( error ) ;

            dispatch( usersActions.setPassword( $data ) ) ;
        }

        return Promise.resolve( $data ) ;
    }

    return { load, changePassword, update, create, isReady, data, error }
}
