import React, { useEffect, useState } from "react";
import Logger from "../../../helpers/logger";
import TicketPropertyComponent from "./TicketPropertyComponent";
import { PERMISSIONS, ROLES, UI } from "../../../dto";
import Permission from "../../ui-kit/permission";
import { identity, pickBy } from "lodash";
import usePermissionsHook from "../../../hooks/usePermissionsHook";
import { useTranslation } from "react-i18next";
import Message from "../../ui-kit/message";
import useTicketPropertyHook from "../../../hooks/useTicketProperty.hook";
import { useReduxDispatch, useReduxSelector } from "../../../hooks/redux.hook";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Modal } from "antd";
import useMyTickets from "../../../hooks/useMyTickets.hook";
import Button from "../../ui-kit/button";
import { getMyActiveCompany } from "../../../helpers/cross-messages.services";
import { ticketPropertyActions } from "../../../store/slices/ticketProperty.slice";
import { DepsList, EmployeesList, ReAssignersSelector } from "../../ui-kit/reassigners";
import useDepsHook from "../../../hooks/useDeps.hook";
import useI18N from "../../../hooks/useI18N.hook";
import { reAssignTaskToDep, reAssignTaskToEmployee } from "../../../helpers/api.services";
import { NotificationManager } from "react-notifications";
import useEmployeesHook from "../../../hooks/useEmployees.hook";
import "../../../styles/components/ticket_property.scss";

export default function TicketProperty( props ) {

    const id = props.id ;
    const [ isRemoveConfirmation, openRemoveConfirmation ] = useState( false ) ;
    const params = useParams();
    const { data: profile } = useReduxSelector( ({ profile }) => profile ) ;
    const { role_id: role } = profile ;
    const navigate = useNavigate();
    const { state } = useLocation();
    const mode = state?.mode || TicketPropertyComponent.defaultProps.mode ;
    const ticketProperty = useReduxSelector( ({ ticketProperty }) => ticketProperty ) ;
    const dispatch = useReduxDispatch();
    const { updateFileProperty } = ticketPropertyActions;
    const { t } = useTranslation() ;
    const { getPermissionsById } = usePermissionsHook() ;
    const { initialize, update, readByID, reset, sync, setSigners, assignToDep, assignToCompany } = useTicketPropertyHook() ;
    const permissions = getPermissionsById( id ) ;
    const { removeTicketByID, assignTo } = useMyTickets() ;

    const [ reassignTo, setReAssignerType ] = useState() ;
    const isReAssignEnabled = Boolean( reassignTo ) ;

    const depsController = useDepsHook() ;
    const employeesController = useEmployeesHook() ;

    const { fromI18N } = useI18N() ;

    /* debug */ Logger.info("TicketProperty([ employeesController ])", employeesController ) ;

    function _updateFileAction( data ) {
        /* debug */ Logger.warn( "TicketProperty::_updateFileAction([ data ])", data ) ;
        return dispatch( updateFileProperty( data ) ) ;
    }
    function _sendToDepAction( id ) {
        /* debug */ Logger.warn( "TicketProperty::_sendToDepAction([!])", id ) ;
        assignToDep( id ) ;
    }
    function _sendToCompanyAction( id ) {
        /* debug */ Logger.warn( `TicketProperty::_sendToCompanyAction([ ${ id } ])`, ticketProperty.data ) ;
        assignToCompany( ticketProperty.data.id ) ;
    }

    async function _syncAction( callback ) {

        /* debug */ Logger.warn( "TicketProperty::_syncAction([!])", ticketProperty?.data ) ;
        const p = ticketProperty?.data ;
        const ticket_id = p?.id ;
        const $signers = {} ;
        const $filesStatuses = {} ;

        ( p?.$files || [] ).forEach(({ hash, signers, status_id, status_description }) => {
            $signers[ hash ] = signers ;
            $filesStatuses[ hash ] = { status_id, status_description } ;
        } ) ;

        const data = {
            dep_id: p?.dep_id,
            company_id: p?.company_id,
            subject: p?.subject,
            description: p?.description,
            ... ( [ ROLES.OPERATOR ].includes( role ) ? { status_id: p?.status_id } : {} ),
            ... ( [ ROLES.SUPERVISOR ].includes( role ) ? { review_status_id: p?.review_status_id, review_status_description: p?.review_status_description } : {} ),
            $signers,
            $filesStatuses,
        }

        /* debug */ Logger.warn( "TicketProperty::_syncAction([ ticket_id, data, callback ])", { ticket_id, data, callback }) ;

        await sync( ticket_id, data, callback ) ;
    }
    function _initializeAction() {
        const ticket_id = params?.ticket_id ;
        /* debug */ Logger.log("TicketProperty() _initializeAction([ ticket_id ])", ticket_id ) ;
        readByID( ticket_id ) ;
    }
    async function _createTicketAction() {

        /* debug */ Logger.log("TicketProperty() _createTicketAction([ ticket_id, profile ])", profile ) ;

        let company,
            company_id,
            dep_id ;

        if( role === ROLES.CLIENT ) {
            company = await getMyActiveCompany() ;
            company_id = company?.data?.clientRef ;

        } else {
            dep_id = profile?.dep_id ;
        }

        const params = pickBy({ company_id, dep_id }, identity ) ;
        /* debug */ Logger.log("TicketProperty() _createTicketAction([ params ])", params ) ;

        initialize( params ) ;
    }
    function _removeAction() {
        openRemoveConfirmation( true ) ;
    }
    async function _assignAction( ticket_id, user_id ) {
        const isSuccess = await assignTo( ticket_id, user_id ) ;
        isSuccess && navigate("/")
    }
    function _refreshAction( ticket_id ) {
        /* debug */ Logger.warn( "TicketProperty() _refreshAction([ ticket_id ])", ticket_id ) ;
        readByID( ticket_id ) ;
    }
    function _reassignAction( type ) {
        /* debug */ Logger.warn( "TicketProperty::_reassignAction([ type ])", type ) ;
        setReAssignerType( type ) ;
    }

    function getActionsFor( state ) {
        return {
            refresh: _refreshAction,
            initialize: state === UI.CREATE_NEW_TICKET ? _createTicketAction : _initializeAction,
            update,
            reset,
            setSigners,
            sync: _syncAction,
            remove: _removeAction,
            assign: _assignAction,
            updateFile: _updateFileAction,
            sendToDep: _sendToDepAction,
            sendToCompany: _sendToCompanyAction,
            reassignTo: _reassignAction
        }
    }
    function clearRecycleBin () {
        openRemoveConfirmation( false );
    }
    async function remove() {
        const ticket_id = ticketProperty?.data?.id ;
        await removeTicketByID( ticket_id ) ;
        return navigate("/")
    }
    function showDeleteFileConfirmation() {
        if( isRemoveConfirmation ) {
            function onYes( handler )  {
                remove() ;
                handler.destroy() ;
            }
            function onClose( handler ) {
                clearRecycleBin() ;
                handler.destroy() ;
            }
            const handler = Modal.confirm({
                title: t("common.confirmation"),
                content: t( "prompts.removeTicket", ticketProperty?.data ),
                footer: (
                    <span className="ant-modal-footer">
                        <Button key="yes" className="save" label={ t( "common.yes" ) } onClick={ () => onYes( handler ) } />
                        <Button key="cancel" ghost className="cancel" label={ t( "common.cancel" ) } onClick={ () => onClose( handler ) } />
                    </span>
                )
            });
        }
    }

    function closeAllModals() {
        setReAssignerType( null ) ;
        Modal.destroyAll() ;
    }

    const ReAssignEvents = {
        onSelectDep: async ( dep_id ) => {
            const ticket_id = ticketProperty.data.id ;
            /* debug */ Logger.info("TicketProperty() ReAssignEvents.onSelectDep([ ticket_id, dep_id ])", { ticket_id, dep_id } ) ;

            let error ;
            try { await reAssignTaskToDep( ticket_id, dep_id ) }
            catch( exception ) { error = exception  }
            finally { ! error && closeAllModals() }

            ! error
            ? navigate("/")
            : NotificationManager.error( error?.message )
        },
        onSelectEmployee: async ( employee_id ) => {
            const ticket_id = ticketProperty.data.id ;

            /* debug */ Logger.info("TicketProperty() onAssignToEmployeeSelectedHandler([ ticket_id, employee_id ])", { ticket_id, employee_id } ) ;

            let error ;
            try { await reAssignTaskToEmployee( ticket_id, employee_id ) }
            catch( exception ) { error = exception  }
            finally { ! error && closeAllModals() }

            ! error
            ? navigate("/")
            : NotificationManager.error( error?.message )
        },
    }
    function modalDialogsRenderer() {
        /* debug */ Logger.log( `TicketProperty() showReAssignerDialog()` ) ;
        switch ( reassignTo ) {
            case ReAssignersSelector.ITEMS.DEP: return (
                <DepsList
                    title={ t("prompts.chooseDep") }
                    fromI18N={ fromI18N }
                    initialize={ depsController.initialize }
                    fetching={ depsController.isReady }
                    data={ depsController.data }
                    selected={ profile?.dep_id }
                    onSelect={ ReAssignEvents.onSelectDep }
                />
            );
            case ReAssignersSelector.ITEMS.EMPLOYEE: return (
                <EmployeesList
                    title={ t("prompts.chooseEmployee") }
                    fromI18N={ fromI18N }
                    initialize={ employeesController.initialize }
                    fetching={ employeesController.isReady }
                    data={ employeesController.data }
                    selected={ profile?.$id }
                    onSelect={ ReAssignEvents.onSelectEmployee }
                />
            )
            default: return null ;
        }
    }

    function destructor() {
        closeAllModals() ;
        reset() ;
    }
    function constructor() {
        depsController.initialize() ;
        return destructor ;
    }

    useEffect( () => { constructor() }, [] )
    useEffect( () => { showDeleteFileConfirmation() }, [ isRemoveConfirmation ]) ;

    const actions = getActionsFor( id ) ;
    const modals = modalDialogsRenderer() ;

    return (
        <div className="TicketProperty">
            <Modal title={ null } open={ isReAssignEnabled } onCancel={ closeAllModals } footer={ null }>
                { modals }
            </Modal>
            <Permission.Verifycator
                id={ id }
                name={ PERMISSIONS.READ }
                value="1"
                whileFailed={
                    <Message
                        className="ErrorMessage"
                        type={ Message.types.ERROR }
                        header="ACCESS DENIED"
                        message={ t( "errors.permissionAccessDenied" ) }
                    />
                }>
                    <TicketPropertyComponent
                        id={ id }
                        profile={ profile }
                        mode={ mode }
                        store={ ticketProperty }
                        permissions={ permissions }
                        actions={ actions }
                        view={ state?.view || TicketPropertyComponent.VIEWS.FILE_MANAGER }
                    />
            </Permission.Verifycator>
        </div>
    )
}