import { useEffect } from "react"
import Logger from "../../helpers/logger";
import { getSettings } from "../../data/settings";
import useSocketHook from "../../hooks/useSocket.hook"
import PropTypes from "prop-types";
import { NotificationManager } from "react-notifications";
import { useReduxSelector } from "../../hooks/redux.hook";

const settings = getSettings() ;

function Socket( props ) {
    /* debug */ Logger.info( "Socket([ props ])", props ) ;

    const { options } = props ;
    const { init, isReady, send, doPingPong, error, socket, destroy } = useSocketHook() ;
    const { data: profile } = useReduxSelector( ({ profile }) => profile ) ;

    function componentDidMount() {
        /* debug */ Logger.log( "Socket() componentDidMount([ profile ])", profile ) ;
        init( options.host ) ;
    }

    function onConnectedHandler() {
        if( isReady ) doPingPong( .75 /* seconds */ ) ;
    }

    useEffect( () => { return () => destroy() }, [] ) ;
    useEffect( () => { componentDidMount() }, [ profile ] ) ;
    useEffect( () => { onConnectedHandler() }, [ isReady ] ) ;
    useEffect( () => { error && NotificationManager.error( error?.message ) }, [ error ] ) ;

    return <span/> ;
}
Socket.defaultProps = {
    options: {
        host: settings.webSocketHost
    }
}
Socket.propTypes = {
    options: PropTypes.shape({
        host: PropTypes.string,
        port: PropTypes.number
    }).isRequired
}

export default Socket ;