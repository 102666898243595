import { enterToChatRoom, getChat, leaveChatRoom, sendChatMessage } from "../helpers/api.services";
import Logger from "../helpers/logger";

export type TChatHook = {
    init: Function,
    send: Function,
    enter: Function,
    leave: Function
}
function useChatHook( id: number ): TChatHook {
    /* debug */ Logger.success( "useChatHook([ id ])", id ) ;

    function send( text: string, data: any ) {
        return sendChatMessage( id, text, data ) ;
    }
    function init() {
        return getChat( id ) ;
    }
    function enter() {
        /* debug */ Logger.reducer( "useChatHook() enter([ id ])", id ) ;
        enterToChatRoom( id ) ;
    }
    function leave(): void {
        /* debug */ Logger.reducer( "useChatHook() leave([ id ])", id ) ;
        leaveChatRoom( id ) ;
    }

    return { enter, leave, send, init }
}

export default useChatHook ;