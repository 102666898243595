import React from "prop-types";
import PropTypes from "prop-types";
import { Tooltip } from "antd";
import ReviewStatusLabel from "../label";
import ReviewStatusSelector from "../selector";
import { PERMISSIONS, UI } from "../../../../dto";
import Permission from "../../permission";
import { MessageFilled } from '@ant-design/icons';
import "../../../../styles/components/ui-kit/status_review.scss";

function ReviewStatusWrapper( props ) {
    const { id, description, placement, children, onClose, onSave } = props ;
    const $description = (
        description && <>
            &nbsp;
            <MessageFilled />
        </>
    )
    return (
        <Permission.Verifycator
            id={ ReviewStatusWrapper.ID }
            name={ PERMISSIONS.UPDATE }
            value="1"
            whileFailed={
                <ReviewStatusLabel id={ id }>
                    <Tooltip placement="top" title={ description }>
                        { children }
                        { $description }
                    </Tooltip>
                </ReviewStatusLabel>
            }
        >
            <ReviewStatusSelector id={ id } description={ description } placement={ placement } onSave={ onSave } onClose={ onClose }>
                <Tooltip placement="top" title={ description }>
                    { children }
                    { $description }
                </Tooltip>
            </ReviewStatusSelector>
        </Permission.Verifycator>
    )
}
ReviewStatusWrapper.ID = UI.TICKET_VERIFICATION_STATUS ;
ReviewStatusWrapper.defaultProps = {
    placement: "bottom",
    onClose: () => ({}),
    onSave: () => ({}),
}
ReviewStatusWrapper.propTypes = {
    placement: PropTypes.string,
    id: PropTypes.string,
    description: PropTypes.string,
    onClose: PropTypes.func,
    onSave: PropTypes.func,
}

export default ReviewStatusWrapper ;