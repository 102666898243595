import React from "react";
import PropTypes from "prop-types";
import ClassNames from "classnames";
import { get } from "lodash";
import { getSettings } from "../../../data/settings";
import { DownloadOutlined } from "@ant-design/icons";
import Logger from "../../../helpers/logger";
import { Tooltip } from "antd";
import { useTranslation } from "react-i18next";
import { downloadFile } from "../../../helpers/api.services";
import { HTTP_X_HEADERS } from "../../../dto";
import "../../../styles/components/ui-kit/download_file_link.scss";

const settings = getSettings();

function DownloadFileLink( props ) {
    /* debug */ Logger.info( "DownloadFileLink([ props ])", props );

    const { t } = useTranslation();
    const { icon, remoteFile, className } = props;

    function onClickHandler() {
        const { hash, name } = remoteFile;
        const { origin } = new URL( settings?.apiHostUrl || "" );
        const url = `${ origin }/file/${ hash }/download`;

        /* debug */ Logger.warn( "DownloadFileLink() onClickHandler([ name, url ])", { name, url } );

        const events = {
            success: ( response ) => {
                /* debug */ Logger.success( "DownloadFileLink() onClickHandler::events.success([ response ])", response, response.headers.toJSON() );

                const fileName = decodeURIComponent( get( response, `headers.${ HTTP_X_HEADERS.FILE_NAME }`, "file" ) ) ;

                /* debug */ Logger.warn( "DownloadFileLink() onClickHandler([ fileName ])", fileName );

                const href = URL.createObjectURL( response.data );
                const link = document.createElement( 'a' );
                link.href = href;
                link.setAttribute( 'download', fileName );
                document.body.appendChild( link );
                link.click();
                document.body.removeChild( link );
                URL.revokeObjectURL( href );

            }, failed: ( error ) => {
                /* debug */ Logger.error( "DownloadFileLink() onClickHandler::events.failed([ error ])", error );
            }
        }

        downloadFile( hash ).then( events.success ).catch( events.failed );
    }

    return ( <div className={ ClassNames( "DownloadFileLink", className ) } onClick={ onClickHandler }>
            <Tooltip placement="top" title={ t( "FilesExplorer.tooltips.download" ) }>
                { icon || <DownloadOutlined className="download"/> }
            </Tooltip>
        </div> )
}
DownloadFileLink.defaltProps = {}
DownloadFileLink.propTypes = {
    remoteFile: PropTypes.shape( {
        hash: PropTypes.string, name: PropTypes.string
    } ), icon: PropTypes.element, className: PropTypes.string
}

export default DownloadFileLink;