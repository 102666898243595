import Axios from 'axios';
import Logger from '../helpers/logger';
import { getSettings } from "../data/settings";
import SessionHelper from "./session";
import { TApplicationSettings, TResponseError, TString } from "../types";
import { APIAccessTokenPrefix } from "../data/constants";
import { get, isArray, isEmpty, isString, keys, set } from "lodash";
import { getLanguageFromLocalStore } from "./common";

function BootstrapAPI() {

    const settings: TApplicationSettings = getSettings();
    const request = Axios.create( { baseURL: settings.apiHostUrl || "" } );
    const onRequestHandler = async ( config: any ): Promise<any> => {
        const token: TString = SessionHelper.getToken() ;

        if( ! config.headers[ "Content-Type" ] ) {
            config.headers[ "Content-Type" ] = "application/json" ;
        }

        config.headers[ "Accept-Language" ] = getLanguageFromLocalStore() ;

        if ( token ) { config.headers.Authorization = `${ APIAccessTokenPrefix } ${ token }` }

        return config;
    }
    const onResponseHandler = ( response: any ): any => {
        /* debug */ Logger.success( '[→]', 'onResponseHandler([ response ])', response );
        return response;
    }
    const onErrorHandler = ( exception: any ): Promise<TResponseError> => {
        /* debug */ Logger.error( '[→][!]', 'onErrorHandler([ exception ])', { exception } );

        function parseDataMessages( data: object ): any {
            if( isEmpty( data ) ) return null ;

            let _data: object = {} ;
            const fieldKeys = keys( data ) ;
            fieldKeys.forEach( ( key ) => {
                const context: any = get( data, key, null ) ;
                /* debug */ Logger.warn( '[→][!]', 'onErrorHandler([ key, context ])', { key, context } );
                if( isArray( context ) ) set( _data, key, context.join(" ") ) ;
                if( isString( context ) ) set( _data, key, context.toString() );
            } )

            return _data ;
        }

        const error: TResponseError = {
            message: exception?.response?.data?.message || exception?.response?.statusText || exception.message || "Unhandled payload",
            data: parseDataMessages( exception?.response?.data?.errors )
        }

        /* debug */ Logger.error( '[→][!]', 'onErrorHandler([ error ])', { error } );

        return Promise.reject( error );
    }

    request.interceptors.request.use( onRequestHandler );
    request.interceptors.response.use( onResponseHandler, onErrorHandler );

    return request;
}

export default BootstrapAPI();
