import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'antd';
import { useTranslation } from "react-i18next";
import Logger from "../../../../../../helpers/logger";
import { SearchOutlined } from '@ant-design/icons';
import ClientsSearcher from "./ClientsSearcher";
import { PCompanyUser } from "../../../../../../types/prop-types";
import '../../../../../../styles/components/company_selector.scss';
import classNames from "classnames";

function FromCompanySelectorView( props ) {
    const { name, position } = props ;
    return (
        <div className="FromCompanySelectorView">
            <p key="pos" className="position">{ position }</p>
            <p key="name" className="name">{ name }</p>
        </div>
    )
}
FromCompanySelectorView.defaultProps = {}
FromCompanySelectorView.propTypes = {
    company: PropTypes.string,
    position: PropTypes.string,
    name: PropTypes.string,
}

function FromCompanySelector( props ) {
    const { selected, disabled, onChange } = props ;
    const { t } = useTranslation() ;
    const [ isOpen, setOpen ] = useState( false );
    const isSelected = selected && selected?.id ;

    /* debug */ Logger.info( "FromCompanySelector([ props ])", props ) ;

    function reset() {
        setOpen( false ) ;
    }
    function onOpenTriggerHandler() {
        if( disabled ) return ;
        setOpen( _ => ! _ ) ;
    }
    function onSelectUserHandler( company ) {
        /* debug */ Logger.log("FromCompanySelector([ company ])", company ) ;
        onChange( company ) ;
        reset();
    }

    return (
        <div className={ classNames( "FromCompanySelector", disabled ? "disabled" : null )}>
            <div className="name">
                {
                    ! isSelected
                    ? (
                        <div className="placeholder">
                            { t("TicketProperty.from.fromCompany") }
                        </div>
                    ) : (
                        <div className="value">
                            <div className="company_name">{ selected?.name }</div>
                        </div>
                    )
                }
            </div>
            <div className="browse">
                <SearchOutlined className="open" onClick={ onOpenTriggerHandler } />
            </div>
            { isOpen && (
                <Modal open={ true } closable={ true } onCancel={ reset } rootClassName="Modal" wrapClassName="dialog" >
                    <ClientsSearcher selected={ selected } onSelect={ onSelectUserHandler } />
                </Modal>
            ) }
        </div>
    )
}
FromCompanySelector.View = FromCompanySelectorView ;
FromCompanySelector.defaultProps = {
    disabled: false
}
FromCompanySelector.propTypes = {
    disabled: PropTypes.bool,
    selected: PCompanyUser,
    onChange: PropTypes.func,
}

export default FromCompanySelector;