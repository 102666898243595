import React, { useState } from "react";
import { shortenString } from "../../../../../helpers/common";
import { Input, Modal, Tooltip } from "antd";
import { useTranslation } from "react-i18next";
import { DeleteFilled, EditOutlined, PlusCircleOutlined } from '@ant-design/icons';
import Button from "../../../../ui-kit/button";

const { TextArea } = Input;

type TNoCommentsProps = {
    onOpen: Function,
    readonly: boolean
}
function NoComments( props: TNoCommentsProps ) {
    const { onOpen, readonly } = props ;
    const { t } = useTranslation();
    const onClick = () => ! readonly && onOpen && onOpen() ;

    if( readonly ) return null ;

    return (
        <p className="add-comment" onClick={ onClick }>
            <PlusCircleOutlined /> { `${ t( `FilesExplorer.tooltips.addComment` ) }` }
        </p>
    )
}

type TCommentProps = {
    value: string,
    readonly: boolean,
    onClick: Function
}
function Comment( props: TCommentProps ) {
    const { value, readonly, onClick } = props ;
    const { t } = useTranslation();
    const limit = 74;
    const cut = shortenString( value, limit, "..." );
    const isLengthChanged = cut.length < value.length ;
    const element = (
        <p onClick={ () => onClick && onClick() }>
            { ! readonly && <EditOutlined className="add-comment" /> } { cut }
        </p>
    )

    return (
        ! isLengthChanged
        ? ( element )
        : (
            <Tooltip placement="top" title={ value }>
                { element }
            </Tooltip>
        )
    )
}

type TCommentCellViewProps = {
    value: string,
    readonly: boolean,
    onUpdate: Function
}
export default function CommentCellView( props: TCommentCellViewProps ) {
    const { value, readonly, onUpdate } = props ;
    const [ isEdit, setIsEdit ] = useState( false );
    const [ state, setValue ] = useState( value );
    const { t } = useTranslation();
    const hasEmpty = ! value.length ;

    function onOpen() {
        if( readonly ) return;
        setIsEdit( true );
    }
    function onClose() {
        setIsEdit( false );
    }
    function onChange( event: any ) {
        const value = event?.target?.value ;
        setValue( value ) ;
    }
    function onRemove() {
        onUpdate && onUpdate( null ) ;
        onClose() ;
    }
    function onSave() {
        onUpdate && onUpdate( state ) ;
        onClose() ;
    }

    return (
        <>
            {
                ! hasEmpty
                ? <Comment readonly={ readonly } value={ value } onClick={ onOpen }/>
                : <NoComments readonly={ readonly } onOpen={ onOpen }/>
            }
            <Modal
                rootClassName="Modal"
                wrapClassName="AddFileComment"
                title={ t( "FilesExplorer.cell._comment.header" ) }
                open={ isEdit }
                onCancel={ onClose }
                footer={[
                    <Button className="save" label={ t( "common.saveChanges" ) } onClick={ onSave } />,
                    <Button ghost className="cancel" label={ t( "common.cancel" ) } onClick={ onClose } />,
                    ! hasEmpty && <Button icon={ <DeleteFilled /> } className="remove" label={ t( "common.remove" ) } onClick={ onRemove } />
                ]}
            >
                <TextArea
                    autoFocus={ true }
                    rows={ 5 }
                    // placeholder="maxLength is 6"
                    maxLength={ 500 }
                    value={ state }
                    onChange={ onChange }
                />
            </Modal>
        </>
    )
}