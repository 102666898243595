import {TEndPointsManagerComponentOptions} from "../../../fields.dto";
import Logger from "../../../../../../helpers/logger";
import {find} from "lodash";

const NameRenderer = ( options: TEndPointsManagerComponentOptions ) => ( props: any ) => {

    const { value } = props;
    const property = find( options.data.routes, { id: value } ) ;

    return (
        <div className="NameRenderer">
            <div data-cell-renderer={ props.column.colId } data-rule={ property?.rule_id }>
                <b className="value"><b>{ property?.type }</b> &nbsp;{ property?.id }</b>
                <b className="path">{ property?.path }</b>
                <p className="description">{ property?.description }</p>
            </div>
        </div>
    )
}
export default NameRenderer;