import {find, isNil} from "lodash";
import {Tooltip} from 'antd';
import {TUsersManagerComponentOptions} from "../../../fields.dto";
import Logger from "../../../../../../helpers/logger";
import {NodeExpandOutlined} from '@ant-design/icons';

const HostRenderer = ( options: TUsersManagerComponentOptions ) => ( props: any ) => {

    // /* debug */ Logger.info( "HostRenderer([ options, props ])", { options, props } ) ;

    const { data } = props ;
    const { hosts_id } = data ;

    function getMeta( id: string ): any {
        return find( options?.data, { id } ) ;
    }
    function getValue( id: string ): string {
        return getMeta( id )?.host ;
    }

    const value = getValue( hosts_id ) ;

    return (
        <div className="HostRenderer">
            <Tooltip placement="top" title={ hosts_id }>
                {
                    ! isNil( value )
                    ? (
                        <>
                            { hosts_id }
                            <NodeExpandOutlined/>
                            <b className="title">{ value }</b>
                        </>
                    ) : null
                }
            </Tooltip>
        </div>
    )
}
export default HostRenderer;