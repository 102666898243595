import {getAllEndPoints, updateEndPoint} from "../helpers/api.services";
import Logger from "../helpers/logger";
import { useReduxDispatch, useReduxSelector } from "./redux.hook";
import { NotificationManager } from "react-notifications";
import { endpointsActions } from "../store/slices/endpoints.slice";

export type TEndPointsHook = {
    load: Function,
    update: Function,
    isReady: boolean,
    data: any,
    error: any
}
export default function useEndPoints(): TEndPointsHook {
    const dispatch = useReduxDispatch();
    const { data, error, fetched, fetching } = useReduxSelector( ({ endpoints }) => endpoints ) ;
    const isReady = fetched && ! fetching ;

    async function load( forced: boolean = true, silent: boolean = false ) {
        /* debug */ Logger.saga( "useEndPoints.load([ forced ])", forced ) ;

        if( ! forced && fetched && ! fetching ) return ;

        if( ! silent ) dispatch(endpointsActions.setFetch({fetching: true, fetched: false}));

        let payload: any,
            error: any ;

        try { payload = await getAllEndPoints() }
        catch ( exception: any ) {
            const message = exception?.message || "[ useEndPoints ] unhandled error" ;
            error = { message }
            /* error */ Logger.error( "useEndPoints.load([ error ])", { error } ) ;
        }

        if( error ) {
            NotificationManager.error( error.message )
            dispatch( endpointsActions.setData({ error, data: [] }) ) ;
        } else {
            const { data, meta } = payload?.data ;
            /* debug */ Logger.success( "useEndPoints.load([ data ])", data ) ;
            dispatch( endpointsActions.setData({ data, meta, error: null }) ) ;
        }

        if( ! silent ) dispatch( endpointsActions.setFetch({ fetching: false, fetched: true }) ) ;
    }

    async function update( data: any ) {
        /* debug */ Logger.saga( "useEndPoints.update([ data ])", data ) ;

        let error: any ;
        try { await updateEndPoint( data ) }
        catch ( exception: any ) {
            const message = exception?.message || "[ useEndPoints.update ] unhandled error" ;
            error = { message } ;
            NotificationManager.error( message ) ;
            dispatch( endpointsActions.setData({ error, data: {} }) ) ;
            return Promise.resolve( false ) ;
        }

        return Promise.resolve( true ) ;
    }

    return { load, update, isReady, data, error }
}
