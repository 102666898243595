import React, { useState } from "react";
import PropTypes from "prop-types";
import { isEmpty, map, valuesIn } from "lodash";
import { Dropdown, Input, Popover, Tooltip } from "antd";
import classnames from "classnames";
import { useTranslation } from "react-i18next";
import Logger from "../../../../../helpers/logger";
import StatusLabel from "../../../../ui-kit/status/label";
import Button from "../../../../ui-kit/button";
import Header from "../../../../ui-kit/header";
import Label from "../../../../ui-kit/label";
import { CaretDownOutlined, MessageFilled } from '@ant-design/icons';
import { FILES_STATUSES } from "../../../../../dto";
import "../../../../../styles/components/ui-kit/file_status.scss";

const { TextArea } = Input;

function FileStatusReadOnly( props ) {
    const { t } = useTranslation() ;
    const { data } = props ;
    const { status_id, status_description } = data ;
    const isDescriptionIncluded = ! isEmpty( status_description ) ;

    return (
        <StatusLabel id={ data.status_id } className="read-only">
            <Tooltip
                placement="top"
                title={ status_description }
                className={ classnames( "wrapper", isDescriptionIncluded ? "highlighted" : null ) }
            >
                { t(`FilesExplorer.statuses.${ status_id }`) }
                { isDescriptionIncluded && <>
                    &nbsp;
                    <MessageFilled />
                </> }
            </Tooltip>
        </StatusLabel>
    )
}
FileStatusReadOnly.defaultProps = {}
FileStatusReadOnly.propTypes = {
    data: PropTypes.shape({
        status_id: PropTypes.string,
        status_description: PropTypes.string
    })
}

export function FileStatusEditor( props ) {
    /**
     * @todo
     * Этот компонент можно (нужно) вынести в UI-Kit ;
     */
    /* debug */ Logger.info("FileStatusEditor([ props ])", props ) ;

    const { data, statuses, onCancel, onSave } = props ;
    const [ state, setState ] = useState( data ) ;
    const { status_id, status_description } = state ;
    const { t } = useTranslation() ;

    function update( value ) {
        setState( ( _ )=>({ ..._, ... value }))
    }
    function onStatusSelectedHandler({ key: status_id }) {
        /* debug */ Logger.log("FileStatusEditor() onStatusSelectedHandler([ status_id ])", status_id ) ;
        update({ status_id }) ;
    }
    function onChangeTextHandler({ target:{ value: status_description } }) {
        /* debug */ Logger.log("FileStatusEditor() onChangeTextHandler([ status_description ])", status_description ) ;
        update({ status_description }) ;
    }
    function onSaveHandler() {
        const { status_id, status_description } = state ;
        onSave({ status_id, status_description });
    }

    const items = map( statuses, ( key ) => ({ key, label: t(`FilesExplorer.statuses.${ key }`), disabled: key === props.data?.status_id }) ) ;

    /* debug */ Logger.warn("FileStatusEditor([ state ])", state ) ;

    return (
       <div className="FileStatusEditor">
           <Header size={ Header.sizes.small } text={ t("FileStatusEditor.header") }/>
            <div className="status">
                <Label value={ `${ t("FilesExplorer.columns.status_id") }:` }/>
                <Dropdown className="value" menu={{ items, onClick: onStatusSelectedHandler }} trigger="click">
                    <div>
                        { t(`FilesExplorer.statuses.${ status_id }`) }
                        <CaretDownOutlined />
                    </div>
                </Dropdown>
            </div>
            <TextArea rows={4} placeholder={ t("FileStatusEditor.placeholder") } maxLength={ 500 } value={ status_description } onChange={ onChangeTextHandler } />
            <div className="footer">
                <Button label={ t("common.saveChanges") } onClick={ onSaveHandler } />
                <Button ghost label={ t("common.cancel") } onClick={ onCancel } />
            </div>
       </div>
    )
}
FileStatusEditor.defaultProps = {
    statuses: valuesIn( FILES_STATUSES )
}
FileStatusEditor.propTypes = {
    statuses: PropTypes.arrayOf( PropTypes.string ),
    data: PropTypes.shape({
        status_id: PropTypes.string,
        status_description: PropTypes.string
    }),
    onCancel: PropTypes.func,
    onSave: PropTypes.func
}

function FileStatus( props ) {
    /* debug */ Logger.info("FileStatus([ props ])", props ) ;

    const { editable, data, onChange } = props ;
    const [ isEdit, setIsEdit ] = useState( false ) ;

    function onCloseEditor() {
        setIsEdit( false ) ;
    }
    function onSave( data ) {
        /* debug */ Logger.warn("FileStatus() onSave([ data ])", data ) ;
        onCloseEditor() ;
        onChange( data ) ;
    }
    function showEditor() {
        if( ! editable ) return ;
        setIsEdit( true ) ;
    }

    return (
        <Popover visible={ isEdit } placement="topRight" trigger={ null } content={ <FileStatusEditor data={ data } onSave={ onSave } onCancel={ onCloseEditor }/> }>
            <div className="FileStatus" onClick={ showEditor } data-editable={ editable }>
                <FileStatusReadOnly data={ data } />
            </div>
        </Popover>
    )
}
FileStatus.defaultProps = {
    editable: false
}
FileStatus.propTypes = {
    editable: PropTypes.bool,
    data: PropTypes.shape({
        status_id: PropTypes.string,
        status_description: PropTypes.string
    }),
    onChange: PropTypes.func
}

export default FileStatus ;