import { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { find, map, uniqueId } from "lodash";
import moment from "moment";
import Logger from "../../../helpers/logger";
import { jsonToObject, scrollToBottom } from "../../../helpers/common";
import { DATE_TIME_FORMAT } from "../../../data/constants";
import { useTranslation } from "react-i18next";
import useSocketHook, {SOCKET_DATA_TYPES} from "../../../hooks/useSocket.hook";
import AttachedFiles from "./AttachedFiles";

function ViewMessages( props ) {
    const { id, owner_id, messages, filesAvailable } = props ;
    const { t } = useTranslation() ;
    const { dispatcher } = useSocketHook() ;
    const [ received, setReceived ] = useState([]) ;
    const LAST_ITEM_ID = uniqueId( id ) ;

    function updateView() {
        /* debug */ Logger.warn( `ViewMessages() updateView()` ) ;
        scrollToBottom( LAST_ITEM_ID ) ;
    }
    function constructor() {
        dispatcher.addEventListener( SOCKET_DATA_TYPES.MESSAGE_CHAT, onChatMessage ) ;
    }
    function destructor() {
        dispatcher.removeEventListener( SOCKET_DATA_TYPES.MESSAGE_CHAT, onChatMessage ) ;
    }
    function onChatMessage( event ) {
        if( [ SOCKET_DATA_TYPES.MESSAGE_CHAT ].includes( event?.type ) && event?.data?.room_id === id ) {
            /* debug */ Logger.warn( `ViewMessages() onChatMessage([ event ])`, event ) ;
            setReceived( ( _ ) => ([ ... _, event.data ]) )
        }
    }

    const $allMessages = useMemo( () => [
        ... messages || [],
        ... received || []
    ], [ received, messages ] ) ;
    const $messages = map( $allMessages, ( msg, key  ) => {
        const isOwner = msg?.owner_id === owner_id ;
        const data = jsonToObject( msg?.data ) ;
        const isLast = Boolean( key === ( $allMessages.length - 1 ) ) ;

        const messageFiles = data?.files || [];
        /* debug */ Logger.log( `ViewMessages([ messageFiles ])`, messageFiles ) ;

        const selectedFiles = map( messageFiles, ( hash ) => {
            return find( filesAvailable, { hash })
        })

        /* debug */ Logger.log( `ViewMessages([ messageFiles, selectedFiles ])`, { messageFiles, selectedFiles } ) ;

        return (
            <div id={ isLast ? LAST_ITEM_ID : null} className="message" key={ key } data-is-owner={ isOwner }>
                <div className="from">
                    <div className="position">{ data?.profile?.position || t("errors.userPositionIsUndeclared") }</div>
                    <div className="name">{ data?.profile?.name }</div>
                </div>
                <div className="body">
                    <div className="text">
                        <p>{ msg?.text }</p>
                        <AttachedFiles readonly={ true } files={ selectedFiles }/>
                        <div className="created-at">
                            { moment( msg?.createdAt ).format( DATE_TIME_FORMAT ) }
                        </div>
                    </div>
                </div>
            </div>
        )
    } )

    useEffect( () => { constructor(); return () => destructor() }, [] ) ;
    useEffect( () => { updateView() }, [ $allMessages ] ) ;

    return (
        <div className="ViewMessages">
            { $messages }
        </div>
    )
}
ViewMessages.defaultProps = {} ;
ViewMessages.propTypes = {
    id: PropTypes.number.isRequired,
    owner_id: PropTypes.number,
    messages: PropTypes.array,
    filesAvailable: PropTypes.array
}

export default ViewMessages ;