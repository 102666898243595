import KeyIcon from "../../../../../ui-kit/key_icon";
import {TUsersManagerComponentOptions} from "../../../fields.dto";

const SignerRenderer = ( options: TUsersManagerComponentOptions ) => ( props: any ) => {
    // /* debug */ Logger.info( "SignerRenderer([ options, props ])", { options, props } ) ;
    const { data: { signer } } = props ;
    const { t } = options ;
    return (
        <div className="SignerRenderer">
            <KeyIcon signRights={signer}/>
            <b>{t(`common.signers.${signer}`)}</b>
        </div>
    )
}
export default SignerRenderer;