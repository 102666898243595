import React from 'react';
import AuthPage from "../../pages/auth.page";
import ApplicationRouter from "../router";
import useSession from "../../hooks/useSession.hook";
import AppPreloader from "../app-preloader";
import Socket from "../socket";
import MessagesObserver from "../messages-observer";
import "../../styles/components/application.scss";
import Logger from "../../helpers/logger";
import Oops from "../oops";

export default function Application( props ) {
    /* debug */ Logger.info( "Application([ props ])", props ) ;

    const { authorized, fetching } = useSession() ;
    const { isRunnedInIFrame } = props ;

    if( fetching ) {
        return (
            <div className="Application">
                <AppPreloader/>
            </div>
        )
    }

    return (
        ! authorized
        ? ( ! isRunnedInIFrame
            ? <AuthPage />
            : <Oops/>
        ) : (
            <ApplicationRouter>
                <Socket/>
                <MessagesObserver/>
            </ApplicationRouter>
        )
    )
}
