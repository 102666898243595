import { useState } from "react";
import { getAllEmployees } from "../helpers/api.services";
import Logger from "../helpers/logger";
import { NotificationManager } from "react-notifications";

export type TEmployeesHook = {
    initialize: Function,
    isReady: boolean,
    data: any,
    error: any
}
export default function useEmployeesHook(): TEmployeesHook {

    const [ state, setState ] = useState({
        fetched: false,
        fetching: false,
        error: null,
        data: []
    }) ;
    const { data, error, fetched, fetching } = state ;
    const isReady = fetched && ! fetching ;

    function dispatch( data: object ) {
        /* debug */ Logger.reducer( "useEmployeesHook.dispatch([ data ])", data ) ;
        setState( ( _: any ) => ({ ... _ || {}, ... data || {} }) ) ;
    }
    async function initialize( forced: boolean = true ) {
        /* debug */ Logger.reducer( "useEmployeesHook.initialize([ forced ])", forced ) ;

        if( ! forced && fetched && ! fetching ) return ;

        dispatch({ fetching: true, fetched: false } ) ;

        let payload: any,
            error: any ;

        try { payload = await getAllEmployees() }
        catch ( exception: any ) {
            const message = exception?.message || "[ useEmployeesHook ] unhandled error" ;
            error = { message }
            /* error */ Logger.error( "useEmployeesHook.load([ error ])", { error } ) ;
        }

        if( error ) {
            NotificationManager.error( error.message )
            dispatch({ error, data: [] }) ;
        } else {
            const data = payload?.data ;
            /* debug */ Logger.success( "useEmployeesHook.load([ data ])", data ) ;

            dispatch({ data, error: null }) ;
        }

        dispatch({ fetching: false, fetched: true }) ;
    }

    return { data, error, initialize, isReady }
}
