import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import Button from "../../../../ui-kit/button";
import Logger from "../../../../../helpers/logger";
import { useTranslation } from "react-i18next";
import { LoadingOutlined, } from '@ant-design/icons';
import { Modal, Popconfirm, Tooltip } from 'antd';
import { getCSSVariableValue } from "../../../../../helpers/common";
import CompanyUsers from "../../../../ui-kit/company_users";
import Header from "../../../../ui-kit/header";
import Label from "../../../../ui-kit/label";
import { map, merge } from "lodash";
import useCompanyUsersHook from "../../../../../hooks/useCompanyUsers.hook";
import "../../../../../styles/components/reassign.scss";
import { PID } from "../../../../../types/prop-types";

function ReAssign({ selected_id, disabled, company_id, onSelect }) {
    const { t } = useTranslation() ;
    const [ selected, setSelected ] = useState( null ) ;
    const {
        isStateLoading,
        isStateReady,
        isVerificationLoading,
        isVerificationReady,
        verify,
        verified,
        prepare,
        reset,
        state,
    } = useCompanyUsersHook() ;

    const isAllReady = isStateReady && isVerificationReady ;
    const isAllLoading = isStateLoading || isVerificationLoading ;
    const error = state?.error || verified?.error ;

    function initialize() {
        prepare({ company_id } ) ;
    }
    function verifyEmployees() {
        if( ! isStateReady ) return /* debug */ Logger.error("ReAssign([ error ])", `"state" must be ready!` );
        const employeesIds = map( state.data, ({ userId }) => userId ) ;
        verify( company_id, employeesIds ) ;
    }

    function onSelectHandler( user ) {
        reset() ;
        setSelected( user ) ;
    }
    function onConfirmHandler() {
        onSelect && onSelect( selected ) ;
        omCancelHandler() ;
    }
    function omCancelHandler() {
        reset() ;
        setSelected( null );
    }

    useEffect( () => { verifyEmployees() }, [ isStateReady ] ) ;

    const data = useMemo( () => (
        ! isAllReady
        ? []
        : merge( [], state.data, verified.data )
    ), [ isAllReady ] ) ;

    return (
        <>
            <Tooltip
                placement="top"
                open={ Boolean( error ) }
                color={ getCSSVariableValue( "--cssDefaultErrorBackgroundColor" ) }
                title={ `ERROR: ${ error?.message }` }
            >
                <Button
                    disabled={ isAllLoading || error || disabled }
                    className="ReAssign"
                    label={
                        <>
                            { isAllLoading && <LoadingOutlined /> }
                            { t( "TicketProperty.reassign" ) }
                        </>
                    }
                    onClick={ initialize }
                />
            </Tooltip>
            <Popconfirm
                placement="bottomRight"
                open={ Boolean( selected ) }
                title={ t( "prompts.reassign.title" ) }
                description={ t( "prompts.reassign.description", {
                    fullname: `${ selected?.userFirstName || "" } ${ selected?.userMiddleName || "" } ${ selected?.userLastName || "" }`.trim()
                } ) }
                onConfirm={ onConfirmHandler }
                onCancel={ omCancelHandler }
                okText={ t( "common.yes" ) }
                cancelText={ t( "common.no" ) }
            />
            { isAllReady && (
                <Modal
                    closable={ true }
                    onCancel={ reset }
                    open={ true }
                    rootClassName="Modal"
                    wrapClassName="dialog"
                >
                    <span className="modal-header">
                        <Header text={ t( "ReAssign.header" ) } size={ Header.sizes.middle }/>
                        <Label value={ t( "ReAssign.description" ) } />
                    </span>
                    <CompanyUsers selected={ selected_id } data={ data } onSelect={ onSelectHandler } />
                </Modal>
            )}
        </>
    )
}
ReAssign.defaultProps = {
    disabled: false
}
ReAssign.propTypes = {
    disabled: PropTypes.bool,
    selected_id: PID,
    company_id: PID,
    onSelect: PropTypes.func
}

export default ReAssign ;