import React from 'react';
import ReactDOM from 'react-dom/client';
import Main from './pages/main.page';
import { TApplicationSettings } from "./types";
import { getSettings } from "./data/settings";
import Logger from "./helpers/logger";
import Session from "./helpers/session";
import Package from "../package.json";
import "./styles/com/react-notifications.css";
import "./styles/index.scss";
import './helpers/i18n';

const settings: TApplicationSettings = getSettings() ;

/* debug */ Logger.header( `TICKETS v${ Package.version }` ) ;
/* debug */ Logger.info( "settings", settings ) ;
/* debug */ Logger.info( "document", { document } ) ;

function preloadSession( callback: Function ) {

    const href = document?.location?.href || "" ;
    const url = new URL( href ) ;
    const oauth = url.searchParams.get("oauth") ;

    /* debug */ Logger.saga( "oauth", oauth ) ;

    oauth && Session.setToken( oauth ) ;
    const isRunnedInIFrame = Boolean( oauth ) ;
    callback && callback( isRunnedInIFrame ) ;
}

preloadSession(
    ( isRunnedInIFrame: boolean ) => ReactDOM
        .createRoot( document.getElementById( 'root' ) as HTMLElement )
        .render( <Main isRunnedInIFrame={ isRunnedInIFrame }/> )
) ;

