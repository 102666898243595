import PropTypes from "prop-types";
import { map } from "lodash";
import Logger from "../../../helpers/logger";
import { CloseCircleFilled, FileTextFilled } from '@ant-design/icons';
import moment from "moment";
import { DATE_TIME_FORMAT } from "../../../data/constants";
import { useTranslation } from "react-i18next";
import { formatSize } from "../../../helpers/common";
import React from "react";
import { getSettings } from "../../../data/settings";
import DownloadFileLink from "../../ui-kit/download-file-link";

function AttachedFiles( props ) {
    const { t } = useTranslation() ;
    const { files, readonly, onRemove } = props ;
    const settings = getSettings() ;

    /* debug */ Logger.info( `AttachedFiles([ props ])`, props ) ;

    const items = map( files, ( file , key ) => {
        const { hash, name, size, updatedAt } = file ;
        const fileSize = formatSize( size ) ;
        const { origin } = new URL( settings?.apiHostUrl || "" ) ;
        const fileUrl = `${ origin }/static/${ hash }` ;
        return (
            <div key={ key } className="file">
                <div className="icon">
                    <FileTextFilled />
                </div>
                <div className="meta">
                    <div className="name">{ name }</div>
                    <div className="date">
                        { `${ moment( updatedAt ).format( DATE_TIME_FORMAT ) }` }
                        <b> { `(${ fileSize.size } ${ t(`common.dataSizes.${ fileSize.type }`) })` }</b>
                    </div>
                </div>
                <div className="toolbar">
                    <DownloadFileLink remoteFile={ file }/>
                    {! readonly && <CloseCircleFilled className="remove" onClick={ () => onRemove && onRemove( hash ) } /> }
                </div>
            </div>
        )
    })

    return (
        <div className="AttachedFiles">
            { items }
        </div>
    )
}
AttachedFiles.defaultProps = {
    readonly: false
} ;
AttachedFiles.propTypes = {
    readonly: PropTypes.bool,
    files: PropTypes.array,
    onRemove: PropTypes.func,
}

export default AttachedFiles ;