import React from "react";
import PropTypes from "prop-types";
import Message from "../../../../ui-kit/message";
import { PID, PSignVerifier } from "../../../../../types/prop-types";
import Logger from "../../../../../helpers/logger";
import { Collapse } from 'antd';
import Label from "../../../../ui-kit/label";
import "../../../../../styles/components/file_info.scss";
import { useTranslation } from "react-i18next";
import TabLabel from "./TabLabel";

function SignInfoDetails( props ) {
    /* debug */ Logger.info( `SignInfoDetails([ props ]`, props ) ;
    const { t } = useTranslation() ;

    function DetailsPanel( info ) {
        /**
         * @description
         * Описание полей можно посмотреть тут:
         * https://wiki.edin.ua/uk/newapiview/API_ETTN/Methods/GetSignersInfo.html
         */
        const items = [
            "subjCN",
            "subjLocality",
            "subjTitle",
            "subjEMail",
            "subjPhone",
            "serial",
            "subjOrg",
            "subjOrgUnit",
            "issuerCN"
        ]
        return (
            <div className="DetailsPanel">
                { items.map( ( key ) => <Label.WithHeader key={ key } value={ info?.info.ownerInfo[ key ] } placeholder={ `${ t(`DetailsPanel.${ key }`) }:` } /> ) }
            </div>
        )
    }

    const { id, info, owner, verified } = props ;
    const isError = info?.error ;
    const children = (
        ! isError
        ? <DetailsPanel info={ info }/>
        : <Message message={ info?.error?.message } type={ Message.types.ERROR }/>
    )
    return (
        <Collapse
            className="SignInfoDetails"
            size="small"
            items={[
                {
                    key: id,
                    label: <TabLabel title={ owner?.fullname } description={ owner?.position } verified={ verified }/>,
                    children
                },
            ]}
        />
    )
}
SignInfoDetails.defaultProps = {}
SignInfoDetails.propTypes = {
    id: PID,
    sign: PropTypes.string,
    owner: PropTypes.shape({
        id: PID,
        position: PropTypes.string,
        fullname: PropTypes.string
    }),
    info: PropTypes.any,
    verified: PSignVerifier
}

export default SignInfoDetails ;