import {find} from "lodash";
import {Tooltip} from 'antd';
import {TUsersManagerComponentOptions} from "../../../fields.dto";
import Logger from "../../../../../../helpers/logger";

const RoleRenderer = ( options: TUsersManagerComponentOptions ) => ( props: any ) => {

    // /* debug */ Logger.info( "RoleRenderer([ options, props ])", { options, props } ) ;

    const { data } = props ;
    const { role_id } = data ;

    function getRoleMeta( id: string ): any {
        return find( options?.data, { id } ) ;
    }
    function getRoleDescription( id: string ): string {
        return getRoleMeta( id )?.description ;
    }

    const description = getRoleDescription( role_id ) ;

    return (
        <div className="RoleRenderer">
            <Tooltip placement="top" title={ role_id }>
                <b className="title">{ description }</b>
            </Tooltip>
        </div>
    )
}
export default RoleRenderer;