import API from "./api";
import { set } from "lodash";
import { TAuthenticatorResponseData, TCompanyUsersResponseData, TDepsResponseData, TDepTasksResponseData, TPaginatorOptions, TProfileResponseData, TTicketLocalFile, TTicketsResponseData, TUserParams } from "../types";
import {use} from "i18next";

/* Authentication */
export function authenticate( user: TUserParams ): Promise<TAuthenticatorResponseData> {
    return API.post( "/auth", user ) ;
}

/* Profile */
export function getProfile(): Promise<TProfileResponseData> {
    return API.get( "/profile" ) ;
}

/* Deps */
export function getDeps(): Promise<TDepsResponseData> {
    return API.get( "/deps" ) ;
}

/* Get My tickets */
export function getMyTickets( params: TPaginatorOptions ): Promise<TTicketsResponseData[]> {
    return API.get( "/tickets/my", { params } ) ;
}
/* Create new Ticket */
export function createNewTicket( data: TTicketsResponseData ): Promise<TTicketsResponseData[]> {
    return API.put( "/tickets/create", data || {}) ;
}
/* Get Ticket by ID */
export function getTicketByID( ticket_id: string | number ): Promise<TTicketsResponseData[]> {
    return API.get( `/tickets/my/${ ticket_id }` ) ;
}
/* Save Ticket by ID */
export function updateTicket( ticket_id: string, data: TTicketsResponseData ): Promise<TTicketsResponseData[]> {
    return API.post( `/tickets/my/${ ticket_id }`, data || {} );
}
/* Remove (My) Ticket by UD */
export function removeTicketByID( ticket_id: string | number ): Promise<TTicketsResponseData[]> {
    return API.delete( `/tickets/my/${ ticket_id }` ) ;
}
/* Assign Ticket TO */
export function assignTicketTo( ticket_id: number, user_id: number ): Promise<TTicketsResponseData[]> {
    return API.get( `/tickets/assign/${ ticket_id }/to/${ user_id }` ) ;
}
/* Get Ticket by ID */
export function verifyEmployees( company_id: number, ids: number[] ): Promise<TCompanyUsersResponseData[]> {
    return API.get( `/company_users/${ company_id }/?ids=${ ids.join(",") }` ) ;
}

/* Поиск пользователей компании по ключевому "keyword" слову */
export function searchCompanyClients( keyword: string ): Promise<any[]> {
    return API.get( `/companies/${ keyword }` ) ;
}

/* Uploader */
export function getUploadCSRF( ticket_id: number ): Promise<string> {
    return API.get( `/uploader/${ ticket_id.toString() }` ) ;
}
export function sendFile( csrf: string, files: TTicketLocalFile[], callback: Function ): Promise<string> {

    const formData = new FormData(),
          metaData: object = {} ;

    files.forEach( ( file: TTicketLocalFile ) => {
        const id = file.hash ;
        formData.append( id, <any> file ) ;

        set( metaData, id, {
            comment: file.comment,
            filename: file.altName || file.name,
            signers: file.signers,
            status_id: file?.status_id,
            status_description: file?.status_description
        } ) ;
    } )

    return API.post( `/uploader/${ csrf.toString() }`, formData, {
        headers: {  "Content-Type": "multipart/form-data" },
        params: { metadata: encodeURIComponent( JSON.stringify( metaData ) ) },
        onUploadProgress:( event ) => callback && callback( event )
    })
}

/* Get File metadata */
export function getFile( hash: string, signatures: boolean = false ): Promise<any> {
    return API.get( `/file/${ hash }?${ signatures ? "signatures" : "" }`.trim() ) ;
}
/* Save File Properties */
export function updateFileProperties( hash: string, data: object ): Promise<any> {
    return API.post( `/file/${ hash.toString() }`, data ) ;
}
/* Download file by ID */
export function downloadFile( hash: string ): Promise<void> {
    return API.get( `/file/${ hash }/download`, { responseType: "blob" } ) ;
}

/* Chat */
export function getChat( id: number ): Promise<any> {
    return API.get( `/chat/${ id }` ) ;
}
/* Send Message to Chat */
export function sendChatMessage( id: number, text: string | null, data: any ): Promise<any> {
    return API.put( `/chat/${ id }`, { text, data } ) ;
}
/* Enter To ChatRoom */
export function enterToChatRoom( id: number ): Promise<any> {
    return API.get( `/chat/${ id }/enter` ) ;
}
/* Leave ChatRoom */
export function leaveChatRoom( id: number ): Promise<any> {
    return API.get( `/chat/${ id }/leave` ) ;
}

/* Get Department Tasks */
export function getDepTasks( params: TPaginatorOptions ): Promise<TDepTasksResponseData[]> {
    return API.get( "/tasks", { params } ) ;
}
/* Get task to work */
export function setTaskForWork( id: number ): Promise<any> {
    return API.get( `/task/${ id }/assign/me` ) ;
}
/* Assign task to Dep */
export function assignTaskToDep( id: number ): Promise<any> {
    return API.get( `/task/${ id }/assign/dep` ) ;
}
/* Assign task to Company */
export function assignTaskToCompany( id: number ): Promise<any> {
    return API.get( `/task/${ id }/assign/company` ) ;
}
/* Task for Review */
export function assignTaskForReview( id: number ): Promise<any> {
    return API.get( `/task/${ id }/review` ) ;
}
/* Update Review ticket params */
export function updateTask( id: number, data: object ): Promise<any> {
    return API.put( `/task/${ id }/review`, data ) ;
}
/* ReAssign Task to external Department */
export function reAssignTaskToDep( task_id: number, dep_id: number ): Promise<any> {
    return API.get( `/task/${ task_id }/reassign/${ dep_id }/dep` ) ;
}
/* ReAssign Task to employee */
export function reAssignTaskToEmployee( task_id: number, user_id: number ): Promise<any> {
    return API.get( `/task/${ task_id }/reassign/${ user_id }/employee` ) ;
}

/* All Dep employee(s) */
export function getAllEmployees(): Promise<any> {
    return API.get( `/employees` ) ;
}

/* read All users */
export function getAllUsers(): Promise<any> {
    return API.get( `/users` ) ;
}

/* Update user by id */
export function updateUsers( user_id: number, data: any ): Promise<any> {
    return API.put( `/users/${ user_id }`, data || {} );
}

/* create new user */
export function createNewUser( data: any ): Promise<any> {
    return API.post( `/users`, data || {} );
}

/* change user password */
export function changeUserPassword( user_id: number, password: string ): Promise<any> {
    return API.post( `/password/${ user_id }`, { password } );
}

/* Send p2p message */
export function sendP2PMessage( user_id: number, message: string ): Promise<any> {
    return API.put( `/message/${ user_id }`, { message } ) ;
}

/* read All API endpoint(s) */
export function getAllEndPoints(): Promise<any> {
    return API.get( `/end_points` ) ;
}

/* update API endpoint */
export function updateEndPoint( data: any ): Promise<any> {
    return API.put( `/end_points`, data ) ;
}