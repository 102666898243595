import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { find, map } from "lodash";
import { useTranslation } from "react-i18next";
import { getFile } from "../../../../../helpers/api.services";
import { LoadingOutlined, WarningFilled } from '@ant-design/icons';
import { signVerification } from "../../../../../helpers/cross-messages.services";
import SignInfoDetails from "./SignInfoDetails";
import Logger from "../../../../../helpers/logger";
import "../../../../../styles/components/file_info.scss";

function SignInfo({ file }) {
    const { t } = useTranslation() ;
    const [ isSigned, setSigned ] = useState( false ) ;
    const [ isFetching, setIsFetching ] = useState( null ) ;
    const [ data, setData ] = useState( null ) ;
    const [ state, setState ] = useState( null ) ;
    const [ error, setError ] = useState( false ) ;

    const isVerified = Boolean( data?.success === true ) ;
    const isError = Boolean( error?.message )

    async function loadFile() {
        const { hash } = file ;
        let payload, error ;
        try { payload = await getFile( hash, true ) }
        catch ( exception ) { error = exception }

        return { payload, error }
    }
    async function componentDidMount() {

        setIsFetching( true ) ;

        const { payload, error } = await loadFile() ;
        if( error ) {
            setIsFetching( false ) ;
            return setError( error ) ;
        } else {
            setState( payload?.data ) ;
        }

        return setIsFetching( false ) ;

        // валидацию подписи (пока) отключаем

        const sign = payload?.data?.sign ;
        if( sign ) {
            setSigned( true ) ;

            const verification = await signVerification( sign ) ;
            setData( verification?.data ) ;
            setIsFetching( false ) ;
        } else {
            setIsFetching( false ) ;
        }
    }

    const items = useMemo( () => {
        if( isFetching ) return null ;

        /* debug */ Logger.log( `SignInfo([ state ][!])`, state ) ;

        return map( state?.signatures || [], ( _sign ) => {
            /* debug */ Logger.warn( `SignInfo([ _sign ])`, _sign ) ;

            const { id, info, sign, verified } = _sign ;
            const owner = find( state?.$signers, { id } )

            return <SignInfoDetails sign={ sign } owner={ owner } info={ info } verified={ verified } />
        } )

    }, [ isFetching ] ) ;

    useEffect( () => { ( async () => ( await componentDidMount() ))() }, [] ) ;

    return (
        <div className="SignInfo">
            <div className="wrapper">
                {/*
                <div key="signed" className="signed" data-disabled={ ! isSigned }>
                    <SafetyCertificateOutlined/> Signed
                </div>
                <div key="verified" className="verified" data-disabled={ ! isVerified }>
                    <SafetyCertificateFilled /> Verified
                </div>
                */}
                { isError && (
                    <div className="error">
                        <WarningFilled /> { error.message }
                    </div>
                )}

                { isFetching ? <LoadingOutlined /> : items }
            </div>
        </div>
    )
}
SignInfo.propTypes = {
    file: PropTypes.shape()
}

export default SignInfo ;