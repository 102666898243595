import React, {useEffect, useState, useMemo} from "react";
import Logger from "../../../helpers/logger";
import PropTypes from "prop-types";
import {AgGridReact} from 'ag-grid-react';
import {get, isEmpty, isNil, map, omitBy} from 'lodash';
import CellRenderers from "./components/CellRenderers";
import DataEditorProvider from "./components/DataEditorProvider";
import {Modal} from "antd";
import classNames from "classnames";
import {deepClone} from "../../../helpers/common";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import Toolbar from "./components/Toolbar";
import {ToolbarActions} from "./fields.dto";
import {TicketPropertyViewModes} from "../../../data/constants";
import {NotificationManager} from "react-notifications";

function fixHeadersTranslation( t, data ) {
    let $data = deepClone( data ) ;
    for ( const [ key, value ] of Object.entries( $data )) {
        value.props.headerName = t( `Users.columns.${ value.props.field }` ) ;
    }
    return $data ;
}
function UsersManagerComponent( props ) {
    const [ modalProps, setModalProps ] = useState( null ) ;
    const { t, lang, fromI18N, data, onUpdate, onCreate, onChangePassword, onSend2P2Message, onRefresh } = props ;
    const meta = useMemo( () => fixHeadersTranslation( t, props?.meta ), [] ) ;

    /* debug */ Logger.info( "UsersManagerComponent([ props ])", props ) ;

    const [ state, setState ] = useState();
    const [ metadata, setMetadata ] = useState();

    function initialize() {
        const _metadata = build() ;
        setMetadata( _metadata ) ;
        refresh() ;
    }
    function build() {
        return map( meta, ( options ) => {
            const { id, props: _props } = options ;
            const options_ = { ... options, fromI18N, lang, t, onOkay: onActionsHandler( id ) } ;
            const renderers = CellRenderers( options_ ) ;
            const cellRenderer = get( renderers, id, renderers.default ) ;
            return omitBy({ ... _props, cellRenderer }, isNil ) ;
        }) ;
    }
    function refresh() { setState( data ) }

    useEffect( () => { initialize() }, []);
    useEffect( () => { refresh() }, [ data ]);

    const onCloseModalHandler = () => {
        setModalProps( null );
        Modal.destroyAll();
    }
    const onActionsHandler = ( id ) => ( event ) => setModalProps({ id, event }) ;
    const onRefreshActionHandler = () => {
        /* debug */ Logger.log( "UsersManagerComponent.onRefreshActionHandler()" ) ;
        onRefresh && onRefresh() ;
    }
    const onCreateActionHandler = () => {
        /* debug */ Logger.log( "UsersManagerComponent.onCreateHandler()" ) ;
        setModalProps({
            id: null,
            event: {
                id: TicketPropertyViewModes.Create,
                mode: ToolbarActions.CREATE,
                data: {}
            }
        })
    }

    const fetch = async ( data, mode ) => {
        /* debug */ Logger.warn( `UsersManagerComponent.fetch([ data, mode ])`, { data, mode } ) ;

        let isUpdateSuccess = null ;
        const user_id = modalProps?.event?.data?.id ;

        try {
            switch (mode) {
                case ToolbarActions.CREATE: {
                    isUpdateSuccess = await onCreate(data);
                    break;
                }
                case ToolbarActions.WRITE: {
                    if (isEmpty(data) || !user_id) {
                        onCloseModalHandler();
                        return Promise.resolve();
                    }
                    isUpdateSuccess = await onUpdate(user_id, data);
                    break;
                }
                case ToolbarActions.CHANGE_PASSWORD: {
                    if (!user_id) {
                        onCloseModalHandler();
                        return Promise.resolve();
                    }
                    isUpdateSuccess = await onChangePassword(user_id, data);
                    break;
                }
                case ToolbarActions.SEND_MESSAGE: {
                    isUpdateSuccess = await onSend2P2Message( data.user_id, data.message );
                    /* debug */ Logger.warn( `UsersManagerComponent.fetch([ ??? isUpdateSuccess ])`, isUpdateSuccess ) ;
                    const message = isUpdateSuccess?.data?.data?.message ;
                    message && NotificationManager.info( message ) ;

                    break;
                }
            }
        } catch ( exception ) {
            isUpdateSuccess = { error: { message: exception.message } }
        }

        isUpdateSuccess && ! isUpdateSuccess?.error && onCloseModalHandler() ;
    }
    const DataEditorWindowComponent = DataEditorProvider({ fromI18N, lang, t, onOkay: fetch, onCancel: onCloseModalHandler }) ;

    /* debug */ Logger.log( "UsersManagerComponent([ metadata ])", { metadata } ) ;

    return (
        <div className="UsersManagerComponent">
            <Modal className={ classNames( "UsersManagerModal", modalProps?.event?.mode ) } title={ null } open={ ! isNil( modalProps ) } onCancel={ onCloseModalHandler } footer={ null }>
                <DataEditorWindowComponent { ... modalProps?.event } meta={ meta } />
            </Modal>
            <Toolbar onCreate={ onCreateActionHandler } onRefresh={ onRefreshActionHandler } />
            <div className={ classNames( "ag-theme-quartz", "UsersManagerTable" ) } style={{ height: "100%" }}>
                <AgGridReact
                    rowData={ state }
                    columnDefs={ metadata }
                />
            </div>
        </div>
    )
}
UsersManagerComponent.defaultProps = {};
UsersManagerComponent.propTypes = {
    t: PropTypes.func,
    fromI18N: PropTypes.func,
    lang: PropTypes.string,

    data: PropTypes.array,
    meta: PropTypes.any,

    onUpdate: PropTypes.func,
    onCreate: PropTypes.func,
    onRefresh: PropTypes.func,
    onChangePassword: PropTypes.func,
    onSend2P2Message: PropTypes.func
};

export default UsersManagerComponent;