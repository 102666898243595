import React, { useEffect } from 'react';
import Spinner from "../../components/ui-kit/spinner";
import { useNavigate } from "react-router-dom";
import '../../styles/pages/home.scss';

export default function HomePage({ redirectTo }) {

    const navigate = useNavigate() ;

    function initialize() {
        redirectTo && navigate( `/${ redirectTo }` )
    }

    /**
     * @todo
     * Делать redirect на страницу "по умолчанию"
     */

    useEffect( ()=>{ initialize() }, [] )
    return (
        <div className="HomePage">
            <Spinner className="preloader" />
        </div>
    )
}
