import React from 'react';
import { CloseCircleOutlined } from '@ant-design/icons';
import { Button, Result, Typography } from 'antd';
const { Paragraph, Text } = Typography;

export default function Oops() {
    return (
        <Result
            status="error"
            title="Ваш обліковий запис тимчасово заблокуван"
            subTitle="Ваш доступ до облікового запису було обмежено через порушення правил використання сервісу. Ми прагнемо підтримувати безпечне та шанобливе середовище для всіх користувачів, тому призупиняємо облікові записи, які порушують наші норми та політики."
        >
            <div className="desc">
                <Paragraph>
                    <Text strong style={{fontSize: 16}}>Що це означає?</Text>
                </Paragraph>
                <Paragraph>
                    <CloseCircleOutlined className="site-result-demo-error-icon"/> <b>Обмежений доступ:</b> Ви не
                    зможете увійти до свого облікового запису або використовувати пов’язані з ним функції.
                </Paragraph>
                <Paragraph>
                    <CloseCircleOutlined className="site-result-demo-error-icon"/> <b>Збереження даних:</b> Усі ваші
                    дані залишаться на сервері та будуть захищені. Ви зможете відновити доступ до облікового запису
                    після усунення причин блокування.
                </Paragraph>
                <Paragraph>
                    <CloseCircleOutlined className="site-result-demo-error-icon"/> <b>Зворотний зв'язок:</b> Якщо ви
                    вважаєте, що блокування було помилковим, ви можете зв'язатися з нашою службою підтримки для
                    отримання роз'яснень та подання апеляції.
                </Paragraph>
                <br/>
                <Paragraph>
                    <Text strong style={{fontSize: 16}}>Що робити далі?</Text>
                </Paragraph>
                <Paragraph>
                    <CloseCircleOutlined className="site-result-demo-error-icon"/> <b>Перевірте свою електронну
                    пошту:</b> Ми надіслали вам лист із додатковою інформацією про причину блокування та кроками, які
                    потрібно зробити для її усунення.
                </Paragraph>
                <Paragraph>
                    <CloseCircleOutlined className="site-result-demo-error-icon"/> <b>Подайте апеляцію:</b> Якщо ви не
                    згодні з блокуванням, ви можете надіслати запит на її перегляд, слідуючи інструкціям у листі.
                </Paragraph>
                <Paragraph>
                    <CloseCircleOutlined className="site-result-demo-error-icon"/> <b>Ознайомтеся з правилами
                    спільноти:</b> Будь ласка, перегляньте наші правила та політики, щоб запобігти подібним ситуаціям у
                    майбутньому.
                </Paragraph>
                <br/>
                <Paragraph>
                    <Text strong style={{fontSize: 16}}>Ми цінуємо ваше розуміння та сподіваємось на швидке вирішення
                        ситуації.</Text>
                </Paragraph>

            </div>
        </Result>
    )
}