import { LANGUAGES_SUPPORTED } from "../dto";
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

void i18n
    .use( Backend )
    .use( LanguageDetector )
    .use( initReactI18next )
    .init({
        fallbackLng: [ LANGUAGES_SUPPORTED.EN, LANGUAGES_SUPPORTED.UA],
        lng: LANGUAGES_SUPPORTED.UA,
        debug: true,
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        backend: {
            loadPath: `/locales/{{lng}}/translation.json`
        }
    });


export default i18n;
