import React, {useEffect} from "react";
import Logger from "../../../helpers/logger";
import UsersManagerComponent from "./UsersManagerComponent";
import Toolbar from "./components/Toolbar";
import {useReduxSelector} from "../../../hooks/redux.hook";
import useUsers from "../../../hooks/useUsers.hook";
import useI18N from "../../../hooks/useI18N.hook";
import {useTranslation} from "react-i18next";
import Spinner from "../../ui-kit/spinner";
import "../../../styles/components/users.scss";
import useMessagesHook from "../../../hooks/useMessages.hook";

export default function Users( props ) {
    /* debug */ Logger.info( "Users([ props ])", props ) ;

    const usersController = useUsers() ;
    const messagesController = useMessagesHook() ;
    const { value: i18n, fromI18N } = useI18N() ;
    const { t } = useTranslation() ;
    const { data, meta, error, fetching, fetched } = useReduxSelector( ({ users }) => users ) ;
    const isInitialize = ! fetching && fetched ;

    function initialize() { ! isInitialize && usersController.load() ; }
    useEffect( () => { initialize() }, [] ) ;

    return (
        <div className="Users">
            <div className="wrapper">
                {
                    ! isInitialize
                    ? (
                        <Spinner spinVisible={ true } label={ t("common.loading") }>
                            <span/>
                        </Spinner>
                    ) : (
                        <UsersManagerComponent
                            t={ t }
                            lang={ i18n }
                            fromI18N={ fromI18N }
                            data={ data }
                            meta={ meta }
                            onUpdate={ usersController.update }
                            onCreate={ usersController.create }
                            onChangePassword={ usersController.changePassword }
                            onSend2P2Message={ messagesController.send }
                            onRefresh={ usersController.load }
                        />
                    )
                }
            </div>
        </div>
    )
}