import React from "react";
import { filter, isNil } from "lodash";
import { Route } from "react-router-dom";
import Layout from "../ui-kit/layout";
import HomePage from "../../pages/home.page";
import NotFoundPage from "../../pages/not-found.page";
import ErrorPage from "../../pages/error-page";
import Permission from "../ui-kit/permission";

const onRenderRoute = ( model ) => ( perm ) => {
    const { id, path, ui_section_id } = perm ;
    return (
        <Route id={ ui_section_id } key={ ui_section_id } path={ path } element={ <Permission /> }>
            { build( model, id ) }
        </Route>
    )
}

function build( model, parent_id = null ) {
    if( ! model ) return null ;
    return filter( model || [], { parent_id } ).map( onRenderRoute( model ) )
}

export const UnknownRoleRoutes = (
    <Route path="/" element={ <NotFoundPage/> }>
        <Route path="*" element={ <NotFoundPage/> }/>
    </Route>
)

function getRedirectUrl( permissions ) {
    const topLevel = filter( permissions, ({ parent_id }) => isNil( parent_id ) ).find(( p ) => p?.json?.useOnStartup === true ) ;
    return (
        ! topLevel?.path
        ? null
        : topLevel.path
    )
}

export const BootstrapRoutes = ( profile, permissions ) => {

    if( ! ( profile && profile?.role_id ) ) return UnknownRoleRoutes ;
    if( ! ( permissions && permissions.length ) ) return UnknownRoleRoutes ;

    const routes = permissions && build( permissions ) ;
    const redirectTo = getRedirectUrl( permissions ) ;

    return (
        <Route path="/" element={ <Layout/> } errorElement={ <ErrorPage/> }>
            <Route key="_home_" index element={ <HomePage redirectTo={ redirectTo } /> }/>
            { routes }
            <Route key="_404_" path="*" element={ <NotFoundPage/> }/>
        </Route>
    )
}

