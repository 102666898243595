import {TUsersManagerComponentOptions} from "../../../fields.dto";
import {isEmpty} from "lodash" ;
import {UnlockFilled, LockFilled, EyeFilled} from '@ant-design/icons';

const PasswordRenderer = ( options: TUsersManagerComponentOptions ) => ( props: any ) => {
    const { value } = props ;
    const isProtected = ! isEmpty( value ) ;
    return (
        <div className="PasswordRenderer" data-is-protected={ isProtected }>
            {
                ! isProtected
                ? <UnlockFilled />
                : <LockFilled />
            }
        </div>
    )
}
export default PasswordRenderer;