import React, { useEffect } from "react";
import ClassNames from "classnames";
import { map } from "lodash";
import Spinner from "../spinner";
import Header from "../header";
import PropTypes from "prop-types";
import Logger from "../../../helpers/logger";
import { jsonToObject } from "../../../helpers/common";
import { Dropdown, Modal } from "antd";
import "../../../styles/components/ui-kit/deps_list.scss";
import "../../../styles/components/ui-kit/employees_list.scss";
import FilesSigner from "../../files-signer";
import Button from "../button";
import { useTranslation } from "react-i18next";

const ITEMS = {
    DEP: "dep",
    EMPLOYEE: "employee"
}

function ReAssignersSelector(props ) {
    /* debug */ Logger.info("ReAssignersSelector([ props ])", props ) ;

    const { t, className, placement, children, onSelect } = props ;
    const reassignMenuOptions = {
        items: [
            { key: ITEMS.DEP, label: t(`DepTickets.assign.${ ITEMS.DEP }`) },
            { key: ITEMS.EMPLOYEE, label: t(`DepTickets.assign.${ ITEMS.EMPLOYEE }`) }
        ],
        onClick: ({ key }) => {
            /* debug */ Logger.warn("ReAssignersSelector() onClick([ key ])", key );
            switch ( key ) {
                case ITEMS.EMPLOYEE: return onSelect && onSelect( key ) ;
                case ITEMS.DEP: return onSelect && onSelect( key ) ;
            }
        }
    }

    return (
        <Dropdown menu={ reassignMenuOptions } placement={ placement || "topRight" } autoAdjustOverflow arrow>
            { children }
        </Dropdown>
    )
}
ReAssignersSelector.ITEMS = ITEMS ;
ReAssignersSelector.defaultProps = {}
ReAssignersSelector.propTypes = {
    t: PropTypes.any,
    placement: PropTypes.string,
    className: PropTypes.string,
    children: PropTypes.any,
    onSelect: PropTypes.func
}

function DepsList( props ) {
    const { t } = useTranslation() ;
    const { title, className, fetching, initialize, fromI18N, data, selected, onSelect } = props ;

    function onSelectHandler( id, name ) {
        return () => {
            function onYes( handler )  {
                onSelect && onSelect( id ) ;
                handler.destroy() ;
            }
            function onClose( handler ) {
                handler.destroy() ;
            }
            const handler = Modal.confirm({
                className: "short",
                title: t("common.confirmation"),
                content: t( "ReAssign.prompts.toDep", { dep: name } ),
                footer: (
                    <span className="ant-modal-footer">
                            <Button key="yes" ghost className="save" label={ t( "common.yes" ) } onClick={ () => onYes( handler ) } />
                            <Button key="cancel" className="cancel" label={ t( "common.cancel" ) } onClick={ () => onClose( handler ) } />
                        </span>
                )
            });
        }
    }

    const items = map( data || [], ({ id, name, title, description }) => (
        <div key={ id } className="item" onClick={ onSelectHandler( id, fromI18N( jsonToObject( title ) ) ) } data-disabled={ selected && selected === id }>
            <div className="title">{ fromI18N( jsonToObject( title ) ) }</div>
            <div className="description">{ fromI18N( jsonToObject( description ) ) }</div>
        </div>
    ) ) ;

    useEffect( () => { ( async () => ( await initialize( false ) ))() }, [] ) ;

    /* debug */ Logger.info("DepsList([ props ])", fetching, props ) ;

    return (
        <div className={ ClassNames( "DepsList", className ) }>
            <Header text={ title } size={ Header.sizes.middle }/>
            <Spinner spinVisible={ ! fetching }>
                { items }
            </Spinner>
        </div>
    )
}
DepsList.defaultProps = {}
DepsList.propTypes = {
    title: PropTypes.string,
    className: PropTypes.string,
    selected: PropTypes.number,
    initialize: PropTypes.func,
    fromI18N: PropTypes.func.isRequired,
    fetching: PropTypes.bool,
    data: PropTypes.array,
    onSelect: PropTypes.func
}

function EmployeesList( props ) {
    const { title, className, fetching, initialize, fromI18N, data, selected, onSelect } = props ;
    const { t } = useTranslation() ;

    function onSelectHandler( id, name ) {
        return () => {
            function onYes( handler )  {
                onSelect && onSelect( id ) ;
                handler.destroy() ;
            }
            function onClose( handler ) {
                handler.destroy() ;
            }
            const handler = Modal.confirm({
                className: "short",
                title: t("common.confirmation"),
                content: t( "ReAssign.prompts.toEmployer", { employer: name } ),
                footer: (
                        <span className="ant-modal-footer">
                            <Button key="yes" ghost className="save" label={ t( "common.yes" ) } onClick={ () => onYes( handler ) } />
                            <Button key="cancel" className="cancel" label={ t( "common.cancel" ) } onClick={ () => onClose( handler ) } />
                        </span>
                    )
                });
        }
    }

    const items = map( data || [], ({ id, name, position }) => (
        <div key={ id } className="item" onClick={ onSelectHandler( id, name ) } data-disabled={ selected && selected === id }>
            <div className="position">{ position }</div>
            <div className="name">{ name }</div>
        </div>
    ) ) ;

    useEffect( () => { ( async () => ( await initialize( false ) ))() }, [] ) ;

    /* debug */ Logger.info("EmployeesList([ props ])", fetching, props ) ;
    /* debug */ Logger.info("EmployeesList([ items ])", items ) ;

    return (
        <div className={ ClassNames( "EmployeesList", className ) }>
            <Header text={ title } size={ Header.sizes.middle }/>
            <Spinner spinVisible={ ! fetching }>
                { items }
            </Spinner>
        </div>
    )
}
EmployeesList.defaultProps = {}
EmployeesList.propTypes = {
    title: PropTypes.string,
    className: PropTypes.string,
    selected: PropTypes.number,
    initialize: PropTypes.func,
    fromI18N: PropTypes.func.isRequired,
    fetching: PropTypes.bool,
    data: PropTypes.array,
    onSelect: PropTypes.func
}

export { ReAssignersSelector, DepsList, EmployeesList } ;