import {useEffect} from "react";
import useSocketHook, {SOCKET_DATA_TYPES} from "../../hooks/useSocket.hook";
import Logger from "../../helpers/logger";
import {Alert, notification } from 'antd';
import {WechatFilled} from '@ant-design/icons';
import {TIME_POPUP_DISPLAY_DURATION_MS} from "../../data/constants";
import {jsonToObject} from "../../helpers/common";
import '../../styles/components/messages-observer.scss';

function MessagesObserver( props ) {
    /* debug */ Logger.info( `MessagesObserver([ props ])`, props ) ;

    const { dispatcher } = useSocketHook() ;

    function onMessageHandler( event ) {
        /* debug */ Logger.log( `MessagesObserver() onMessageHandler([ event ])`, { event } ) ;

        const data = event?.data ;
        const meta = jsonToObject( decodeURIComponent( data?.data || "" ) ) ;
        /* debug */ Logger.log( `MessagesObserver() onMessageHandler([ meta ])`, { meta } ) ;

        data?.message && notification.open({
            className: "MessagesObserver",
            message: <b>{ meta?.profile?.name }</b>,
            description: data.message,
            icon: <WechatFilled/>,
            duration: TIME_POPUP_DISPLAY_DURATION_MS / 1000
        }) ;
    }
    function constructor() {
        dispatcher.addEventListener( SOCKET_DATA_TYPES.MESSAGE_P2P, onMessageHandler ) ;
        dispatcher.addEventListener( SOCKET_DATA_TYPES.NOTIFY_CHANGES, onMessageHandler ) ;
    }
    function destructor() {
        dispatcher.removeEventListener( SOCKET_DATA_TYPES.MESSAGE_P2P, onMessageHandler ) ;
        dispatcher.removeEventListener( SOCKET_DATA_TYPES.NOTIFY_CHANGES, onMessageHandler ) ;
    }

    useEffect( () => { constructor(); return () => destructor() }, [] ) ;

    return (
        <div className="MessagesObserver"/>
    )
}
MessagesObserver.defaultProps = {} ;
MessagesObserver.propTypes = {}

export default MessagesObserver ;