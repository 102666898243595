import { useState } from "react";
import PropTypes from 'prop-types';
import { UploadOutlined } from '@ant-design/icons';
import { useTranslation } from "react-i18next";
import { PID } from "../../../../../types/prop-types";
import DragAndDropFiles from "../../../../ui-kit/drag_n_drop_files";
import { getSettings } from "../../../../../data/settings";
import "../../../../../styles/components/open_files.scss";
import { formatSize } from "../../../../../helpers/common";

function OpenFiles({ id, disabled, onOpen }) {

    const settings = getSettings() ;
    const SUPPORTED_FILES_EXTENSIONS = settings.uploaderExtensionAllowed.join(", ") ;
    const MAX_FILESIZE = formatSize( settings.uploaderFileSizeLimitBytes ) ;

    const [ focused, setFocused ] = useState( false );
    const { t } = useTranslation() ;

    function onDragOver() {
        setFocused( true ) ;
    }
    function onDragLeave() {
        setFocused( false ) ;
    }
    function onOpenHandler( files ) {
        onOpen && onOpen( files ) ;
        onDragLeave() ;
    }

    function wrapper( children ) {
        return (
            ! disabled
            ? <DragAndDropFiles disabled={ disabled } focused={ focused } className="OpenFiles" onOpen={ onOpenHandler } onDragLeave={ onDragLeave } onDragOver={ onDragOver }>{ children }</DragAndDropFiles>
            : (
                <div className="OpenFiles">
                    <div className="wrapper" data-disabled={ disabled }>
                        { children }
                    </div>
                </div>
            )
        )
    }

    return wrapper(
        <>
            <div className="icon">
                <UploadOutlined />
            </div>
            <div className="description">
                { t("TicketProperty.uploader" ) }
                <p>{ t("FilesExplorer.tooltips.addFiles", {
                        extensions: SUPPORTED_FILES_EXTENSIONS,
                        size: [
                            MAX_FILESIZE.size,
                            t(`common.dataSizes.${ MAX_FILESIZE.type }`)
                        ].join(" ")
                    } ) }
                </p>
            </div>
        </>
    )
}
OpenFiles.defaultProps = {
    disabled: false
}
OpenFiles.propTypes = {
    id: PID,
    disabled: PropTypes.bool,
    onOpen: PropTypes.func
}

export default OpenFiles ;