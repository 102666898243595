import {TLayoutRenderer, ToolbarActions} from "../../../fields.dto";
import Read from "./renders/read";
import Write from "./renders/write";
import SendMessage from "./renders/send_message";
import ChangePassword from "./renders/change_password";
import DestroySession from "./renders/destroy_session";

const DataEditorLayouts = {
    [ ToolbarActions.READ ]: ( props: TLayoutRenderer ) => <Read { ... props } />,
    [ ToolbarActions.CREATE ]: ( props: TLayoutRenderer ) => <Write { ... props } />,
    [ ToolbarActions.WRITE ]: ( props: TLayoutRenderer ) => <Write { ... props } />,
    [ ToolbarActions.SEND_MESSAGE ]: (props: TLayoutRenderer ) => <SendMessage { ... props } />,
    [ ToolbarActions.CHANGE_PASSWORD ]: ( props: TLayoutRenderer ) => <ChangePassword { ... props } />,
    [ ToolbarActions.DESTROY_SESSION ]: ( props: TLayoutRenderer ) => <DestroySession { ... props } />,
}

export default DataEditorLayouts ;