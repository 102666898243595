import { combineReducers, configureStore } from "@reduxjs/toolkit";
import i18n from "./slices/i18n.slice";
import authentication from "./slices/authentication.slice";
import permissions from "./slices/permissions.slice";
import profile from "./slices/profile.slice";
import tickets from "./slices/tickets.slice";
import ticketProperty from "./slices/ticketProperty.slice";
import deps from "./slices/deps.slice";
import socket from "./slices/socket.slice";
import users from "./slices/users.slice";
import endpoints from "./slices/endpoints.slice";

const rootReducer = combineReducers({
    i18n,
    authentication,
    profile,
    permissions,
    tickets,
    deps,
    socket,
    ticketProperty,
    users,
    endpoints
});

export const setupStore = () => {
    return configureStore({
        reducer: rootReducer,
        devTools: true
    });
}

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore[ "dispatch" ];