import React, { useEffect, useMemo, useState } from 'react';
import { Input } from 'antd';
import { map } from 'lodash';
import Header from '../../../../../ui-kit/header';
import Spinner from '../../../../../ui-kit/spinner';
import { useTranslation } from "react-i18next";
import { searchCompanyClients } from "../../../../../../helpers/api.services";
import Logger from "../../../../../../helpers/logger";
import { LoadingOutlined } from '@ant-design/icons';
import PropTypes from "prop-types";
import { PCompanyUser } from "../../../../../../types/prop-types";
import '../../../../../../styles/components/company_selector.scss';

function ClientsSearcher( props ) {
    const { selected, onSelect } = props ;
    const { t } = useTranslation() ;
    const [ keyword, setKeyword ] = useState();
    const [ fetching, setFetching ] = useState( false );
    const [ data, setData ] = useState( false );

    /* debug */ Logger.info("ClientsSearcher([ props ])", props )
    /* debug */ Logger.log("ClientsSearcher([ data ])", data )

    async function search() {
        /* debug */ Logger.log("ClientsSearcher() find()", keyword ) ;

        if( ! keyword || keyword?.length < 2 ) return ; // reset() ;

        setFetching( true ) ;

        let payload = null,
            error = null ;

        try { payload = await searchCompanyClients( keyword ) }
        catch ( exception ) { error = exception }
        finally {
            ! error
                ? Logger.success("FromCompanySelector([ data ])", payload?.data )
                : Logger.error("FromCompanySelector([ error ])", error?.message )
        }

        setData( payload?.data ) ;
        setFetching( false ) ;
    }

    function onChangeHandler({ target:{ value } }) {
        /* debug */ Logger.log("FromCompanySelector() onChangeHandler([ value ])", value )
        setKeyword( value ) ;
    }

    useEffect( () => { search() }, [ keyword ]) ;
    const items = useMemo( () => {
        /* debug */ Logger.log("ClientsSearcher([ data, selected ])", { data, selected } )
        return map( data, ( company, key ) => {
            const { id, name, code } = company ;
            function onClickHandler() { onSelect( company ) }
            return (
                <div key={ key } className="client" onClick={ onClickHandler } data-selected={ selected?.id && id === selected?.id }>
                    <p><b>{ code }</b>{ name }</p>
                </div>
            )
        }) ;
    }, [ data ] )

    return (
        <div className="ClientsSearcher">
            <Header text={ t("TicketProperty.search.title") } size={ Header.sizes.middle }/>
            <Input placeholder={ t("TicketProperty.search.placeholder") } onChange={ onChangeHandler } value={ keyword } />
            <Spinner className="wrapper" spinVisible={ fetching } icon={ <LoadingOutlined /> }>
                <div className="results">
                    { items }
                </div>
            </Spinner>
        </div>
    )
}
ClientsSearcher.defaultProps = {}
ClientsSearcher.propTypes = {
    selected: PCompanyUser,
    onSelect: PropTypes.func
}

export default ClientsSearcher;