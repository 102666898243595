import useSocketHook from "../../../../../hooks/useSocket.hook";
import Logger from "../../../../../helpers/logger";
import { Tooltip } from 'antd';
import ClassNames from 'classnames';
import "../../../../../styles/components/call_chat_wrapper.scss";
import { useTranslation } from "react-i18next";

export default function CallChatWrapper({ children }) {
    const { init, isReady, send, error, socket, destroy } = useSocketHook() ;
    /* debug */ Logger.info( "CallChatWrapper([ socket ])", socket ) ;
    const { t } = useTranslation() ;

    return (
        <Tooltip
            rootClassName="CallChatWrapperMessage"
            className={ ClassNames( "CallChatWrapper", ! isReady ? "disabled" : "" ) }
            visible={ ! isReady }
            title={ t("errors.chatDisabled")}
            placement="top"
        >
            { children }
        </Tooltip>
    )
}