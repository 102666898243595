import React from "react";
import ClassNames from "classnames";
import PropTypes from "prop-types";
import Header from "../header";
import "../../../styles/components/ui-kit/spinner.scss";

function Spinner({ className, label, spinVisible, error, icon, children }) {
    return (
        <div className={ ClassNames( "Spinner", className ) } data-error={ error } data-spinning={ spinVisible }>
            { spinVisible && (
                <div key="wrapper" className="wrapper">
                    <div className="logo">
                        { ! icon ? <p className="icon">A</p> : icon }
                    </div>
                    { label && <Header size={ Header.sizes.small } text={ label }/> }
                </div>
            )}
            <span key="context" className="context">
                { children }
            </span>
        </div>
    )
}
Spinner.defaultProps = {
    size: "2em",
    spinVisible: true,
    error: false
}
Spinner.propTypes = {
    icon: PropTypes.element,
    size: PropTypes.string,
    error: PropTypes.bool,
    spinVisible: PropTypes.bool,
    className: PropTypes.string,
    label: PropTypes.string
}

export default Spinner ;
