import { useReduxDispatch, useReduxSelector } from "./redux.hook";
import { i18nActions } from "../store/slices/i18n.slice";
import { TLanguagesSupported, TString } from "../types";
import LocalStorage from "../helpers/local-storage";
import { LanguageDefault, LocalStoreKeys } from "../data/constants";
import { LocalStore_I } from "../types/intarfaces";
import { get } from "lodash";
import { getLanguageFromLocalStore } from "../helpers/common";
import i18n from "../helpers/i18n";
import { useTranslation } from "react-i18next";

export type TI18NHook = {
    changeLanguage: Function, initialize: Function, translator: Function, fromI18N: Function, value: TLanguagesSupported | null
}
export default function useI18N(): TI18NHook {

    const { language: value } = useReduxSelector( ( { i18n }) => i18n );
    const dispatch = useReduxDispatch();
    const { t: translator } = useTranslation();
    const { change } = i18nActions;
    const ls: LocalStore_I = LocalStorage( LocalStoreKeys.I18N ) ;

    function changeLanguage( lang: TLanguagesSupported ): void {
        ls.setState({ i18n: lang }) ;
        dispatch( change( lang ) ) ;
        void i18n.changeLanguage(lang);
    }
    function initialize() {
        const lang: TString = getLanguageFromLocalStore() ;

        if( ! lang ) {
            changeLanguage( LanguageDefault ) ;
        } else {
            ls.setState({ i18n: lang }) ;
            changeLanguage( lang as TLanguagesSupported ) ;
        }
    }
    function fromI18N( i18n: any ): string {
        return get( i18n, value || "", "" )
    }

    return { changeLanguage, initialize, fromI18N, translator, value }
}
