import { useEffect } from 'react';
import useI18N from "../../hooks/useI18N.hook";

export default function Bootstrap( props ) {
    const { children } = props ;
    const { initialize, value: language } = useI18N() ;

    function componentDidMount() {
        if( ! language ) initialize() ;
    }

    useEffect( () => { componentDidMount() }, [] )

    return (
        ! language
        ? null
        : children
    )
}
