import { PaperClipOutlined } from '@ant-design/icons';
import { Checkbox, Input, Popover } from 'antd';
import Button from '../../ui-kit/button';
import { filter, find, map } from 'lodash';
import { useState } from "react";
import PropTypes from "prop-types";
import AttachedFiles from "./AttachedFiles";
import Logger from "../../../helpers/logger";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

const { TextArea } = Input;

function CreateMessage( props ) {
    const { t } = useTranslation() ;
    const { files, onSend } = props ;
    const [ text, setText ] = useState( null ) ;
    const [ selected, setSelected ] = useState([]) ;
    const [ isFilesSelectorVisible, setFilesSelectorVisible ] = useState( false ) ;
    const isNoFilesInAttachment = ! Boolean( files?.length ) ;
    const FilesSelectorCtrl = {
        open: () => setFilesSelectorVisible( true ),
        close: () => setFilesSelectorVisible( false )
    }

    function onChangeTextHandler({ target:{ value }}) {
        setText( value ) ;
    }
    function onSelectFileHandler() {
        FilesSelectorCtrl.close() ;
    }
    function onCheckFilesHandler( file ) {
        const { hash } = file ;
        setSelected( ( state ) => {
            const isExists = state.includes( hash ) ;
            return (
                ! isExists
                ? [ ... state, hash ]
                : filter( state, hash_=> hash_ !== hash )
            )
        })
    }
    function onSendHandler() {
        onSend && onSend({ files: selected, text }) ;
        setSelected([]) ;
        setText("");
    }
    function onRemoveAttachment( hash ) {
        setSelected( ( state ) => {
            const isExists = state.includes( hash ) ;
            return (
                ! isExists
                ? [ ... state, hash ]
                : filter( state, hash_=> hash_ !== hash )
            )
        })
    }

    const items = map( files, ( file, key ) => {
        const checked = selected.includes( file?.hash ) ;
        return (
            <div key={ key } className="file" onClick={ () => onCheckFilesHandler( file ) }>
                <Checkbox checked={ checked } />
                { file?.name }
            </div>
        )
    })
    const selectedFiles = map( selected, ( hash ) => {
        return find( files, { hash })
    })

    /* debug */ Logger.info( `CreateMessage([ selected, files ])`, { selected, files } ) ;

    return (
        <div className="CreateMessage">
            <div className="left">
                <div className={ classNames("left","text") } >
                    <TextArea
                        autoFocus={true}
                        rows={4}
                        placeholder={t("Chat.placeholder")}
                        maxLength={500}
                        value={text}
                        onChange={onChangeTextHandler}
                    />
                    <AttachedFiles readonly={false} files={selectedFiles} onRemove={onRemoveAttachment}/>
                </div>
                <div className="left">
                    <Popover
                        overlayClassName="ChatFilesSelector"
                        placement="topLeft"
                        content={
                            <>
                                {items}
                                <div className="footer">
                                    {/* <Button label="выбрать" onClick={ onSelectFileHandler }/> */}
                                    <Button ghost label={t("common.close")} onClick={FilesSelectorCtrl.close}/>
                                </div>
                            </>
                        }
                        title="Выберите файл(ы)"
                        trigger="click"
                        open={isFilesSelectorVisible}
                        onOpenChange={() => ({})}
                    >
                        <PaperClipOutlined
                            className="attach-files"
                            disabled={isNoFilesInAttachment}
                            onClick={() => !isNoFilesInAttachment && setFilesSelectorVisible(true)}
                        />
                    </Popover>
                </div>
            </div>
            <div className="left">
                <Button className="send" label={t("Chat.send")} onClick={onSendHandler}/>
            </div>
        </div>
    )
}

CreateMessage.defaultProps = {};
CreateMessage.propTypes = {
    files: PropTypes.array,
    onSend: PropTypes.func
}

export default CreateMessage;