import React from "react";
import Permission from "../../../../../ui-kit/permission";
import {PERMISSIONS, UI} from "../../../../../../dto";
import {EditFilled, EyeFilled, LockFilled} from '@ant-design/icons';
import {Tooltip} from 'antd';
import classNames from "classnames";
import {ToolbarActions, TUsersManagerComponentOptions} from "../../../fields.dto";
import Logger from "../../../../../../helpers/logger";

const ToolbarRenderer = ( options: TUsersManagerComponentOptions ) => ( props: any ) => {
    const { data, column: { colId: id } } = props ;
    const { t } = options ;

    /* debug */ Logger.info( `ToolbarRenderer([ ${ id } ])`, { props, options } ) ;

    function onChangePasswordHandler() {
        options?.onOkay && options.onOkay({ id, mode: ToolbarActions.CHANGE_PASSWORD, data })
    }

    return (
        <span className="ToolbarRenderer">
            <Permission.Verifycator id={ UI.USERS_ACTIONS_TOOLBAR } name={ PERMISSIONS.READ } value="1" whileFailed={ null  }>
                <>
                    {/*

                    <Tooltip key={ ToolbarActions.REMOVE } placement="top" title={ t("common.remove") }>
                        <div className={ classNames("Button", "RemoveButton") } onClick={ () => options?.onOkay && options.onOkay({ id, mode: ToolbarActions.REMOVE, data }) }>
                            <DeleteFilled/>
                        </div>
                    </Tooltip>

                    */}
                    {/*
                    <Permission.Verifycator id={UI.DESTROY_ACCESS_TOKEN_BUTTON} name={ PERMISSIONS.READ } value="1" whileFailed={ null }>
                        <Tooltip key={ ToolbarActions.REMOVE } placement="top" title={ t("Users.destroySessionToken") }>
                            <div className={ classNames("Button", "DestroySessionButton", isAccessTokenNotExists && "disabled" ) } onClick={ () => options?.onOkay && options.onOkay({ id, mode: ToolbarActions.DESTROY_SESSION, data }) }>
                                <SafetyCertificateFilled />
                            </div>
                        </Tooltip>
                    </Permission.Verifycator>
                    */}
                    <Permission.Verifycator id={UI.CHANGE_PASSWORD_BUTTON } name={ PERMISSIONS.READ } value="1" whileFailed={ null }>
                        <Tooltip key={ ToolbarActions.SEND_MESSAGE } placement="top" title={ t("Users.changePassword") }>
                            <div className={ classNames( "Button", "ChangePasswordButton" ) } onClick={ onChangePasswordHandler }>
                                <LockFilled/>
                            </div>
                        </Tooltip>
                    </Permission.Verifycator>
                    <Tooltip key={ ToolbarActions.WRITE } placement="top" title={ t("common.edit") }>
                        <div className={ classNames( "Button", "EditButton" ) } onClick={ () => options?.onOkay && options.onOkay({ id, mode: ToolbarActions.WRITE, data }) }>
                            <EditFilled/>
                        </div>
                    </Tooltip>
                    <Tooltip key={ ToolbarActions.READ } placement="top" title={ t("common.view") }>
                        <div className={ classNames( "Button", "ReadButton" ) } onClick={ () => options?.onOkay && options.onOkay({ id, mode: ToolbarActions.READ, data }) }>
                            <EyeFilled/>
                        </div>
                    </Tooltip>
                </>
            </Permission.Verifycator>
        </span>
    )
}

export default ToolbarRenderer;