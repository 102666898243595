import { useState } from "react";
import Logger from "../helpers/logger";
import { filter, find, map } from "lodash";
import MD5 from "md5";
import { getSettings } from "../data/settings";
import { TTicketLocalFile } from "../types";
import { getUploadCSRF, sendFile } from "../helpers/api.services";
import { AxiosProgressEvent } from "axios";
import { FILES_STATUSES } from "../dto";
import { useTranslation } from "react-i18next";

export type TFileManagerHook = {
    id: number,
    data: any[],
    add: Function,
    addMany: Function,
    setAltNameByHash: Function,
    setCommentByHash: Function,
    remove: Function,
    uploadAll: Function,
    reset: Function,
    setFileStatus: Function
}
export type TFileManagerHookParams = {
    id: number,
    initialState: TTicketLocalFile[],
    onUploaderProgress: Function
}
export function useFileManagerHook( params: TFileManagerHookParams ): TFileManagerHook {
    const { id, initialState, onUploaderProgress } = params ;
    const [ data, setData ] = useState<TTicketLocalFile[]>( initialState || [] ) ;
    const { t } = useTranslation() ;

    function getFileHash( file: TTicketLocalFile ): string {
        const { path, name, lastModified, size, type } = file ;
        const fields = [ path, name, lastModified, size, type ].join() ;
        const hash = MD5( fields ) ;

        return hash.toString() ;
    }
    function add( file: TTicketLocalFile ) {
        /* debug */ Logger.saga( "useFileManagerHook() add([ file ])", file ) ;

        file.hash = getFileHash( file ) ;
        file.altName = file.name ;
        file.disabled = false ;
        file.error = null ;
        file.isLocal = true ;
        file.isRenameEnabled = true ;
        file.status_id = FILES_STATUSES.PENDING ;
        file.status_description = null ;

        /* debug */ Logger.saga( "useFileManagerHook() add([ uploaderFileSizeLimitBytes ])", getSettings().uploaderFileSizeLimitBytes ) ;

        const serviceSettings = getSettings() ;
        const SIZE_LIMIT: number = serviceSettings.uploaderFileSizeLimitBytes ;
        const EXTENSIONS: string[] = serviceSettings.uploaderExtensionAllowed ;
        if( file.size >= SIZE_LIMIT ) {
            const message: string = t( "errors.uploaderFileSizeLimit", { size: SIZE_LIMIT } ) ;
            file.error = new Error( message ) ;
        } else {
            const extension: string = file.name.toLowerCase().split(".").pop() || "" ;
            if( ! EXTENSIONS.includes( extension ) ) {
                const message: string = t( "errors.uploaderFileExtensionRejected" ) ;
                file.error = new Error( message ) ;
            }

            /* debug */ Logger.saga( "useFileManagerHook() add([ EXTENSIONS ])", { extension, EXTENSIONS } ) ;
        }

        const isExists = find( data, { hash: file.hash } ) ;
        ! isExists && setData( ( files ) => ([ file, ... files ])) ;
    }
    function addMany( files: TTicketLocalFile[] ) {
        files.forEach( add ) ;
    }
    function setAltNameByHash( hash:string, altName: string, extension: string ) {
        /* debug */ Logger.saga( "useFileManagerHook() setAltNameByHash([ hash, altName, data ])", { hash, altName, data } ) ;
        setData( _ => map( _, ( file: TTicketLocalFile ) => {
            if( file.hash === hash ) {
                file.altName = `${ altName }.${ extension }` ;
            }
            return file ;
        }) )
    }
    function setCommentByHash( hash:string, comment: string ) {
        /* debug */ Logger.saga( "useFileManagerHook() setCommentByHash([ hash, comment, data ])", { hash, comment, data } ) ;
        setData( _ => map( _, ( file: TTicketLocalFile ) => {
            if( file.hash === hash ) {
                file.comment = comment ;
            }
            return file ;
        }) )
    }
    function setFileStatus( { hash, status_description, status_id }: TTicketLocalFile ) {
        setData( _ => map( _, ( file: TTicketLocalFile ) => {
            if( file.hash === hash ) {
                file.status_id = status_id ;
                file.status_description = status_description ;
            }
            return file ;
        }) )
    }
    function remove( hash: string ) {
        /* debug */ Logger.saga( "useFileManagerHook() remove([ hash, data ])", { hash, data } ) ;
        setData( ( state ) => filter( state, ( file: TTicketLocalFile ) => file.hash !== hash ) )
    }
    function disableAll() {
        /* debug */ Logger.saga( "useFileManagerHook() disableAll()" ) ;
        setData( _ => map( _, ( file: TTicketLocalFile ) => {
            file.disabled = true ;
            return file ;
        }) )
    }

    function uploadAll(): Promise<any> {
        return new Promise( async ( resolve, reject ) => {

            // удаляем из списка файлы - которые помечены как с ошибками
            const $data = ( data || [] ).filter( ({ error }) => ! error );

            /* debug */ Logger.saga( "useFileManagerHook() uploadAll([ $data ])", $data ) ;

            if( ! ( $data && $data.length ) ) return Promise.resolve() ;

            let payload: any ;
            try { payload = await getUploadCSRF( id ) }
            catch ( exception: any ) {
                /**
                 * @todo Исключение при получении CSRF нужо (как-то) визуализировать!
                 */
                /* error */ Logger.error( "useFileManagerHook([ error ]) uploadAll([ message ])", exception ) ;
                return reject( exception?.message ) ;
            }

            const csrf: string = payload?.data?.csrf ;
            if( csrf ) {
                disableAll() ;
                try {
                    const payload = await sendFile( csrf, $data, ( event: AxiosProgressEvent ) => {
                        /* debug */ Logger.warn( "useFileManagerHook() uploadAll([ event ])", event );
                        onUploaderProgress && onUploaderProgress( event );
                        const isUploadingComplete: boolean = event.loaded === event.total;
                        isUploadingComplete && Logger.success( "useFileManagerHook() uploadAll([ DONE ])", csrf, "Data already uploaded" );
                    } );
                    return resolve( payload );
                } catch ( exception: any ) {
                    /* error */ Logger.error( "useFileManagerHook([ exception ]) uploadAll([ message ])", exception?.message ) ;
                    return reject( exception?.message );
                }
            }
        })
    }
    function reset() {
        setData( initialState || [] ) ;
    }

    return { id, setFileStatus, uploadAll, remove, setCommentByHash, setAltNameByHash, data, add, addMany, reset }
}