import Logger from "../../helpers/logger";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    fetched: false,
    fetching: false,
    error: null,
    meta: null,
    data: []
}

export const users = createSlice({
    name: 'endpoints',
    initialState,
    reducers: {
        setFetch( state, { payload:{ fetched, fetching } }) {
            /* debug */ Logger.reducer( "endpoints.setFetch([ fetched, fetching ])", { fetched, fetching }) ;
            state.fetched = fetched ;
            state.fetching = fetching ;
        },
        setData( state, { payload:{ error, data, meta } }) {
            /* debug */ Logger.reducer( "endpoints.setData([ error, data, paginator ])", { error, data }) ;
            state.error = error ;
            state.data = data ;
            state.meta = meta ;
        },
    }
})

const { reducer, actions } = users ;
export const endpointsActions = actions;
export default reducer;
