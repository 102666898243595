import { useState } from "react";
import { TError } from "../types";
import Logger from "../helpers/logger";
import { verifyEmployees } from "../helpers/api.services";
import { isUndefined } from "lodash";
import { TCrossMessagesData } from "../helpers/cross-messages";
import { getCompaniesSigners } from "../helpers/cross-messages.services";

export type TCompanySignersHookState = {
    fetching: boolean,
    fetched: boolean,
    data: any[] | undefined,
    error: TError | null
}
export type TCompaniesHook = {
    state: TCompanySignersHookState,
    verified: TCompanySignersHookState,
    prepare: Function,
    reset: Function,
    verify: Function,

    isStateLoading: boolean,
    isStateReady: boolean,
    isVerificationLoading: boolean,
    isVerificationReady: boolean
}

const defaultState: TCompanySignersHookState = {
    fetching: false,
    fetched: false,
    data: undefined,
    error: null
}
export default function useCompanySignersHook(): TCompaniesHook {
    const [ state, setState ] = useState<TCompanySignersHookState>( defaultState ) ;
    const [ verified, setVerified ] = useState<TCompanySignersHookState>( defaultState ) ;

    function reset() {
        setState( defaultState ) ;
        setVerified( defaultState ) ;
    }
    async function prepare( props: any ) {

        reset() ;
        setState( ( _ ) => ({ ... _, fetching: true }) ) ;

        const { data } = <TCrossMessagesData> await getCompaniesSigners( props ) ;

        /* debug */ Logger.saga( "useCompanySignersHook() prepare([ data ])", data ) ;

        setState( ( _ ) => ({ ... _, data, fetching: false, fetched: true }) )
    }
    async function verify( company_id: number, user_ids: number[] ) {
        /* debug */ Logger.saga( "useCompanySignersHook() verify([ company_id, user_ids ])", { company_id, user_ids } ) ;

        setVerified( ( _ ) => ({ ... defaultState, fetching: true }) ) ;

        let payload: any,
            error: any ;

        try { payload = await verifyEmployees( company_id, user_ids ) }
        catch ( exception: any ) {
            const message = exception?.message || "[ useCompanySignersHook ] unhandled error" ;
            error = { message }
            /* error */ Logger.error( "useCompanySignersHook.load([ error ])", { error } ) ;
        }

        ! error && Logger.success( "useCompanySignersHook() verify([ data ])", payload?.data ) ;

        ! error
            ? setVerified( _state => ({ ... _state, data: payload?.data, error: null }) )
            : setVerified( _state => ({ ... _state, data: [], error }) ) ;

        setVerified( _state => ({ ... _state, fetched: true, fetching: false }) ) ;
    }

    const isStateLoading = ! state.fetched && state.fetching ;
    const isStateReady = ! Boolean( isStateLoading || state.error || isUndefined( state.data ) ) ;
    const isVerificationLoading = ! verified.fetched && verified.fetching ;
    const isVerificationReady = ! Boolean( isVerificationLoading || verified.error || isUndefined( verified.data ) ) ;

    return {
        isVerificationLoading,
        isVerificationReady,
        verified,
        verify,

        isStateLoading,
        isStateReady,
        state,
        prepare,

        reset
    }
}
