import React from "react";
import ClassNames from "classnames";
import PropTypes from "prop-types";
import { uniqueId } from "lodash";
import "../../../styles/components/ui-kit/input.scss";

function Input({ className, disabled, id, type, label, value, placeholder, error, onChange }) {
    function onChangeHandler( event ) {
        onChange && onChange( id, event.target.value ) ;
    }
    return (
        <div className={ ClassNames( "Input", className, disabled ? "disabled" : "" ) } data-error={ Boolean( error ) }>
            { label && (
                <label data-type="label" htmlFor={ id }>
                    { label }
                </label>
            ) }
            <input disabled={ disabled} id={ id } data-type="value" autoComplete="off" placeholder={ placeholder } type={ type } value={ value } onChange={ onChangeHandler } />
            { error && <div className="error-message">{ error }</div> }
        </div>
    )
}
Input.defaultProps = {
    id: uniqueId( "input_" ),
    disabled: false,
    type: "text",
    label: null,
    value: "",
    error: null,
    placeholder: ""
}
Input.propTypes = {
    id: PropTypes.string,
    disabled: PropTypes.bool,
    type: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    value: PropTypes.string,
    error: PropTypes.string,
    onChange: PropTypes.func
}

export default Input ;
