import { TApplicationSettings, TEnvironmentsSupported } from "../types";

export function getSettings(): TApplicationSettings {
    return {
        environment: process.env.NODE_ENV as TEnvironmentsSupported,
        apiHostUrl: <string> process.env.REACT_APP_API_URL,
        subRoute: <string> process.env.REACT_APP_ROUTE_SUB,
        iFrameNamespace: <string> process.env.REACT_APP_IFRAME_NAMESPACE,
        iitHistUrl: <string> process.env.REACT_APP_IIT_SIGN_WIDGET_URI,
        webSocketHost: <string> process.env.REACT_APP_WEBSOCKET_HOST,
        uploaderFileSizeLimitBytes: parseInt( String( process.env.REACT_APP_UPLOADER_FILE_SIZE_LIMIT_IN_BYTES ) ),
        uploaderExtensionAllowed: `${ process.env.REACT_APP_UPLOADER_FILE_EXTENSION_ALLOWED || "" }`.toLowerCase().split("|")
    }
}