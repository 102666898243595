import React from "react";
import ClassNames from "classnames";
import PropTypes from "prop-types";
import { find } from "lodash";
import useI18N from "../../../hooks/useI18N.hook";
import { useReduxSelector } from "../../../hooks/redux.hook";
import { jsonToObject } from "../../../helpers/common";
import "../../../styles/components/ui-kit/dep_label.scss";

function DepLabel({ id, className }) {
    const { data } = useReduxSelector( ({ deps }) => deps ) ;
    const { fromI18N } = useI18N() ;
    const dept = find( data, { id } ) ;

    return (
        <div className={ ClassNames( "DepLabel", className ) }>
            <b>{ dept?.title ? fromI18N( jsonToObject( dept.title ) ) : "?" }</b>
            <p>{ dept?.description ? fromI18N( jsonToObject( dept.description ) ) : "..." }</p>
        </div>
    )
}
DepLabel.defaultProps = {}
DepLabel.propTypes = {
    id: PropTypes.number
}

export default DepLabel ;
