import React from "react";
import PropTypes from "prop-types";
import { PSignVerifier } from "../../../../../types/prop-types";
import Label from "../../../../ui-kit/label";
import { Tooltip } from 'antd';
import { random } from 'lodash';
import { SafetyCertificateFilled, WarningFilled, SafetyCertificateOutlined } from '@ant-design/icons';

function TabLabel( props ) {
    const { verified, description, title } = props ;
    function drawIcon() {

        const isFailed = ! verified || verified?.error || ! verified.hasOwnProperty( "exists" ) ;
        if( isFailed ) return (
            <Tooltip placement="top" title={ verified?.error?.message || verified?.error || "Unhandled error" }>
                <WarningFilled/>
            </Tooltip>
        )

        const isExists = +verified.exists === 1 ;

        return (
            ! isExists
            ? <SafetyCertificateOutlined className="unsafe" />
            : <SafetyCertificateFilled className="verified" />
        )
    }

    return (
        <div className="TabLabel">
            <div className="icon">
                { drawIcon() }
            </div>
            <Label.WithHeader value={ title } placeholder={ description } />
        </div>
    )
}

TabLabel.propTypes = {
    title: PropTypes.string,
    description: Promise.string,
    verified: PSignVerifier
}
TabLabel.defaultProps = {

}

export default TabLabel ;