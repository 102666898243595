import {useState} from "react";
import Logger from "../../../../../../../helpers/logger";
import {UsersFields} from "../../../../fields.dto";
import {Input, Select} from 'antd';
import {find, map} from 'lodash';
import {jsonToObject} from "../../../../../../../helpers/common";
import {SIGN_RIGHTS} from "../../../../../../../dto";
import KeyIcon from "../../../../../../ui-kit/key_icon";
import CompaniesRenderer from "../../../CellRenderers/renders/companies";
import DataCaption from "../../../DataCaption";
import Button from "../../../../../../ui-kit/button";
import Header from "../../../../../../ui-kit/header";

export default ( props ) => {
    /* debug */ Logger.info( `write([ ${ props.context.id } ])`, props ) ;

    const { context:{ data, meta, mode }, options } = props ;
    const { t, fromI18N, onOkay, onCancel } = options ;
    const [ state, setState ] = useState( data ) ;

    const onChangeHandler = ( id ) => ( event ) => {
        /* debug */ Logger.warn( `write.onChangeHandler([ ${ id } ])`, event ) ;
        setState( ( state_ ) => ({ ... state_, [ id ]: event }) ) ;
    }
    const onOkayHandler = () => {
        onOkay( state, mode ) ;
    }

    function drawValueDropdown( id, mapper, onChange, data = [] ) {
        const metadata = meta[ id ]?.data ;
        const value = state[id] ;
        const $mapped = map( metadata, mapper ) || [];
        const options = [ ... data || [], ... $mapped || [] ] ;

        return (
            <Select
                value={ value }
                options={ options }
                onChange={ onChange( id ) }
            />
        )
    }
    function drawValueI18nDropdown( field, mapper, onChange ) {
        const metadata = meta[ field ]?.data ;
        const id = state[ field ] ;
        const props = find( metadata, { id } ) ;
        const value = jsonToObject( props?.title ) ;
        const dataset = map( metadata, mapper ) || [];

        return (
            <Select
                value={ fromI18N( value ) }
                options={ dataset }
                onChange={ onChange( field ) }
            />
        )
    }

    const role_id = (
        <div data-field={ UsersFields.role_id }>
            { drawValueDropdown( UsersFields.role_id, ({ id: value, description: label } ) => ({ value, label }), onChangeHandler ) }
        </div>
    )

    const rule_id = (
        <div data-field={ UsersFields.rule_id }>
            { drawValueDropdown( UsersFields.rule_id, ({ id: value, description: label } ) => ({ value, label }), onChangeHandler ) }
        </div>
    )

    const hosts_id = (
        <div data-field={ UsersFields.hosts_id }>
            { drawValueDropdown( UsersFields.hosts_id, ({ id: value, host } ) => ({ value, label: ( <><b>{ value }&nbsp;</b>{ host }</> ) }), onChangeHandler, [{ value: null, label: t("common.none")}] ) }
        </div>
    )

    const signerOptions = [
        { label: SIGN_RIGHTS.ALL, value: t( `common.signers.${ SIGN_RIGHTS.ALL }` ) },
        { label: SIGN_RIGHTS.PRIMARY, value: t( `common.signers.${ SIGN_RIGHTS.PRIMARY }` ) },
        { label: SIGN_RIGHTS.SECONDARY, value: t( `common.signers.${ SIGN_RIGHTS.SECONDARY }` ) },
        { label: SIGN_RIGHTS.DISABLED, value: t( `common.signers.${ SIGN_RIGHTS.DISABLED }` ) }
    ] ;
    const signerCurrentValue = state[ UsersFields.signer] ;
    const signer = (
        <div data-field={ UsersFields.signer }>
            { map( signerOptions, ({ label, value }) => (
                <b className="sign" data-selected={ signerCurrentValue === label } onClick={ () => onChangeHandler( UsersFields.signer )( label ) }>
                    <KeyIcon signRights={ label }/>
                    { value }
                </b>
            )) }
        </div>
    )

    const dep_id = (
        <div data-field={ UsersFields.dep_id }>
            { drawValueI18nDropdown( UsersFields.dep_id, ({ id: value, title }) => ({ value, label: fromI18N(jsonToObject(title)) }), onChangeHandler)}
        </div>
    )

    const name = (
        <div data-field={ UsersFields.name }>
            <Input value={ state[ UsersFields.name ] } onChange={ ({ target:{ value } }) => onChangeHandler( UsersFields.name )( value ) } />
        </div>
    ) ;

    const surname = (
        <div data-field={ UsersFields.surname }>
            <Input value={ state[ UsersFields.surname ] } onChange={ ({ target:{ value } }) => onChangeHandler( UsersFields.surname )( value ) } />
        </div>
    ) ;

    const lastname = (
        <div data-field={ UsersFields.lastname }>
            <Input value={ state[ UsersFields.lastname ] } onChange={ ({ target:{ value } }) => onChangeHandler( UsersFields.lastname )( value ) } />
        </div>
    ) ;

    const middlename = (
        <div data-field={ UsersFields.middlename }>
            <Input value={ state[ UsersFields.middlename ] } onChange={ ({ target:{ value } }) => onChangeHandler( UsersFields.middlename )( value ) } />
        </div>
    ) ;

    const position = (
        <div data-field={ UsersFields.position }>
            <Input value={ state[ UsersFields.position ] } onChange={ ({ target:{ value } }) => onChangeHandler( UsersFields.position )( value ) } />
        </div>
    ) ;

    return (
        <div className="WriteUserDataLayout" data-mode={ mode }>
            <Header text={ t( `Users.modal.${ mode }.header` ) } size={ Header.sizes.middle }/>
            <div className="wrapper">
                <span data-row="4">
                    <DataCaption label={t(`Users.columns.${UsersFields.name}`)} bordered={false}>{name}</DataCaption>
                    <DataCaption label={t(`Users.columns.${UsersFields.surname}`)} bordered={false}>{surname}</DataCaption>
                    <DataCaption label={t(`Users.columns.${UsersFields.lastname}`)} bordered={false}>{lastname}</DataCaption>
                    <DataCaption label={t(`Users.columns.${UsersFields.middlename}`)} bordered={false}>{middlename}</DataCaption>
                </span>
                <span data-row="3">
                    <DataCaption label={t(`Users.columns.${UsersFields.dep_id}`)} bordered={false}>{dep_id}</DataCaption>
                    <DataCaption label={t(`Users.columns.${UsersFields.role_id}`)} bordered={false}>{role_id}</DataCaption>
                    <DataCaption label={t(`Users.columns.${UsersFields.position}`)} bordered={false}>{position}</DataCaption>
                </span>
                <span data-row="2">
                    <DataCaption label={t(`Users.columns.${UsersFields.rule_id}`)} bordered={false}>{rule_id}</DataCaption>
                    <DataCaption label={t(`Users.columns.${UsersFields.hosts_id}`)} bordered={false}>{hosts_id}</DataCaption>
                </span>
                <DataCaption label={t(`Users.columns.${ UsersFields.signer }`)} bordered={false}>{signer}</DataCaption>
            </div>
            <div className="footer">
                <Button ghost className="close" label={ t("common.cancel") } onClick={ onCancel }/>
                <Button className="save" label={ t("common.saveChanges") } onClick={ onOkayHandler }/>
            </div>
        </div>
)
}       