import { useEffect, useState } from "react";
import ViewMessages from './components/ViewMessages';
import Spinner from '../ui-kit/spinner';
import CreateMessage from './components/CreateMessage';
import Logger from "../../helpers/logger";
import PropTypes from "prop-types";
import useChatHook from "../../hooks/useChat.hook";
import "../../styles/components/chat.scss";

function Chat( props ) {
    const { id, files, profile } = props ;
    const [ messages, setMessages ] = useState([]) ;
    const [ error, setError ] = useState() ;
    const [ fetching, setFetching ] = useState( null ) ;
    const { send, init, enter, leave } = useChatHook( id ) ;

    function onSend( message ) {
        /* debug */ Logger.warn( "Chat() onSelectFiles([ message ])", message ) ;
        //  setMessages(( state ) => ([ ... state, message ])) ;
        const { text, files } = message ;
        ( text || files?.length ) && send( text, { files } ) ;
    }

    async function loadMessages() {
        setFetching( true ) ;

        let payload, error ;

        try { payload = await init() }
        catch( exception ) { error = exception }

        if( error ) return setError( error?.message ) ;

        const data = payload?.data || [] ;
        setMessages( data ) ;

        enter() ;

        setFetching( false ) ;
    }
    async function componentDidMount() {
        if( ! fetching && id ) {
            /* debug */ Logger.info( `Chat() componentDidMount([ ${ id } ])`, fetching, { messages, files, error, profile } ) ;
            await loadMessages() ;
        }
    }

    useEffect( () => {
      ( async  () => ( await componentDidMount() ))() ;
        return () => { leave() }
    }, [ id ] ) ;

    return (
        <Spinner spinVisible={ fetching }>
            <div className="Chat">
                <div className="wrapper">
                    <div className="messages">
                        <ViewMessages
                            id={ id }
                            filesAvailable={ files }
                            owner_id={ profile?.$id }
                            messages={ messages }
                        />
                    </div>
                    <div className="footer">
                        <CreateMessage
                            files={ files }
                            onSend={ onSend }
                        />
                    </div>
                </div>
            </div>
        </Spinner>
    )
}
Chat.defaultProps = {} ;
Chat.propTypes = {
    id: PropTypes.number.isRequired,
    profile: PropTypes.object,
    files: PropTypes.array
}

export default Chat ;