import Logger from "../../helpers/logger";
import { createSlice, current } from "@reduxjs/toolkit";
import { filter, keys, set } from "lodash";
import { PAGINATOR_DEFAULTS_PAGE_NO, PAGINATOR_DEFAULTS_PER_PAGE_LIMIT, SORT_DIRECTIONS, TICKET_STATUSES } from "../../dto";

const initialState = {
    fetched: false,
    fetching: true,
    error: null,
    search: null,
    filter: {
        status_id: null
    },
    paginator: {
        total: 0,
        pageNo: PAGINATOR_DEFAULTS_PAGE_NO,
        perPageLimit: PAGINATOR_DEFAULTS_PER_PAGE_LIMIT,
        sortBy: "id",
        sortDirection: SORT_DIRECTIONS.DESC
    },
    data: []
}

export const ticketsSlice = createSlice({
    name: 'tickets',
    initialState,
    reducers: {
        setFetch( state, { payload:{ fetched, fetching } }) {
            /* debug */ Logger.reducer( "ticketsSlice.setFetch([ fetched, fetching ])", { fetched, fetching }) ;
            state.fetched = fetched ;
            state.fetching = fetching ;
        },
        setData( state, { payload:{ error, data, paginator } }) {
            /* debug */ Logger.reducer( "ticketsSlice.setData([ error, data, paginator ])", { error, data, paginator }) ;
            state.error = error ;
            state.data = data ;
            state.paginator = {
                ... state.paginator || {},
                total: paginator?.total
            }
        },
        setPageNo( state, { payload }) {
            /* debug */ Logger.reducer( "ticketsSlice.setPageNo([ payload ])", payload ) ;
            state.paginator.pageNo = payload ;
        },
        setSearch( state, { payload }) {
            /* debug */ Logger.reducer( "ticketsSlice.setSearch([ payload ])", payload ) ;
            state.search = payload ;
        },
        setPerPageLimit( state, { payload }) {
            /* debug */ Logger.reducer( "ticketsSlice.setPerPageLimit([ payload ])", payload ) ;
            state.paginator.perPageLimit = payload ;
        },
        setFilter( state, { payload }) {
            /* debug */ Logger.reducer( "ticketsSlice.setFilter([ payload ])", payload ) ;
            state.filter = payload ;
        },
        setSort( state, { payload } ) {
            /* debug */ Logger.reducer( "ticketsSlice.setSort([ payload ])", payload ) ;
            state.paginator.sortBy = payload?.sortBy ;
            state.paginator.sortDirection = payload?.sortDirection ;
        },
        remove( state, { payload } ) {
            const { id } = payload ;
            /* debug */ Logger.reducer( "ticketsSlice.remove([ id ])", id ) ;
            state.data = filter( state.data || [], ({ id: _id }) => _id !== id )
        },
        setTaskWorker( state, { payload }) {
            const { $user, ticket_id } = payload ;
            /* debug */ Logger.reducer("ticketsSlice() setTaskWorker([ $user, ticket_id ])", payload ) ;

            for( let i: number = 0, _length = state.data.length ; i < _length ; i++ ) {
                const { id } = state.data[i];
                if( id === ticket_id ) {
                    set( state.data[i], "status_id", TICKET_STATUSES.PROCESSED ) ;
                    set( state.data[i], "is_favorite", true ) ;
                    set( state.data[i], "$user", $user ) ;
                    set( state.data[i], "user_id", $user?.user_id ) ;
                    break ;
                }
            }
        },
        update( state, { payload }) {
            /* debug */ Logger.reducer("ticketsSlice() update([ payload ])", payload ) ;
            const { ticket_id, data } = payload ;

            for( let i: number = 0, _length = state.data.length ; i < _length ; i++ ) {
                const { id } = state.data[i];
                if( id === ticket_id ) {
                    keys( data ).forEach( ( field ) => {
                        const value = data[ field ] ;
                        set( state.data[i], field, value ) ;
                    } )
                    break ;
                }
            }

            /* debug */ Logger.reducer("ticketsSlice() update([ payload ])", current( state ) ) ;

        }
    }
})

const { reducer, actions } = ticketsSlice ;
export const ticketsActions = actions;
export default reducer;
