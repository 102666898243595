import Logger from "../helpers/logger";
import { w3cwebsocket as W3C } from 'websocket';
import { Error_I } from "../types";
import { useReduxDispatch, useReduxSelector } from "./redux.hook";
import { socketActions } from "../store/slices/socket.slice";
import md5 from "md5";
import { jsonToObject } from "../helpers/common";
import EventDispatcher, { DispatchableEvent } from "../helpers/event-dispatcher";
import Session from "../helpers/session";
import {useTranslation} from "react-i18next";

/**
 * @todo
 * Не хватает проверки на разрыв соединения ;
 * В случае разрыва - пытаемся восстановить соединение ;
 */

export type TSocketHook = {
    socket: W3C | null,
    dispatcher: EventDispatcher,
    error: Error_I | null,
    isReady: boolean,
    init: Function,
    send: Function,
    destroy: Function,
    doPingPong: Function
}

const dispatcher = new EventDispatcher() ;
let $_singletonInstance: any = null ;

export enum SOCKET_DATA_TYPES {
    MESSAGE_CHAT = "MESSAGE_CHAT",
    MESSAGE_P2P = "MESSAGE_P2P",
    NOTIFY_CHANGES = "NOTIFY_CHANGES"
}

let _PingPongID: any = null ;
function useSocketHook(): TSocketHook {

    const { t } = useTranslation() ;
    const dispatch = useReduxDispatch();

    const { isReady, error } = useReduxSelector( ({ socket }) => socket ) ;
    const { update, reset } = socketActions ;
    const _isReady = isReady && $_singletonInstance?.readyState === 1 ;

    function _onOpen( event: any ) {
        /* debug */ Logger.warn( "useSocketHook() _onOpen([ event ])", event ) ;

        const connection: any = event?.target ;
        /* debug */ Logger.warn( "useSocketHook() _onOpen([ connection ])", connection ) ;

        const isReady = Boolean( connection ) ;

        if( isReady ) {
            $_singletonInstance = connection ;
            dispatch( update({ isReady }) ) ;
        } else {
            destroy() ;
            dispatch( update({ isReady: false }) ) ;
        }
    }
    function _onError( event: any ) {
        /* debug */ Logger.error( "useSocketHook() _onError([ error ])", event ) ;
        const message: string = `${ t( "errors.chatDisabled" ) }` ;
        setError({ message }) ;
    }
    function _onClose( event: any ) {
        /* debug */ Logger.warn( "useSocketHook() _onClose([ event ])", event ) ;
        const message: string = `${ t( "errors.connectionTerminated" ) }` ;
        setError({ message }) ;
    }
    function _onMessage( event: any ) {
        const payload: any = jsonToObject( event.data ) ;
        const { type, data } = payload ;
        dispatcher.dispatchEvent( new DispatchableEvent( type, data ) ) ;
    }

    function doPingPong( interval: number /* sec */ ) {
        if( isReady && ! error ) {
            _PingPongID = setInterval( ()=> {
                /* debug */ Logger.saga( "socket::ping" ) ;
                send("#ping") ;
            }, (( interval * 60 ) * 1000 ) ) ;
        }
    }
    function clearPingPong() {
        if( _PingPongID ) {
            clearInterval( _PingPongID ) ;
            _PingPongID = null ;
        }
    }

    function setError( error: Error_I ) {
        clearPingPong() ;
        dispatch( update({ isReady: false, error }) ) ;
    }
    function init( host: string ) {
        const access_token: string = Session.getToken() || "";
        try {
            const url: string = `${ host }?token=${ md5( access_token ) }` ;
            const socket_: any = new W3C( url ) ;
            socket_.onopen = _onOpen ;
            socket_.onerror = _onError ;
            socket_.onclose = _onClose ;
            socket_.onmessage = _onMessage ;

        } catch ( error: any ) { setError({ message: error?.message }) }
    }
    function send( text: string, data: any = null ) {
        _isReady && ( text || data ) && $_singletonInstance.send( JSON.stringify({ text, data }) ) ;
    }
    function destroy() {
        if( $_singletonInstance ) {
            /* debug */ Logger.error( "Socket() destroy()" ) ;
            $_singletonInstance.onopen = null ;
            $_singletonInstance.onerror = null ;
            $_singletonInstance.onclose = null ;
            $_singletonInstance.onmessage = null ;
            $_singletonInstance = null ;
        }
        reset() ;
    }

    /* debug */ Logger.info( "useSocketHook()", { error, isReady } ) ;

    return { isReady: _isReady, doPingPong, dispatcher, error, socket: $_singletonInstance, send, init, destroy }
}

export default useSocketHook ;