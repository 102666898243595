import React, { useEffect, useState } from "react";
import Logger from "../../../helpers/logger";
import PropTypes from "prop-types";
import { clone, filter, find, get, isEmpty, isNil, map, uniqueId, valuesIn } from "lodash";
import Spinner from "../../ui-kit/spinner";
import Label from "../../ui-kit/label";
import StatusSelector from "../../ui-kit/status/selector";
import { useTranslation } from "react-i18next";
import useI18N from "../../../hooks/useI18N.hook";
import { getCheckSum, getCSSVariableValue, jsonToObject } from "../../../helpers/common";
import FromCompanySelector from "./components/Selectors/FromCompany";
import Input from "../../ui-kit/input";
import DepsSelector from "./components/Selectors/FromDeps";
import { Input as AntInput, Modal, Progress, Spin, Tooltip } from 'antd';
import Button from "../../ui-kit/button";
import { DeleteFilled, FileTextOutlined, NumberOutlined, UsergroupAddOutlined, WarningFilled, WechatOutlined } from '@ant-design/icons';
import Permission from "../../ui-kit/permission";
import { PERMISSIONS, READY_STATES, ROLES, TICKET_STATUSES } from "../../../dto";
import ClassNames from "classnames";
import { TicketPropertyViewModes } from "../../../data/constants";
import DepLabel from "../../ui-kit/dep_label";
import ReAssign from "./components/ReAssign";
import { useNavigate } from "react-router-dom";
import FileManager from "./components/FileManager";
import FilesSigner from "../../files-signer";
import Chat from "../../chat";
import CallChatButton from "./components/CallChat";
import { ReAssignersSelector } from "../../ui-kit/reassigners";
import ReviewStatusWrapper from "../../ui-kit/review_status/wrapper";
import { updateTask } from "../../../helpers/api.services";

const { TextArea } = AntInput ;
const FILE_MANAGER = "file_manager" ;
const CHAT = "chat" ;

function TicketPropertyComponent( props ) {
    /* debug */ Logger.info( "TicketPropertyComponent([ props ])", props ) ;

    const [ initialStatusId, setInitialStatusId ] = useState() ;

    const { id, view, profile, mode, permissions, store, actions } = props ;
    const { role_id: role } = profile ;
    const { value: i18n } = useI18N() ;
    const { t } = useTranslation() ;
    const navigate = useNavigate() ;
    const [ layout, setLayout ] = useState( view ) ;
    const [ selectedFiles, setSelectedFiles ] = useState([]) ;
    const [ selectedCompany, setSelectedCompany ] = useState( store?.$company ) ;
    const { initialize, update, reset, sync, remove, assign, refresh, setSigners, updateFile, sendToDep, sendToCompany, reassignTo } = actions ;
    const { data, fetched: isInitialized, fetching: isPending } = store ;
    const { dep_id, company_id, subject, description, status_id, is_owner, ready_state, review_status_id, review_status_description } = data ;

    const [ preFetchedCompanyId, setFetchedCompany ] = useState( null ) ;
    const [ uploadingProgressPercents, setUploadingProgressPercents ] = useState( null ) ;
    const dictionary = jsonToObject( data?.status_i18n ) ;
    const status = get( dictionary, i18n, null ) ;
    const isDataReady = ! isPending && isInitialized ;
    const [ checksum, setChecksum ] = useState() ;
    const [ filesManagerInstance, setFilesManagerInstance ] /* useFileManagerHook instance! */ = useState() ;

    function hasFilesToUploadExists() {
        if( ! filesManagerInstance ) return false ;
        const files = ( filesManagerInstance?.data || [] ).filter( ({ error }) => ! error );
        return Boolean( files.length ) ;
    }

    const isFilesToUploadExists = hasFilesToUploadExists() ;

    const _checkSumNow = getCheckSum( store?.data ) ;

    const isChecksumChanged = checksum !== _checkSumNow ;
    const hasDataChanged = isChecksumChanged || isFilesToUploadExists ;
    const isTicketOwner = Boolean( is_owner ) ;
    const isCompany = Boolean( company_id ) ;
    const isCompanyAlreadyChoosedPreviously = Boolean( preFetchedCompanyId ) ;
    const isReadOnly = mode === TicketPropertyViewModes.ReadOnly || [ TICKET_STATUSES.NEW, TICKET_STATUSES.SUCCESS, TICKET_STATUSES.REJECTED ].includes( initialStatusId ) ;
    const isCanUpdate = ! isReadOnly && mode === TicketPropertyViewModes.Update ;
    const _isOperator = role === ROLES.OPERATOR ;
    const _isSupervisor = role === ROLES.SUPERVISOR ;
    const _isKBClient = role === ROLES.CLIENT ;
    const _isIAmOperatorAndTaskAssignedToMe = _isOperator && profile.$id === data?.user_id ;

    const _isOperatorAndTicketOwner = _isOperator && isTicketOwner ;
    const _isOperatorCanUseDraftStatus = _isOperatorAndTicketOwner && initialStatusId === TICKET_STATUSES.DRAFT ;

    const isCanChangeStatus = _isOperator && isCanUpdate && ! [ TICKET_STATUSES.DRAFT ].includes( initialStatusId );

    function componentDidMount() {
        /* debug */ Logger.log( "TicketPropertyComponent() componentDidMount()" ) ;
        reset() ;
        initialize() ;
    }
    function init() {
        if( ! isDataReady ) return ;

        /* debug */ Logger.log( "TicketPropertyComponent() init([ isDataReady ])", isDataReady ) ;

        setInitialStatusId( status_id ) ;
        setFetchedCompany( company_id ) ;
        setChecksum( getCheckSum( data ) ) ;
    }
    function onSenderChangeHandler( company_id ) {
        const isCompany = profile?.id !== company_id ;
        ! isCompany
        ? update({ company_id: null })
        : update({ company_id })
    }
    function onReceiverChangeHandler( dep_id ) {
        update({ dep_id }) ;
    }
    function onSubjectHandler( _, subject ) {
        update({ subject }) ;
    }
    function onDescriptionHandler({ target:{ value: description } }) {
        update({ description }) ;
    }
    function onGoBackHandler() {
        function goToBack() {
            navigate("/")
        }

        if( _isSupervisor ) return goToBack() ;
        if( hasDataChanged ) {
            function onYes( handler )  {
                navigate("/") ;
                handler.destroy() ;
            }
            function onClose( handler ) {
                handler.destroy() ;
            }
            const handler = Modal.confirm({
                title: t("common.confirmation"),
                content: t( "prompts.unsavedDataWillBeLost" ),
                footer: (
                    <span className="ant-modal-footer">
                        <Button key="yes" ghost className="save" label={ t( "common.yes" ) } onClick={ () => onYes( handler ) } />
                        <Button key="cancel" className="cancel" label={ t( "common.cancel" ) } onClick={ () => onClose( handler ) } />
                    </span>
                )
            });
        } else return goToBack() ;
    }
    function onFilesListUpdate( instance ) {
        //  /* debug */ Logger.warn("TicketPropertyComponent() onFilesListUpdate([ instance ])", instance ) ;
        setFilesManagerInstance( instance ) ;
    }
    function onUploaderProgress( event ) {
        const percentage = Math.round((100 * event.loaded) / event.total);
        setUploadingProgressPercents( percentage ) ;

        if( event.total === event.loaded ) {
            // уничтожаем индикацию загрузки (спустя какое-то время) после того как она завершила свою работу
            setTimeout( () => { setUploadingProgressPercents( null ) }, 254 ) ;
        }
    }
    function onSingersUpdate( file, signers ) {
        /* debug */ Logger.warn("TicketPropertyComponent() onSignersUpdate([ file, signers ])", { file, signers }) ;
        setSigners && setSigners( file, signers ) ;
    }
    function onSelectFiles( ids, files ) {
        /* debug */ Logger.warn("TicketPropertyComponent() onSelectFiles([ ids, files ])", { ids, files }) ;
        setSelectedFiles( ids ) ;
    }
    function onShowChat() {
        setLayout( TicketPropertyComponent.VIEWS.CHAT ) ;
    }
    function onShowFileManager() {
        setLayout( TicketPropertyComponent.VIEWS.FILE_MANAGER ) ;
    }
    function onSigningFinished( info, results ) {
        /* debug */ Logger.success( "TicketPropertyComponent() onSigningFinished([ info, results ])", { info, results }, data ) ;
        refresh( data?.id ) ;
    }
    function onAssignTicketToDep() {
        const ticket_id = data?.id ;
        /* debug */ Logger.success( "TicketPropertyComponent() onAssignTicketToDep([ ticket_id ])", ticket_id ) ;
        sendToDep( ticket_id ) ;
    }
    function onAssignTicketToCompany() {
        const ticket_id = data?.company_id ;
        /* debug */ Logger.success( "TicketPropertyComponent() onAssignTicketToCompany([ company_id ])", company_id ) ;
        sendToCompany( ticket_id ) ;
    }
    function onChangeFileStatus( status ) {
        /* debug */ Logger.success( "TicketPropertyComponent() onChangeFileStatus([ status ])", status ) ;
        updateFile( status ) ;
    }
    function onChangeTicketStatus( status_id ) {
        update({ status_id }) ;
    }

    async function onReAssignHandler( user ) {

        /**
         * @description
         * Перед тем как перевести ticket на другого пользователя
         * - нужно сохранить все несохраненные изменения
         */

        const ticket_id = data?.id ;
        const user_id = user?.userId ;
        assign( ticket_id, user_id ) ;
    }
    async function onSaveHandler() {

        async function callback () {
            /* debug */ Logger.warn( "TicketPropertyComponent() callback([2])", { filesManagerInstance } ) ;

            if( isFilesToUploadExists ) {

                const payload = await filesManagerInstance.uploadAll() ;
                /* debug */ Logger.warn( "TicketPropertyComponent() callback([3])", { payload } ) ;

                const isError = Boolean( payload?.data?.error ) ;
                if( isError ) return Promise.reject( new Error( payload.data.error.message ) )

                const files = payload?.data?.files ;
                /* debug */ Logger.warn( "TicketPropertyComponent() callback([4])", { files } ) ;

                files && filesManagerInstance.reset() ;

                return (
                    ! files
                    ? Promise.reject( new Error( "Uploading failed, wrong response payload!" ) )
                    : Promise.resolve( payload?.data )
                )
            }

            return Promise.resolve({ files: [] }) ;
        }

        try { await sync( callback ) ; }
        catch( exception ) {
            /* debug */ Logger.error( "TicketPropertyComponent([ error ]) onSaveHandler([ message ])", exception?.message ) ;
        }
    }

    useEffect( () => { componentDidMount() }, [] ) ;
    useEffect( () => { init() }, [ isDataReady ] ) ;

    const isBusy = ! isInitialized || isPending ;
    const isCanSaveNow = isCanUpdate && hasDataChanged ;

    const spinnerIcon = isBusy && uploadingProgressPercents ? (
        <Progress
            type="circle"
            percent={ uploadingProgressPercents }
            strokeColor={ getCSSVariableValue("--cssDefaultPrimaryBackgroundColor") }
        />
    ) : null ;

    const defaultCompany = find( profile?.companies, { company_id } ) ;
    const allFiles = [
        ... ( isFilesToUploadExists ? [  ...filesManagerInstance?.data ] : [] ),
        ... data?.$files || []
    ]

    function hasFileCanBeRemoved( file ) {
        return file && file.isLocal ;
    }
    function hasCanBeSigned( file ) {
        const isIamSigner = file?.signers?.hasOwnProperty( profile.$id ) ;
        return file && isIamSigner && file?.signers[ profile.$id ] === 0 ;
    }

    const filesToRemove = filter( allFiles, hasFileCanBeRemoved ) ;
    const filesSelectedForRemove = filter( selectedFiles, ( hash ) => Boolean( find( filesToRemove, { hash } ) ) ) ;
    const filesToSign = filter( allFiles, hasCanBeSigned ) ;
    const filesSelectedForSign = filter( selectedFiles, ( hash ) => Boolean( find( filesToSign, { hash } ) ) ) ;
    const hasMultipleSelectionEnabled = Boolean( filesToRemove?.length || filesToSign?.length ) ;

    function onRemoveManyFiles() {
        filesManagerInstance && filesSelectedForRemove.length && filesSelectedForRemove.forEach( filesManagerInstance.remove ) ;
    }
    function onSignManyFiles() {
        if( filesSelectedForSign.length ) {
            const forSign = map( filesSelectedForSign, ( hash ) => find( filesToSign, { hash } )) ;
            Modal.confirm({
                closable: true,
                className: "clean",
                content: <FilesSigner
                    files={ forSign }
                    onRemoteFileUpdate={ updateFile }
                    onSuccess={ onSigningFinished }
                />,
                footer: null,
            });
        }
    }
    function onSelectCompanyUser( company ) {
        /* debug */ Logger.warn( "TicketPropertyComponent() onSelectCompanyUser([ company ])", company ) ;
        update({ company_id: +company.id, $company: company })
        setSelectedCompany( company ) ;
    }
    function onRemoveCompanyUser() {
        /* debug */ Logger.warn( "TicketPropertyComponent() onRemoveCompanyUser()" ) ;
        setSelectedCompany( null ) ;
        update({ company_id: null })
    }

    const getLayout = () => {
        /* debug */ Logger.warn( "TicketPropertyComponent() getLayout([ view, layout ])", { view, layout } ) ;
        /* debug */ Logger.warn( "TicketPropertyComponent() getLayout([ isReadOnly ])", isReadOnly ) ;

        switch ( layout ) {
            case TicketPropertyComponent.VIEWS.FILE_MANAGER: return (
                <FileManager
                    id={ data?.id }
                    isMultipleSelectionEnabled={ hasMultipleSelectionEnabled }
                    isReadOnly={ isReadOnly }
                    isOwner={ isTicketOwner || ( _isKBClient && [ TICKET_STATUSES.DEP_REQUEST, TICKET_STATUSES.WAIT_FOR_UPDATE ].includes( status_id ) ) }
                    company_id={ company_id }
                    remoteFiles={ data?.$files }
                    disableSignersSelection={ [ ROLES.OPERATOR, ROLES.SUPERVISOR ].includes( role ) }
                    disableFileStatusSelection={ isReadOnly }
                    onSelectFiles={ onSelectFiles }
                    onSingersUpdate={ onSingersUpdate }
                    onFilesListUpdate={ onFilesListUpdate }
                    onUploaderProgress={ onUploaderProgress }
                    onChangeFileStatus={ onChangeFileStatus }
                />
            )
            case TicketPropertyComponent.VIEWS.CHAT: return (
                data?.id && <Chat id={ data?.id } profile={ profile } files={ store?.data?.$files } />
            )
        }
    }
    const $layout = getLayout() ;

    /* debug */ Logger.warn( "TicketPropertyComponent([1])", checksum, _checkSumNow, { isBusy, isCanSaveNow, isInitialized, isChecksumChanged, isPending, isCanUpdate, hasDataChanged } ) ;

    async function onChangeReviewStatusHandler( status ) {
        /* debug */ Logger.warn( "TicketPropertyComponent() onChangeReviewStatusHandler([ status ])", status ) ;
        const ticket_id = data?.id ;
        const payload = await updateTask( ticket_id, status ) ;
        /* debug */ Logger.success( "TicketPropertyComponent() onChangeReviewStatusHandler([ payload ])", payload ) ;
        update( status );
    }

    function TPSendButton() {
        const isVisible = ! isReadOnly && ! hasDataChanged && data?.$isReady && [
            TICKET_STATUSES.DRAFT,
            TICKET_STATUSES.SIGNATURE_PENDING,
            TICKET_STATUSES.WAIT_FOR_UPDATE,
            TICKET_STATUSES.DEP_REQUEST
        ].includes( status_id ) ;

        /* debug */ Logger.info( "TicketPropertyComponent([ data ])", isVisible ) ;

        if( ! isVisible ) return null ;

        switch ( role ) {
            case ROLES.OPERATOR: return ready_state !== READY_STATES.SENT_TO_COMPANY ? (
                 <Button
                    key="send"
                    className={ ClassNames( "send" ) }
                    label={ t("TicketProperty.send.to.company") }
                    onClick={ onAssignTicketToCompany }
                />
            ) : null ;

            default: return (
                <Button
                    key="send"
                    className={ ClassNames( "send" ) }
                    label={ t("TicketProperty.send.to.dep") }
                    onClick={ onAssignTicketToDep }
                />
            )
        }
    }
    function TRequiredFields() {
        return <span key={ uniqueId() } className="required-field-label">{ t("prompts.required") }</span> ;
    }

    function getExcludedStatuses() {

        // если оператор не выбрал компанию: запрещаем все статусы - кроме DRAFT
        if( _isOperator && ! isCompany ) {
            let excluded = valuesIn( TICKET_STATUSES ) ;
            excluded.splice( excluded.indexOf( TICKET_STATUSES.DRAFT ), 1 ) ;
            return excluded ;
        }

        const defaults = clone( StatusSelector.defaultProps.excluded ) ;

        // если оператор не может использовать статус DRAFT ;
        if( ! _isOperatorCanUseDraftStatus ) defaults.push( TICKET_STATUSES.DRAFT ) ;

        return defaults ;
    }

    const excludedStatuses = getExcludedStatuses() ;
    const isControlEnabled = ( _isKBClient && [ TICKET_STATUSES.DRAFT, TICKET_STATUSES.WAIT_FOR_UPDATE ].includes( initialStatusId ) )
                          || ( _isOperator && [ TICKET_STATUSES.DRAFT ].includes( initialStatusId ) ) ;

    return (
        <Spinner spinVisible={ isBusy } label={ t("common.loading") } icon={ spinnerIcon }>
            <div className="toolbar">
                <span className="left">

                    <Label className="id" value={
                        ! data?.id
                        ? <Spin size="small" />
                        : <p className="request_id"><NumberOutlined /> <b>{ data.id }</b></p>
                    }/>

                    <Button
                        className={ ClassNames( "back", hasDataChanged ? "changed" : "" ) }
                        ghost={ true }
                        icon={ ! hasDataChanged ? "⟵" :
                            (
                                ! _isSupervisor
                                ? (<Tooltip placement="top" title={ t( "prompts.unsavedData" ) }>
                                        <WarningFilled/>
                                    </Tooltip>)
                                : "⟵"
                            )
                        }
                        arrow=""
                        showArrow={ true }
                        arrowPosition={ Button.arrowPositions.Left }
                        label={ t("TicketProperty.goBack") }
                        onClick={ onGoBackHandler }
                    />

                    <StatusSelector
                        id={ status_id }
                        showExcluded={ false }
                        excluded={ excludedStatuses }
                        disabled={ ! isCanChangeStatus }
                        onChange={ onChangeTicketStatus }
                    />

                    { _isSupervisor &&
                        <ReviewStatusWrapper id={ review_status_id } description={ review_status_description } onSave={ onChangeReviewStatusHandler }>
                            { t(`StatusSelector.review_statuses.${ review_status_id }`) }
                        </ReviewStatusWrapper>
                    }
                </span>
                <span className="right">
                    { isCanUpdate && filesSelectedForRemove?.length ? (
                        <Button
                            ghost
                            className="remove"
                            label={ `${ t( "common.remove" ) } (${ filesSelectedForRemove.length })` }
                            onClick={ onRemoveManyFiles }
                        />
                    ) : null }
                    { filesSelectedForSign?.length ? (
                        <Button
                            ghost
                            className="sign"
                            label={ `${ t( "common.sign" ) } (${ filesSelectedForSign.length })` }
                            disabled={ hasDataChanged }
                            onClick={ onSignManyFiles }
                        />
                    ) : null }
                    {
                        _isIAmOperatorAndTaskAssignedToMe && ! [ TICKET_STATUSES.REJECTED, TICKET_STATUSES.SUCCESS ].includes( status_id ) && (
                            <ReAssignersSelector
                                t={ t }
                                placement="bottomRight"
                                onSelect={ reassignTo }
                                children={(
                                    <span className="reassign-to">
                                        <UsergroupAddOutlined />
                                        <Label value={ t("TicketProperty.reassignTo") }/>
                                    </span>
                                )}
                            />
                        )
                    }

                    { ! _isOperator && ! isReadOnly && isCompanyAlreadyChoosedPreviously && (
                        <ReAssign
                            disabled={ hasDataChanged }
                            selected_id={ profile?.id }
                            company_id={ company_id }
                            onSelect={ onReAssignHandler }
                        />
                    ) }

                    { layout !== TicketPropertyComponent.VIEWS.FILE_MANAGER && (
                            <Button
                                ghost
                                key="documents"
                                icon={ <FileTextOutlined /> }
                                className="file-manager"
                                disabled={ layout === TicketPropertyComponent.VIEWS.FILE_MANAGER }
                                label={ t( "TicketProperty.documents" ) }
                                onClick={ onShowFileManager }
                            />)
                    }

                    { layout !== TicketPropertyComponent.VIEWS.CHAT && (
                        <CallChatButton>
                            <Button
                                ghost
                                icon={ <WechatOutlined /> }
                                className="chat"
                                disabled={ layout === TicketPropertyComponent.VIEWS.CHAT }
                                label={ t( "Chat.name" ) }
                                onClick={ onShowChat }
                            />
                        </CallChatButton>
                    ) }

                </span>
            </div>
            <div className="form">
                <div className="wrapper">
                    <div className="left">
                        {
                            isReadOnly ? (
                                <div>
                                    <p className="sub-label">{ t("MyTickets.columns.company_id") }</p>
                                    <Label.WithHeader
                                        key="company"
                                        placeholder={ data?.$owner?.fullname }
                                        value={ data?.$company?.name }
                                    />
                                </div>
                            ) : (
                                <>
                                    {
                                        role === ROLES.OPERATOR
                                        ? (
                                            <>
                                                <FromCompanySelector disabled={ ! isControlEnabled } selected={ data?.$company || selectedCompany } onChange={ onSelectCompanyUser } />
                                                { ! isCompany && <TRequiredFields/> }
                                            </>
                                        )
                                        : <FromCompanySelector.View position={ defaultCompany?.position } name={ defaultCompany?.name } />
                                    }
                                </>
                            )
                        }

                        {
                            isReadOnly ? (
                                <div>
                                    <p className="sub-label">{ t("MyTickets.columns.dep_id") }</p>
                                    <DepLabel id={ data?.dep_id } />
                                </div>
                            ) : (
                                role !== ROLES.OPERATOR
                                ? <>
                                    <DepsSelector disabled={ ! isControlEnabled } current={ dep_id } onChange={ onReceiverChangeHandler } />
                                    { ! dep_id && <TRequiredFields/> }
                                </> : null // <DepsSelector.View />
                            )
                        }

                        {
                            isReadOnly ? (
                                <div>
                                    <p className="sub-label">{ t("MyTickets.columns.description") }</p>
                                    <Label.WithHeader key="subject_description" placeholder={ subject } value={ description } />
                                </div>
                            ) : (
                                <>
                                    <Input
                                        key="subject"
                                        disabled={ ! isControlEnabled }
                                        value={ subject }
                                        placeholder={ t("TicketProperty.subject.placeholder") }
                                        onChange={ onSubjectHandler }
                                    />
                                    { ! subject && <TRequiredFields/> }
                                    <TextArea
                                        key="description"
                                        showCount={ false }
                                        disabled={ ! isControlEnabled }
                                        value={ description }
                                        maxLength={ 512 }
                                        className={ ClassNames( "description", ! isControlEnabled ? "disabled" : null  ) }
                                        placeholder={ t("TicketProperty.description.placeholder") }
                                        onChange={ onDescriptionHandler }
                                    />
                                    { ! description && <TRequiredFields/> }
                                </>
                            )
                        }
                    </div>
                    <div className="center">
                        { $layout }
                    </div>
                </div>
                <div className="wrapper">
                    <div className="footer">
                        <TPSendButton />
                        {
                            ! isReadOnly && (
                                <Permission.Verifycator
                                    id={ id }
                                    name={ PERMISSIONS.UPDATE }
                                    value="1"
                                    whileFailed={
                                        <Button
                                            key="save"
                                            disabled={ true }
                                            className={ ClassNames( "save" ) }
                                            label={ t("common.saveChanges") }
                                        />
                                    }
                                >
                                    <Button
                                        key="save"
                                        disabled={ ! isCanSaveNow }
                                        className={ ClassNames( "save" ) }
                                        label={ t("common.saveChanges") }
                                        onClick={ onSaveHandler }
                                    />
                                </Permission.Verifycator>
                            )
                        }

                        { ! isReadOnly && (
                            <Permission.Verifycator
                                id={ id }
                                name={ PERMISSIONS.DELETE }
                                value="1"
                                whileFailed={
                                    <Button
                                        key="remove"
                                        className={ ClassNames("button-remove-ticket", "disabled") }
                                        ghost={ true }
                                        label={ t("common.remove") }
                                        icon={ <DeleteFilled /> }
                                    />
                                }
                            >
                                <Button
                                    key="remove"
                                    className={ ClassNames("button-remove-ticket", ready_state > 0 ? "disabled" : null ) }
                                    ghost={ true }
                                    label={ t("common.remove") }
                                    icon={ <DeleteFilled /> }
                                    onClick={ remove }
                                />
                            </Permission.Verifycator>
                        ) }
                    </div>
                </div>
            </div>
        </Spinner>
    )
}
TicketPropertyComponent.VIEWS = { FILE_MANAGER, CHAT }
TicketPropertyComponent.defaultProps = {
    mode: TicketPropertyViewModes.ReadOnly,
}
TicketPropertyComponent.propTypes = {
    id: PropTypes.string,
    view: PropTypes.oneOf([ FILE_MANAGER, CHAT ]),
    mode: PropTypes.string,
    profile: PropTypes.object,
    permissions: PropTypes.any,
    store: PropTypes.object,
    actions: PropTypes.shape({
        initialize: PropTypes.func,
        update: PropTypes.func,
        reset: PropTypes.func,
        sync: PropTypes.func,
        remove: PropTypes.func,
        assign: PropTypes.func,
        setSigners: PropTypes.func,
        updateFile: PropTypes.func,
        refresh: PropTypes.func,
        sendToDep: PropTypes.func,
        sendToCompany: PropTypes.func,
        reassignTo: PropTypes.func
    })
}

export default TicketPropertyComponent ;