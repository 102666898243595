import React from "react";
import Logger from "../../../../../helpers/logger";
import PropTypes from "prop-types";
import { Tabs } from 'antd';
import FileProperties from './FileProperties';
import SignInfo from './SignInfo';
import { CopyOutlined } from '@ant-design/icons';
import { useTranslation } from "react-i18next";
import "../../../../../styles/components/file_info.scss";

function FileInfo({ file }) {
    /* debug */ Logger.info( "FileInfo([ file ])", file ) ;

    const { t } = useTranslation() ;
    const onChange = (key) => {
        console.log(key);
    };
    const items = [
        {
            key: 0,
            label: <>{ t( "FileInfo.FileProperties.tab" ) } <CopyOutlined /></>,
            children: <FileProperties file={ file }/>
        },
        {
            key: 1,
            label: <>{ t( "FileInfo.SignInfo.tab" ) } <CopyOutlined /></>,
            children: <SignInfo file={ file } />
        }
    ]

    return (
        <Tabs
            className="FileInfo"
            defaultActiveKey={ 0 }
            items={ items }
            onChange={ onChange }
        />
    )
}
FileInfo.propTypes = {
    file: PropTypes.shape()
}

export default FileInfo ;