import {TDataEditorProviderProps} from "./components/DataEditorProvider";

export enum UsersFields {
    id = "id",
    uid = "uid",
    hosts_id = "hosts_id",
    host = "host",
    name = "name",
    rule_id = "rule_id",
    role_id = "role_id",
    position = "position",
    lastname = "lastname",
    surname = "surname",
    middlename = "middlename",
    signer = "signer",
    dep_id = "dep_id",
    dep_name = "dep_name",
    dep_title = "dep_title",
    dep_description = "dep_description",
    companies = "companies",
    fullname = "fullname",
    password = "password",
    access_token = "access_token",
    access_token_hash = "access_token_hash",
    refresh_token = "refresh_token",
    createdAt = "createdAt",

    $toolbar = "$toolbar",
    $online = "$online",
}
export type TUsersManagerComponentOptions = {
    t: Function,
    fromI18N: Function,
    lang: string,

    data?: any,
    meta?: any,

    onOkay?: Function,
    onCancel?: Function
}

export enum ToolbarActions {
    READ = "read",
    CREATE = "create",
    WRITE = "write",
    SEND_MESSAGE = "send_message",
    CHANGE_PASSWORD = "change_password",
    DESTROY_SESSION = "destroy_session"
}
export type TToolbarActions = ToolbarActions.READ | ToolbarActions.WRITE | ToolbarActions.SEND_MESSAGE | ToolbarActions.CHANGE_PASSWORD | ToolbarActions.DESTROY_SESSION ;
export type TLayoutRenderer = {
    options: TUsersManagerComponentOptions,
    context: TDataEditorProviderProps
}