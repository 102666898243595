import React from "react";
import ClassNames from "classnames";
import PropTypes from "prop-types";
import "../../../styles/components/ui-kit/section.scss";

function Section({ children, className, aside, classAsideName }) {
    return (
        <div className={ ClassNames( "Section", className ) } >
            <div className={ ClassNames( classAsideName ) } data-aside={ aside }>
                { children }
            </div>
        </div>
    )
}
Section.defaultProps = {
    aside: true,
    classAsideName: ""
}
Section.propTypes = {
    aside: PropTypes.bool,
    classAsideName: PropTypes.string
}

export default Section ;
