import React from "react";
import PropTypes from "prop-types";
import Logger from "../../helpers/logger";
import '../../styles/components/iit.scss';

function IIT( props ) {
    /* debug */ Logger.info( "IIT([ props ])", props ) ;

    const { id, visible } = props ;
    return (
        <div className="IIT" data-visible={ Boolean( visible ) }>
            <div id={ id } className="widget-parent">
                {/**
                    @description
                    Батківський елемент для відображення iframe,
                    який завантажує сторінку SignWidget
                */}
            </div>
            <div id="qr-code-block" className="widget-qr-code">
                {/**
                    @description
                    Батківський елемент для відображення QR-коду
                 */}
            </div>
        </div>
    )
}
IIT.defaultProps = {}
IIT.propTypes = {
    id: PropTypes.string,
    visible: PropTypes.bool
}

export default IIT ;