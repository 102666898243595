import md5 from "md5";
import { remove, uniqueId } from "lodash";

export enum CLIENT_TYPES {
    CHILD = "child",
    PARENT = "parent"
}
export type TCrossMessageClientTypes = CLIENT_TYPES.CHILD | CLIENT_TYPES.PARENT ;
export type TCrossMessagesData = {
    id?: string | number,
    _id?: string | number,
    namespace?: string,
    type: string,
    data: any,
    error?: any
}
export type TCrossMessagesEvent = {
    data: TCrossMessagesData
    currentTarget?: any
}
export type TCrossMessagesOptions = {
    namespace: string,
    client: TCrossMessageClientTypes,
    onEvent?: Function,
    debugEnabled: boolean
}

function logger( enabled: boolean ): Function {
    const color = "#00ffa6" ;
    const icon = "👽" ;
    return function( text: string, ...variables: any[] ): void {
        enabled && console.log( `%c${ icon } ${ text }`, `color: ${ color };`, ...variables );
    }
}

export default class CrossMessages {

    static CLIENT_TYPES = CLIENT_TYPES ;

    private _namespace: string | null = null ;
    private _client: TCrossMessageClientTypes | null = null ;
    private _callback: Function | null = null ;
    private _debugEnabled: boolean = true ;
    private _log: Function = logger( this._debugEnabled ) ;
    private _sent: string[] = [] ;

    constructor( options: TCrossMessagesOptions ) {
        this._namespace = options.namespace ;
        this._client = options.client ;
        this._callback= options?.onEvent || this._onEvent ;
        this._debugEnabled = options.debugEnabled ;
    }

    public send( data: TCrossMessagesData ) {

        let path: string = "" ;
        switch ( this._client ) {
            case CLIENT_TYPES.CHILD: {
                path = "parent.postMessage" ;
                break ;
            }
            case CLIENT_TYPES.PARENT: {
                path = `frames.${ this._namespace }.postMessage` ;
                break;
            }
        }

        const id: string = <string> data?.id || this.createID( data?.data ) ;
        const payload = { ... data, namespace: this._namespace, id } ;

        /* debug */ this._log( `[ ${ this._client } ] send([ ${ data.type } ])`, id, data.data ) ;

        this._registerSession( id ) ;
        this._addEventListeners() ;

        if( this._client === CLIENT_TYPES.CHILD ) {
            window.parent.postMessage( payload, "*" ) ;
        } else if( this._client === CLIENT_TYPES.PARENT ) {
            for( let c = 0 ; c < window.frames.length ; c++ ) {
                const frame = window.frames[c] ;
                frame.postMessage( payload, "*" ) ;
            }
        } else {
            /* debug */ this._log( `[ WARNING ]`, `"${ this._client }" has unknown client type` ) ;
        }
    }

    private _addEventListeners(): void {
        this._removeEventListeners() ;
        window.addEventListener( 'message', this.onMessage );
    }
    private _removeEventListeners(): void {
        window.removeEventListener( 'message', this.onMessage );
    }
    private _registerSession( id: string ): void {
        this._sent.push( id ) ;
    }
    private _isSessionExists( id: string ): boolean {
        return this._sent.includes( id ) ;
    }
    private _destroySession( id: string ): void {
        remove( this._sent, id_ => id_ === id ) ;
    }
    private _onEvent = ( event: TCrossMessagesEvent ) => {
        /* debug */ this._log( `[ ${ this._client } ][?] _onEvent([ event ])`, event ) ;
    }

    private createID( data: any ): string {
        return md5( `${ uniqueId( Math.random().toString() ) }${ JSON.stringify( data ) }` ).toString() ;
    }
    private onMessage = ( event: TCrossMessagesEvent ) => {

        const data:TCrossMessagesData = event.data ;

        if( data.namespace !== this._namespace ) {
            return this._log( `[ ${ this._client } ][ WARNING ] onMessage()`, "Unknown namespace", event ) ;
        }

        /* debug */ this._log( `[ ${ this._client } ] onMessage([ data ])`, data ) ;

        const id: string = <string> data.id ;
        const type: string = <string> data.type ;
        const isSessionExists = this._isSessionExists( id ) ;
        if( isSessionExists ) return ; // this._log( `[ ${ this._client } ][ WARNING ] onMessage()`, "here-self message", event ) ;

        const [ _id, _type ] = type.split("|") ;
        /* debug */ this._log( `[ ${ this._client } ] onMessage([ _id, _type ])`, _id, _type ) ;

        const isAwaitedResponseId = _id && this._isSessionExists( _id ) ;
        if( ! isAwaitedResponseId ) return ; // this._log( `[ ${ this._client } ][ WARNING ] onMessage()`, "skip the third-party data", event ) ;

        data.type = _type ;
        data.id = _id ;

        this._destroySession( id ) ;
        this._removeEventListeners() ;

        const handler: Function = this._callback || this._onEvent;
        switch ( this._client ) {
            /**
             * @todo
             * Тут что-то должно присходить - если родитель первым прислал сообщение (нужно проверить)
             */
            case CrossMessages.CLIENT_TYPES.CHILD: {
                return handler( data ) ;
            }
            case CrossMessages.CLIENT_TYPES.PARENT: {
                return handler( data ) ;
            }
        }
    }
}