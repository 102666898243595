import { IFRAME_EVENTS } from "../dto";
import { getSettings } from "../data/settings";
import CrossMessages from "./cross-messages";

const namespace: string = getSettings().iFrameNamespace ;
const getController = ( onEvent: Function ) => {
    return new CrossMessages({
        namespace,
        client: CrossMessages.CLIENT_TYPES.CHILD,
        debugEnabled: true,
        onEvent
    });
}

export type TCompaniesSignersProps = {
    company_id: number
}
export function getCompaniesSigners( data: TCompaniesSignersProps ) {
    return new Promise( ( resolve, reject ) => {
        const type: string = IFRAME_EVENTS.GET_COMPANY_SIGNERS ;
        const ctrl = getController( resolve );
        ctrl.send({ type, data });
    } )
}

export type TCompaniesEmployeesProps = {
    company_id: number
}
export function getCompaniesEmployees( data: TCompaniesEmployeesProps ) {
    return new Promise( ( resolve, reject ) => {
        const type: string = IFRAME_EVENTS.GET_COMPANY_EMPLOYERS ;
        const ctrl = getController( resolve );
        ctrl.send({ type, data });
    } )
}

export function getMyActiveCompany() {
    return new Promise( ( resolve, reject ) => {
        const type: string = IFRAME_EVENTS.GET_MY_ACTIVE_COMPANY ;
        const ctrl = getController( resolve );
        ctrl.send({ type, data: null });
    } )
}

export function signVerification( sign: string ) {
    return new Promise( ( resolve, reject ) => {
        const type: string = IFRAME_EVENTS.SIGN_VERIFICATION ;
        const ctrl = getController( resolve );
        ctrl.send({ type, data: { sign } });
    } )
}