import React from "react";
import Permission from "../../../../ui-kit/permission";
import {PERMISSIONS, UI} from "../../../../../dto";
import {useTranslation} from "react-i18next";
import {RedoOutlined, UsergroupAddOutlined} from '@ant-design/icons';
import Header from "../../../../ui-kit/header";
import {Tooltip} from 'antd';
import classNames from "classnames";
import PropTypes from "prop-types";

function Toolbar( props ) {
    const { t } = useTranslation() ;
    const { onCreate, onRefresh } = props ;
    return (
        <div className="Toolbar">
            <div className="left">
                <Header text={ t( "Users.header" ) } size={ Header.sizes.middle } />
            </div>
            <div className="right">
                <Permission.Verifycator id={ UI.USERS_ACTIONS_TOOLBAR } name={ PERMISSIONS.CREATE } value="1" whileFailed={ null }>
                    <Tooltip placement="top" title={ t( "Users.create" ) }>
                        <div className={ classNames( "Button", "CreateUserButton" )} onClick={ onCreate }>
                            <UsergroupAddOutlined/>
                        </div>
                    </Tooltip>
                </Permission.Verifycator>
                <Tooltip placement="top" title={ t( "common.refresh" ) }>
                    <div className={ classNames( "Button", "RefreshButton" )} onClick={ onRefresh }>
                        <RedoOutlined />
                    </div>
                </Tooltip>
            </div>
        </div>
    )
}

Toolbar.propTypes = {
    onCreate: PropTypes.func,
    onRefresh: PropTypes.func,
}

export default Toolbar ;