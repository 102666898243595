import PropTypes from "prop-types";
import { SIGN_RIGHTS } from "../../../dto";
import "../../../styles/components/ui-kit/key_icon.scss";

function KeyIcon( props ) {
    const { signRights } = props ;
    return (
        <p className="KeyIcon" data-value={ signRights }>C</p>
    )
}
KeyIcon.defaultProps = {
    signRights: SIGN_RIGHTS.DISABLED
}
KeyIcon.propTypes = {
    signRights: PropTypes.oneOf([
        SIGN_RIGHTS.ALL,
        SIGN_RIGHTS.DISABLED,
        SIGN_RIGHTS.PRIMARY,
        SIGN_RIGHTS.SECONDARY
    ])
}

export default KeyIcon ;