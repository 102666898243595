import React from "react";
import ClassNames from "classnames";
import PropTypes from "prop-types";
import "../../../styles/components/ui-kit/label.scss";

function LabelWithHeader( props ) {
    return (
        <div className="LabelWithHeader">
            <b className="label">{ props.placeholder }</b>
            <p className="value">{ props.value }</p>
        </div>
    )
}
LabelWithHeader.defaultProps = {
    ... Label.defaultProps,
    placeholder: null
}
LabelWithHeader.propTypes = {
    ... Label.propTypes,
    placeholder: PropTypes.string
}

function Label({ className, value, url, onClick }) {
    function onClickHandler() {
        onClick && onClick() ;
    }
    return (
        ! url
        ? <p className={ ClassNames( "Label", className ) } onClick={ onClickHandler }>{ value }</p>
        : <a className={ ClassNames( "Label", className ) } onClick={ onClickHandler } href={ url }>{ value }</a>
    )
}
Label.defaultProps = {
    value: "",
}
Label.propTypes = {
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.element
    ]),
    url: PropTypes.string,
    onClick: PropTypes.func
}
Label.WithHeader = LabelWithHeader ;

export default Label ;
