import { getDeps } from "../helpers/api.services";
import Logger from "../helpers/logger";
import { useReduxDispatch, useReduxSelector } from "./redux.hook";
import { NotificationManager } from "react-notifications";
import { depsActions } from "../store/slices/deps.slice";

export type TDepsHook = {
    initialize: Function,
    isReady: boolean,
    data: any,
    error: any
}
export default function useDepsHook(): TDepsHook {
    const dispatch = useReduxDispatch();
    const { data, error, fetched, fetching } = useReduxSelector( ({ deps }) => deps ) ;
    const isReady = fetched && ! fetching ;

    async function initialize( forced: boolean = true ) {
        /* debug */ Logger.reducer( "useDepsHook.initialize([ forced ])", forced ) ;

        if( ! forced && fetched && ! fetching ) return ;

        dispatch( depsActions.setFetch({ fetching: true, fetched: false }) ) ;

        let payload: any,
            error: any ;

        try { payload = await getDeps() }
        catch ( exception: any ) {
            const message = exception?.message || "[ useDepsHook ] unhandled error" ;
            error = { message }
            /* error */ Logger.error( "useDepsHook.load([ error ])", { error } ) ;
        }

        if( error ) {
            NotificationManager.error( error.message )
            dispatch( depsActions.setData({ error, data: [] }) ) ;
        } else {
            const data = payload?.data ;
            /* debug */ Logger.success( "useDepsHook.load([ data ])", data ) ;

            dispatch( depsActions.setData({ data, error: null }) ) ;
        }

        dispatch( depsActions.setFetch({ fetching: false, fetched: true }) ) ;
    }

    return { initialize, isReady, data, error }
}
