import { TProfileResponseData, TString } from "../types";
import { getProfile } from "../helpers/api.services";
import Logger from "../helpers/logger";
import SessionHelper from "../helpers/session";
import { useEffect, useState } from "react";
import { useReduxDispatch, useReduxSelector } from "./redux.hook";
import { authenticationActions } from "../store/slices/authentication.slice";
import { profileActions } from "../store/slices/profile.slice";
import { permissionsActions } from "../store/slices/permissions.slice";
import { NotificationManager } from "react-notifications";

const DEFAULT_PROFILE_DATA: TProfileResponseData = {
    name: null,
    role_id: null
}

export type TSessionHook = {
    fetching: boolean, profile: TProfileResponseData | null, authorized: boolean, logout: Function
}
export default function useSession( prepare: boolean = true ): TSessionHook {

    const auth = useReduxSelector( ( { authentication }) => authentication ) ;
    let access_token: TString = SessionHelper.getToken() || auth?.access_token || null ;

    const dispatch = useReduxDispatch();
    const [ profile, setProfile ] = useState<TProfileResponseData>( DEFAULT_PROFILE_DATA ) ;
    const [ fetching, setFetch ] = useState<boolean>( false ) ;
    const [ authorized, setAuthorized ] = useState<boolean>( false ) ;

    function logout( redirectTo: TString = null ): void {

        setProfile( DEFAULT_PROFILE_DATA ) ;
        dispatch( authenticationActions.destroy() ) ;
        dispatch( profileActions.destroy() ) ;
        dispatch( permissionsActions.destroy() ) ;

        redirectTo && window.location.replace( redirectTo );
    }
    async function initialize(): Promise<void> {
        setFetch( true ) ;

        if( access_token ) {
            let payload: any = null ;
            try { payload = await getProfile() }
            catch ( exception: any ) {
                /* debug */ Logger.error( "useSession.initialize([3])", { exception } ) ;
                setProfile( DEFAULT_PROFILE_DATA ) ;
                setAuthorized( false ) ;
                SessionHelper.destructor() ;
                exception?.message && NotificationManager.error( exception.message )
            }

            const _profile = payload?.data?.profile ;
            const _permissions = payload?.data?.permissions ;
            if( _profile?.name ) {
                /* debug */ Logger.success( "useSession.initialize([6])", _profile ) ;
                setProfile( _profile ) ;
                setAuthorized( true ) ;
                dispatch( profileActions.set( _profile ) ) ;
                dispatch( permissionsActions.set( _permissions ) ) ;
            }

        } else {
            setProfile( DEFAULT_PROFILE_DATA ) ;
            setAuthorized( false ) ;
        }

        setFetch( false ) ;
    }

    useEffect( () => {
        if( prepare ) { ( async () => ( await initialize() ) )() }
    }, [ access_token ] ) ;

    return { authorized, logout, fetching, profile }
}
