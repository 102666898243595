import PropTypes from "prop-types";
import Permission from "../../../../ui-kit/permission";
import { PERMISSIONS, UI } from "../../../../../dto";
import React from "react";
import usePermissionsHook from "../../../../../hooks/usePermissionsHook";
import Logger from "../../../../../helpers/logger";
import FileStatus from "./FileStatus";

function StatusCellView( props ) {
    /* debug */ Logger.info("StatusCellView([ props ])", props )

    const { disabled, data, onChange } = props ;
    const { getPermissionsById } = usePermissionsHook() ;
    const permissions = getPermissionsById( StatusCellView.ID ) ;
    const isCanUpdate = Boolean( +permissions?.update ) ;

    /* debug */ Logger.log("StatusCellView([ isCanUpdate ])", isCanUpdate ) ;

    return (
        <div className="StatusCellView">
            <Permission.Verifycator
                id={ StatusCellView.ID }
                name={ PERMISSIONS.READ }
                value="1"
                whileFailed={ null }
            >
                <FileStatus editable={ isCanUpdate && ! disabled } data={ data } onChange={ onChange }/>
            </Permission.Verifycator>
        </div>
    )
}
StatusCellView.ID = UI.FILE_STATUS ;
StatusCellView.defaultProps = {
    disabled: false
}
StatusCellView.propTypes = {
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
    data: PropTypes.shape({
        status_id: PropTypes.string,
        status_description: PropTypes.string
    })
}

export default StatusCellView ;