import { TPaginatorOptions, TSortDirections } from "../types";
import { assignTicketTo, getMyTickets, removeTicketByID as apiRemoveTicketByID } from "../helpers/api.services";
import Logger from "../helpers/logger";
import { useReduxDispatch } from "./redux.hook";
import { ticketsActions } from "../store/slices/tickets.slice";
import { NotificationManager } from "react-notifications";

export type TMyTicketsHook = {
    load: Function,
    update: Function,
    assignTo /* to user */ : Function,
    removeTicketByID: Function,
    setPageNo: Function,
    setSearch: Function,
    setPerPageLimit: Function,
    setFilter: Function,
    setSort: Function
}
export default function useMyTickets(): TMyTicketsHook {
    const dispatch = useReduxDispatch();

    async function load( paginator: TPaginatorOptions ) {
        /* debug */ Logger.saga( "useMyTickets.load([ paginator ])", paginator ) ;

        dispatch( ticketsActions.setFetch({ fetching: true, fetched: false }) ) ;

        let payload: any,
            error: any ;
        try { payload = await getMyTickets( paginator ) }
        catch ( exception: any ) {
            const message = exception?.message || "[ useMyTickets ] unhandled error" ;
            error = { message }
            /* error */ Logger.error( "useMyTickets.load([ error ])", { error } ) ;
        }

        if( error ) {
            NotificationManager.error( error.message )
            dispatch( ticketsActions.setData({ error, data: [] }) ) ;
        } else {
            const data = payload?.data ;
            /* debug */ Logger.success( "useMyTickets.load([ data ])", data ) ;
            dispatch( ticketsActions.setData({ ... data, error: null }) ) ;
        }

        dispatch( ticketsActions.setFetch({ fetching: false, fetched: true }) ) ;
    }
    async function removeTicketByID( id: number ) {

        /* debug */ Logger.saga( "useMyTickets.removeTicketByID([ id ])", id ) ;

        dispatch( ticketsActions.setFetch({ fetching: true, fetched: false }) ) ;

        let payload: any,
            error: any ;
        try { payload = await apiRemoveTicketByID( id ) }
        catch ( exception: any ) {
            const message = exception?.message || "[ useMyTickets ] unhandled error" ;
            error = { message }
            /* error */ Logger.error( "useMyTickets.removeTicketByID([ error ])", { error } ) ;
        }

        error && NotificationManager.error( error.message ) ;

        const { id: _id } = payload?.data || {} ;
        _id && +_id === id && dispatch( ticketsActions.remove({ id }) ) ;

        dispatch( ticketsActions.setFetch({ fetching: false, fetched: true }) ) ;

        return payload?.data ;
    }
    async function assignTo( ticket_id: number, user_id: number ): Promise<boolean> {
        try { await assignTicketTo( ticket_id, user_id ) }
        catch ( exception: any ) {
            const message = exception?.message || "[ useMyTickets.assignTo ] unhandled error" ;
            NotificationManager.error( message ) ;
            return false ;
        }

        return true ;
    }

    function setPageNo( pageNo: number ) {
        dispatch( ticketsActions.setPageNo( pageNo ) ) ;
    }
    function setSearch( search: string ) {
        dispatch( ticketsActions.setSearch( search ) ) ;
    }
    function setPerPageLimit( perPageLimit: number ) {
        dispatch( ticketsActions.setPerPageLimit( perPageLimit ) ) ;
    }
    function setFilter( filter: any ) {
        dispatch( ticketsActions.setFilter( filter ) ) ;
    }
    function setSort( sortBy: string, sortDirection: TSortDirections ) {
        dispatch( ticketsActions.setSort({ sortBy, sortDirection }) ) ;
    }
    function update( ticket_id: number, data: object ) {
        dispatch( ticketsActions.update({ ticket_id, data }) ) ;
    }

    return { load, update, assignTo, removeTicketByID, setFilter, setSort, setPageNo, setPerPageLimit, setSearch }
}
