import React from "react";
import ClassNames from "classnames";
import PropTypes from "prop-types";
import "../../../../styles/components/ui-kit/status_label.scss";

function StatusLabel({ id, className, children, onClick }) {
    return (
        <div className={ ClassNames( "StatusLabel", className ) } data-status-id={ id } onClick={ onClick }>
            { children }
        </div>
    )
}
StatusLabel.defaultProps = {
    className: "",
    onClick: () => ({})
}
StatusLabel.propTypes = {
    id: PropTypes.string,
    className: PropTypes.string,
    onClick: PropTypes.func
}

export default StatusLabel ;
