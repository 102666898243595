import Logger from "../../helpers/logger";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    data: []
}

export const permissionsSlice = createSlice({
    name: 'permissions',
    initialState,
    reducers: {
        set( state, { payload }) {
            /* debug */ Logger.reducer( "permissionsSlice.set([ payload ])", { state, payload }) ;
            state.data = payload ;
        },
        destroy( state ) {
            /* debug */ Logger.reducer( "permissionsSlice.destroy([ state ])", state ) ;
            state.data = initialState.data ;
        }
    }
})

const { reducer, actions } = permissionsSlice;
export const permissionsActions = actions;
export default reducer;
