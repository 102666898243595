import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Select } from 'antd';
import { map } from 'lodash';
import { useTranslation } from "react-i18next";
import { useReduxSelector } from "../../../../../../hooks/redux.hook";
import useDepsHook from "../../../../../../hooks/useDeps.hook";
import useI18N from "../../../../../../hooks/useI18N.hook";
import { jsonToObject } from "../../../../../../helpers/common";
import '../../../../../../styles/components/deps_selector.scss';
import classNames from "classnames";

function DepsSelectorView() {
    const { t } = useTranslation();
    const { fromI18N } = useI18N() ;
    const { data: profile } = useReduxSelector( ({ profile }) => profile ) ;

    return (
        <div className="DepsSelectorView">
            <div className="sub">{ t("TicketProperty.from.placeholder") }</div>
            <div className="name">
                { fromI18N( jsonToObject( profile.dep_title ) ) }
            </div>
            <div className="description">
                { fromI18N( jsonToObject( profile.dep_description ) ) }
            </div>
        </div>
    )
}
DepsSelectorView.defaultProps={}
DepsSelectorView.propTypes={}

function DepsSelector( props ) {
    const { current, disabled } = props ;
    const { t } = useTranslation();
    const { onChange } = props ;
    const { fetched, fetching, data, error } = useReduxSelector( ({ deps }) => deps ) ;
    const { initialize } = useDepsHook() ;
    const { fromI18N } = useI18N() ;
    const isNotInitialize = ! fetched && ! fetching ;

    function componentDidMount() {
        isNotInitialize && initialize() ;
    }

    useEffect( () => { componentDidMount() }, [] )

    const options = map( data, ( item, key ) => {
        return {
            label: (
                <div key={ key } className="DepsSelectorItem" data-disabled={ item?.id === current }>
                    <b>{ fromI18N( jsonToObject( item?.title ) ) }</b>
                    <p>{ fromI18N( jsonToObject( item?.description ) ) }</p>
                </div>
            ),
            value: item?.id
        }
    } )

    return (
        <Select
            disabled={ disabled }
            loading={ isNotInitialize }
            value={ current }
            placeholder={ t("TicketProperty.to.placeholder") }
            className={ classNames("DepsSelectorItem", disabled ? "disabled" : null ) }
            size="large"
            onChange={ onChange }
            options={ options }
        />
    )
}
DepsSelector.View = DepsSelectorView ;
DepsSelector.defaultProps = {
    disabled: false
}
DepsSelector.propTypes = {
    disabled: PropTypes.bool,
    current: PropTypes.oneOfType( [
        PropTypes.string,
        PropTypes.number
    ] ),
    onChange: PropTypes.func
}

export default DepsSelector;