import React, { useState } from "react";
import PropTypes from "prop-types";
import ReviewStatusLabel from "../label";
import Logger from "../../../../helpers/logger";
import { Popover } from "antd";
import { FileStatusEditor } from "../../../tickets/ticket_property/components/FilesExplorer/FileStatus";
import { valuesIn } from "lodash";
import { REVIEW_STATUSES } from "../../../../dto";

function ReviewStatusSelector( props ) {

    /* debug */ Logger.info("ReviewStatusSelector([ props ])", props ) ;

    const [ isEdit, setEdit ] = useState( false ) ;
    const { id, description, children, placement, onClose, onSave } = props ;

    function close() {
        setEdit( false ) ;
        onClose && onClose() ;
    }
    function open() {
        setEdit( true ) ;
    }
    function onSaveHandler( data ) {
        onSave && onSave({
            review_status_id: data?.status_id,
            review_status_description: data?.status_description
        }) ;
        close() ;
    }

    return (
        <Popover visible={ isEdit } placement={ placement } trigger="click" content={
            <FileStatusEditor data={{ status_id: id, status_description: description }} onCancel={ close } onSave={ onSaveHandler } statuses={ valuesIn( REVIEW_STATUSES ) } />
        }>
            <ReviewStatusLabel id={ id } className="ReviewStatusSelector" onClick={ open }>
                { children }
            </ReviewStatusLabel>
        </Popover>
    )
}
ReviewStatusSelector.defaultProps = {
    placement: "left",
    onClose: () => ({}),
    onSave: () => ({}),
}
ReviewStatusSelector.propTypes = {
    id: PropTypes.string,
    description: PropTypes.string,
    placement: PropTypes.string,
    onClose: PropTypes.func,
    onSave: PropTypes.func
}

export default ReviewStatusSelector ;