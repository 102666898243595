import React from "prop-types";
import PropTypes from "prop-types";
import StatusLabel from "../../status/label";

function ReviewStatusLabel( props ) {
    const { id, children, className, onClick } = props ;
    return (
        <StatusLabel id={ id } className={ className } onClick={ onClick }>
            { children }
        </StatusLabel>
    )
}
ReviewStatusLabel.defaultProps = {
    className: "ReviewStatusLabel"
}
ReviewStatusLabel.propTypes = {
    id: PropTypes.string,
    className: PropTypes.string,
    onClick: PropTypes.func
}

export default ReviewStatusLabel ;