import React from 'react';
import {Tooltip} from 'antd';
import classNames from "classnames";
import Label from "../../../../ui-kit/label";
import "../../../../../styles/components/data_caption.scss";

export type TDataCaptionProps = {
    label?: string,
    description?: string,
    children?: React.ReactNode,
    bordered?: boolean,
    className?: string
}

function DataCaption( props: TDataCaptionProps ) {
    const { label, description, children, className, bordered } = props ;
    return (
        <div className={ classNames( "DataCaption", className ) } data-bordered={ bordered }>
            <Tooltip placement="top" title={ description }>
                { label && <Label className="title" value={ label } /> }
                { children && (<div key="value" data-cell="value">{ children }</div> )}
            </Tooltip>
        </div>
    )
}

export default DataCaption ;