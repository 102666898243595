import { TTicketRemoteFile, TTicketsResponseData } from "../types";
import { assignTaskToCompany, assignTaskToDep, createNewTicket, getTicketByID, updateTicket } from "../helpers/api.services";
import Logger from "../helpers/logger";
import { useReduxDispatch } from "./redux.hook";
import { ticketPropertyActions } from "../store/slices/ticketProperty.slice";
import { NotificationManager } from "react-notifications";
import { useTranslation } from "react-i18next";

export type TTicketPropertyHook = {
    initialize: Function,
    setSigners: Function,
    readByID: Function,
    update: Function,
    reset: Function,
    sync: Function,
    assignToDep: Function,
    assignToCompany: Function
}
export default function useTicketPropertyHook(): TTicketPropertyHook {
    const dispatch = useReduxDispatch();
    const { t } = useTranslation() ;

    async function initialize( data: TTicketsResponseData ) {
        /* debug */ Logger.saga( "useTicketPropertyHook() initialize()" ) ;

        dispatch( ticketPropertyActions.setFetch({ fetching: true, fetched: false }) ) ;

        let payload: any,
            error: any ;
        try { payload = await createNewTicket( data ) }
        catch ( exception: any ) {
            const message = exception?.message || "[ useTicketPropertyHook ] unhandled error" ;
            error = { message }
            /* error */ Logger.error( "useTicketPropertyHook.initialize([ error ])", { error } ) ;
        }

        /* debug */ Logger.saga( "useTicketPropertyHook() initialize([ payload ])", payload ) ;

        if( error ) {
            NotificationManager.error( error.message )
            dispatch( ticketPropertyActions.setData({ error, data: [] }) ) ;
        } else {
            const data = payload?.data ;
            /* debug */ Logger.success( "useTicketPropertyHook.initialize([ data ])", data ) ;
            dispatch( ticketPropertyActions.setData({ data, error: null }) ) ;
        }

        dispatch( ticketPropertyActions.setFetch({ fetching: false, fetched: true }) ) ;
    }
    async function readByID( id: string | number ) {
        /* debug */ Logger.saga( "useTicketPropertyHook() readByID([ id ])", id ) ;

        dispatch( ticketPropertyActions.setFetch({ fetching: true, fetched: false }) ) ;

        let payload: any,
            error: any ;
        try { payload = await getTicketByID( id ) }
        catch ( exception: any ) {
            const message = exception?.message || "[ useTicketPropertyHook ] unhandled error" ;
            error = { message }
            /* error */ Logger.error( "useTicketPropertyHook.initialize([ error ])", { error } ) ;
        }

        /* debug */ Logger.saga( "useTicketPropertyHook() readByID([ payload ])", payload ) ;

        if( error ) {
            NotificationManager.error( error.message )
            dispatch( ticketPropertyActions.setData({ error, data: [] }) ) ;
        } else {
            const data = payload?.data ;
            /* debug */ Logger.success( "useTicketPropertyHook.readByID([ data ])", data ) ;
            dispatch( ticketPropertyActions.setData({ data, error: null }) ) ;
        }

        dispatch( ticketPropertyActions.setFetch({ fetching: false, fetched: true }) ) ;
    }
    async function sync( id: string, data: any, callback: Function ) {
        /* debug */ Logger.saga( "useTicketPropertyHook() sync([ id, data ])", id, data ) ;

        dispatch( ticketPropertyActions.setFetch({ fetching: true, fetched: true }) ) ;

        let payload: any,
            error: any ;

        try { payload = await updateTicket( id, <TTicketsResponseData> data ) }
        catch ( exception: any ) {
            const message = exception?.message || "[ useTicketPropertyHook ] unhandled error" ;
            error = { message }
            /* error */ Logger.error( "useTicketPropertyHook.sync([ error ])", { error } ) ;
        }

        /* debug */ Logger.success( "useTicketPropertyHook() sync([ error, payload ])", { error, payload, callback } ) ;

        if( ! error ) {
            const data = payload?.data ;

            /* debug */ Logger.success( "useTicketPropertyHook.sync([ data ])", data ) ;

            dispatch( ticketPropertyActions.setData({ data, error: null }) ) ;

            if( callback ) {
                /* debug */ Logger.warn( "useTicketPropertyHook() sync() callback([1])" ) ;
                const files = await callback( payload ) ;
                /* debug */ Logger.success( "useTicketPropertyHook() sync() callback([ files ])", files ) ;

                dispatch( ticketPropertyActions.setFiles( files ) ) ;
            }

            const message: string = t("common.saved") ;
            NotificationManager.info( message ) ;
        }

        error && NotificationManager.error( error.message ) ;
        dispatch( ticketPropertyActions.setFetch({ fetching: false, fetched: true }) ) ;

        return (
            ! error
            ? Promise.resolve( payload )
            : Promise.resolve( error )
        )
    }
    async function assignToDep( ticket_id: number ): Promise<boolean> {
        dispatch( ticketPropertyActions.setFetch({ fetching: true, fetched: true }) ) ;

        let error: any ;

        try { await assignTaskToDep( ticket_id ) }
        catch ( exception: any ) { error = exception?.message || "[ useMyTickets.assignToDep ] unhandled error" }

        if( ! error ) await readByID( ticket_id ) ;
        else {
            error && NotificationManager.error( error ) ;
            dispatch( ticketPropertyActions.setFetch({ fetching: false, fetched: true }) ) ;
        }

        return ! Boolean( error ) ;
    }
    async function assignToCompany( ticket_id: number ): Promise<boolean> {
        dispatch( ticketPropertyActions.setFetch({ fetching: true, fetched: true }) ) ;

        let error: any ;

        try { await assignTaskToCompany( ticket_id ) }
        catch ( exception: any ) { error = exception?.message || "[ useMyTickets.assignToCompany ] unhandled error" }

        if( ! error ) await readByID( ticket_id ) ;
        else {
            error && NotificationManager.error( error ) ;
            dispatch( ticketPropertyActions.setFetch({ fetching: false, fetched: true }) ) ;
        }

        return ! Boolean( error ) ;
    }

    function reset() {
        /* debug */ Logger.saga( "useTicketPropertyHook() reset()" ) ;

        dispatch( ticketPropertyActions.reset() ) ;
    }
    function update( data: object, callback: Function ) {
        /* debug */ Logger.saga( "useTicketPropertyHook() update([ data ])", data ) ;

        dispatch( ticketPropertyActions.setFetch({ fetching: true, fetched: true }) ) ;
        dispatch( ticketPropertyActions.update( data ) ) ;
        dispatch( ticketPropertyActions.setFetch({ fetching: false, fetched: true }) ) ;

        callback && callback() ;
    }
    function setSigners( file: TTicketRemoteFile[], signers: any[] ) {
        /* debug */ Logger.warn( "useTicketPropertyHook() setSigners([ file, signers ])", { file, signers } ) ;
        dispatch( ticketPropertyActions.setSigners({ file, signers }) ) ;
    }

    return { initialize, assignToCompany, assignToDep, setSigners, sync, readByID, update, reset }
}
