import { PERMISSIONS } from "../../../dto";
import React from "react";
import { ConfigProvider } from "antd";
import Tickets_UI from "../../tickets";
import { useMatches, useParams } from "react-router-dom";
import LayoutContext from "../layout_context";
import usePermissionsHook from "../../../hooks/usePermissionsHook";
import Message from "../message";
import PropTypes from "prop-types";
import { has, values } from "lodash";
import "../../../styles/components/ui-kit/permission.scss";
import Logger from "../../../helpers/logger";

function PermissionVerifycator({ id, name, value, whileFailed, children }) {
    /* debug */ Logger.log("PermissionVerifycator([ id, name, value ])", { id, name, value } ) ;

    const { getPermissionsById } = usePermissionsHook() ;
    const permissions = getPermissionsById( id ) ;
    const isAllowed = permissions && has( permissions, name ) && permissions[ name ] === value ;

    return (
        ! isAllowed
        ? (
            ! whileFailed
            ? <div/>
            : whileFailed
        ): children
    )
}
PermissionVerifycator.propTypes = {}
PermissionVerifycator.defaultProps = {
    id: PropTypes.string,
    name: PropTypes.oneOf( values( PERMISSIONS ) ),
    value: PropTypes.any,
    whileFailed: PropTypes.element
}

function Permission() {
    const match = useMatches() ;
    /* debug */ Logger.log("Permission([ match ])", match ) ;

    const identify = match.at(-1) ;
    /* debug */ Logger.log("Permission([ identify ])", identify ) ;

    const id = identify?.id ;
    /* debug */ Logger.log("Permission([ id ])", id ) ;

    const { initialize, getPermissionsById } = usePermissionsHook() ;
    const antdColorThemeConfig = initialize( id ) ;
    const permissions = getPermissionsById( id ) ;
    const Component = Tickets_UI[ id ] ;

    return (
        <LayoutContext className="Permission">
            <ConfigProvider theme={ antdColorThemeConfig }>
                {
                    ! Component
                    ? <Message
                            type={ Message.types.ERROR }
                            className="ErrorMessage"
                            header="ERROR"
                            message={ `Renderer for "${ id }" module not exists` }
                        />
                    : (
                        <PermissionVerifycator
                            id={ id }
                            name={ PERMISSIONS.READ }
                            value="1"
                            whileFailed={
                                <Message
                                    type={ Message.types.WARN }
                                    className="ErrorMessage"
                                    header="PERMISSION DENIED"
                                    message={ `You do not have enough PERMISSIONS to READ the area` }
                                />
                            }
                        >
                            <Component
                                id={ id }
                                permissions={ permissions }
                            />
                        </PermissionVerifycator>
                    )
                }
            </ConfigProvider>
        </LayoutContext>
    )
}
Permission.propTypes = {}
Permission.defaultProps = {}
Permission.Verifycator = PermissionVerifycator ;

export default Permission ;