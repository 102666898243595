import React from 'react';
import { find } from 'lodash';
import Logger from "../../../../../helpers/logger";
import {FIELDS} from "../../fields.dto";

export default ( options ) => ( props ) => {
    /* debug */ Logger.info( "HeaderRenderer([ props ])", { props, options } ) ;

    const { displayName, column:{ colId } } = props ;
    const { data:{ roles }, meta } = options ;

    let $component ;
    switch ( colId ) {
        case FIELDS.NAME:
        case FIELDS.ACCESS: {
            $component = displayName ;
            break ;
        }
        default: {
            const info = find( roles, { id: colId } ) ;
            $component = (
                <div className="ColumnHeader">
                    <b className="title">{ colId }</b>
                    <p className="description">{ info?.description }</p>
                </div>
            )
        }
    }

    return $component ;
};