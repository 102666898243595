import React, { useEffect, useMemo, useState } from "react";
import useDepTickets from "../../../hooks/useDepTickets.hook";
import Spinner from "../../ui-kit/spinner";
import Button from "../../ui-kit/button";
import Message from "../../ui-kit/message";
import { useReduxSelector } from "../../../hooks/redux.hook";
import DepTicketsComponent from "./DepTicketsComponent";
import useI18N from "../../../hooks/useI18N.hook";
import useDepsHook from "../../../hooks/useDeps.hook";
import { isNil } from "lodash";
import { useTranslation } from "react-i18next";
import { PAGINATOR_DEFAULTS_PAGE_NO } from "../../../dto";
import { Modal } from 'antd';
import Logger from "../../../helpers/logger";
import { DepsList, EmployeesList, ReAssignersSelector } from "../../ui-kit/reassigners";
import { NotificationManager } from "react-notifications";
import { reAssignTaskToDep, reAssignTaskToEmployee } from "../../../helpers/api.services";
import { useNavigate } from "react-router-dom";
import useEmployeesHook from "../../../hooks/useEmployees.hook";

export default function DepTickets( props ) {

    /* debug */ Logger.info("DepTickets([ props ])", props ) ;

    const { t } = useTranslation();
    const navigate = useNavigate();
    const [ assignedToDep, addToAssignedToDep ] = useState() ;
    const [ assignedToEmployee, addToAssignedToEmployee ] = useState() ;

    const [ recycleBin, addToRecycleBin ] = useState() ;
    const depsController = useDepsHook() ;
    const employeesController = useEmployeesHook() ;
    const { fromI18N } = useI18N() ;
    const { tickets, profile:{ data: profile } } = useReduxSelector( store => store ) ;
    const deps = useReduxSelector( ({ deps }) => deps ) ;
    const { fetching, fetched, error, paginator, data } = tickets ;
    const { load, setPageNo, setSearch, setPerPageLimit, setFilter, setSort, removeTicketByID, getTaskForWork } = useDepTickets() ;
    const pageNo = useMemo( () => paginator?.pageNo, [ paginator?.pageNo ] ) ;
    const perPageLimit = useMemo( () => paginator?.perPageLimit, [ paginator?.perPageLimit ] ) ;
    const sortBy = useMemo( () => paginator?.sortBy, [ paginator?.sortBy ] ) ;
    const sortDirection = useMemo( () => paginator?.sortDirection, [ paginator?.sortDirection ] ) ;
    const search = useMemo( () => tickets?.search, [ tickets?.search ] ) ;
    const filter = useMemo( () => tickets?.filter, [ tickets?.filter ] ) ;
    const isDepsInitialize = ! deps?.fetching && deps?.fetched ;
    const isTicketsInitialize = ! fetching && fetched ;
    const isInitialize = isDepsInitialize && isTicketsInitialize ;
    const isRecycleBinNotEmpty = ! isNil( recycleBin ) ;

    const [ reassignTo, setReAssignerType ] = useState({}) ;
    const isReAssignEnabled = Boolean( reassignTo?.section_id && reassignTo?.ticket_id ) ;

    async function refresh() {
        await load({ paginator, search, filter }) ;
    }
    async function initialize() {
        // инициализация списков департаментов ;
        ! isDepsInitialize && depsController.initialize() ;
    }
    async function removeTicket() {
        const ticket_id = recycleBin?.id ;
        await removeTicketByID( ticket_id ) ;
        clearRecycleBin() ;
    }

    function clearRecycleBin() {
        addToRecycleBin( null );
    }
    function closeAllModals() {
        setReAssignerType({}) ;
        Modal.destroyAll() ;
    }

    function showDeleteFileConfirmation() {
        if( isRecycleBinNotEmpty ) {
            function onYes( handler )  {
                removeTicket && removeTicket() ;
                handler.destroy() ;
            }
            function onClose( handler ) {
                clearRecycleBin() ;
                handler.destroy() ;
            }
            const handler = Modal.confirm({
                title: t("common.confirmation"),
                content: t( "prompts.removeTicket", recycleBin ),
                footer: (
                    <span className="ant-modal-footer">
                        <Button key="yes" className="save" label={ t( "common.yes" ) } onClick={ () => onYes( handler ) } />
                        <Button key="cancel" ghost className="cancel" label={ t( "common.cancel" ) } onClick={ () => onClose( handler ) } />
                    </span>
                )
            });
        }
    }

    function onMenuChangeHandler( item ) {
        const status_id = item?.status_id ;
        setPageNo( PAGINATOR_DEFAULTS_PAGE_NO ) ;
        setFilter({ ... tickets.filter || {}, status_id }) ;
    }
    function onPageSizeHandler( _, perPageLimit ) {
        setPageNo( PAGINATOR_DEFAULTS_PAGE_NO ) ;
        setPerPageLimit( perPageLimit );
    }
    function onPageHandler( pageNo ) {
        setPageNo( pageNo - 1 ) ;
    }
    function onSearchHandler( search ) {
        setSearch( search ) ;
    }
    function onSortHandler( sortBy, sortDirection ) {
        setSort( sortBy, sortDirection ) ;
    }
    function onRemoveHandler( ticket ) {
        addToRecycleBin( ticket ) ;
    }
    function onTakeForWorkHandler( ticket ) {
        /* debug */ Logger.warn( "DepTickets() onTakeForWorkHandler([ ticket ])", ticket ) ;
        getTaskForWork( ticket.id ) ;
    }

    function onCallModal( section_id ) {
        return ( ticket_id ) => {
            setReAssignerType({ section_id, ticket_id }) ;
        }
    }
    const ReAssignEvents = {
        onSelectDep: async ( dep_id ) => {
            /* debug */ Logger.info("DepTickets() ReAssignEvents.onSelectDep([ ticket_id, dep_id ])", reassignTo?.ticket_id, dep_id ) ;

            let error ;
            try { await reAssignTaskToDep( reassignTo?.ticket_id, dep_id ) }
            catch( exception ) { error = exception  }
            finally { ! error && closeAllModals() }

            ! error
            ? navigate("/")
            : NotificationManager.error( error?.message )
        },
        onSelectEmployee: async ( employee_id ) => {
            /* debug */ Logger.info("DepTickets() ReAssignEvents.onSelectEmployee([ ticket_id, employee_id ])", reassignTo?.ticket_id, employee_id ) ;

            let error ;
            try { await reAssignTaskToEmployee( reassignTo?.ticket_id, employee_id ) }
            catch( exception ) { error = exception  }
            finally { ! error && closeAllModals() }

            ! error
            ? navigate("/")
            : NotificationManager.error( error?.message )
        },
    }

    function modalDialogsRenderer() {
        /* debug */ Logger.log( `TicketProperty() showReAssignerDialog()` ) ;
        switch ( reassignTo?.section_id ) {
            case ReAssignersSelector.ITEMS.DEP: return (
                <DepsList
                    title={ t("prompts.chooseDep") }
                    fromI18N={ fromI18N }
                    initialize={ depsController.initialize }
                    fetching={ depsController.isReady }
                    data={ depsController.data }
                    selected={ profile?.dep_id }
                    onSelect={ ReAssignEvents.onSelectDep }
                />
            );
            case ReAssignersSelector.ITEMS.EMPLOYEE: return (
                <EmployeesList
                    title={ t("prompts.chooseEmployee") }
                    fromI18N={ fromI18N }
                    initialize={ employeesController.initialize }
                    fetching={ employeesController.isReady }
                    data={ employeesController.data }
                    selected={ profile?.$id }
                    onSelect={ ReAssignEvents.onSelectEmployee }
                />
            )
            default: return null ;
        }
    }
    const modals = modalDialogsRenderer() ;

    useEffect( () => { initialize() }, [] ) ;
    useEffect( () => { ( async () => ( await refresh() ))() }, [ filter, search, pageNo, perPageLimit, sortBy, sortDirection ] ) ;
    useEffect( () => { showDeleteFileConfirmation() }, [ isRecycleBinNotEmpty ]) ;

    return (
        <Spinner spinVisible={ ! isInitialize } label={ t("common.loading") }>
            <Modal title={ null } open={ isReAssignEnabled } onCancel={ closeAllModals } footer={ null }>
                { modals }
            </Modal>
            {
                ! error
                ? isDepsInitialize && (
                    <DepTicketsComponent
                        fetching={ fetching }
                        data={ data }
                        filter={ filter }
                        search={ search }
                        paginator={ paginator }
                        onMenuChange={ onMenuChangeHandler }
                        onPage={ onPageHandler }
                        onPageSize={ onPageSizeHandler }
                        onSearch={ onSearchHandler }
                        onSort={ onSortHandler }
                        onRemove={ onRemoveHandler }
                        onTakeForWork={ onTakeForWorkHandler }
                        onCallDepsList={ onCallModal( ReAssignersSelector.ITEMS.DEP ) }
                        onCallEmployeeList={ onCallModal( ReAssignersSelector.ITEMS.EMPLOYEE ) }
                    />
                )
                : <Message
                    className="ErrorMessage"
                    type={ Message.types.ERROR }
                    header="ERROR"
                    message={ error.message }
                />
            }
        </Spinner>
    )
}