import Logger from "../../helpers/logger";
import { createSlice } from "@reduxjs/toolkit";
import { get, keys, set } from "lodash";

const initialState = {
    isReady: false,
    error: null
}
export const socketSlice = createSlice({
    name: 'socket',
    initialState,
    reducers: {
        update( state, { payload } ) {
            /* debug */ Logger.reducer( "socketSlice.update([ payload ])", payload ) ;
            state.isReady = payload?.isReady === true ;
            state.error = payload?.error ;
        },
        reset( state ) {
            keys( initialState ).forEach(( field ) => set( state, field, get( initialState, field, null ) ))
        }
    }
})

const { reducer, actions } = socketSlice;
export const socketActions = actions;
export default reducer;
