import {TUsersManagerComponentOptions, UsersFields} from "../../fields.dto";

import DefaultCellRenderer from "././././renders/default";
import $toolbar from "././renders/$toolbar";
import $online from "././renders/$online";

import id from "././././renders/id";
import role_id from "././././renders/role_id";
import rule_id from "././././renders/rule_id";
import hosts_id from "././././renders/hosts_id";
import dep_id from "././././renders/dep_id";
import companies from "././././renders/companies";
import signer from "././././renders/signer";
import password from "././././renders/password";
import createdAt from "././././renders/createdAt";

export default ( options: TUsersManagerComponentOptions ) => {
    return {
        [ UsersFields.id ]: id( options ),
        [ UsersFields.role_id ]: role_id( options ),
        [ UsersFields.rule_id ]: rule_id( options ),
        [ UsersFields.dep_id ]: dep_id( options ),
        [ UsersFields.companies ]: companies( options ),
        [ UsersFields.signer ]: signer( options ),
        [ UsersFields.createdAt ]: createdAt( options ),
        [ UsersFields.password ]: password( options ),
        [ UsersFields.hosts_id ]: hosts_id( options ),
        // ----
        [ UsersFields.$online ]: $online( options ),
        [ UsersFields.$toolbar ]: $toolbar( options ),
        // ----
        default: DefaultCellRenderer( options ),
    }
}