import React, { useMemo } from "react";
import { Table, Tooltip } from 'antd';
import PropTypes from "prop-types";
import { getSettings } from "../../../../../data/settings";
import { get } from "lodash";
import CellRenderers, { FilesExplorerColumns } from "./CellRenderers";
import { useTranslation } from "react-i18next";
import useI18N from "../../../../../hooks/useI18N.hook";
import { PlusCircleFilled } from '@ant-design/icons';
import Logger from "../../../../../helpers/logger";
import DragAndDropFiles from "../../../../ui-kit/drag_n_drop_files";
import "../../../../../styles/components/files_explorer.scss";
import { formatSize } from "../../../../../helpers/common";
import { TABLE_CELL_WIDTH_DEFAULT } from "../../../../../data/constants";


function FilesExplorer( props ) {
    /* debug */ Logger.warn("FilesExplorer([ props ])", props )

    const settings = getSettings() ;
    const SUPPORTED_FILES_EXTENSIONS = settings.uploaderExtensionAllowed.join(", ") ;
    const MAX_FILESIZE = formatSize( settings.uploaderFileSizeLimitBytes ) ;

    const { t } = useTranslation() ;
    const { value: i18n } = useI18N() ;
    const { files, disableFileStatusSelection, disableSignersSelection, isMultipleSelectionEnabled, isReadOnly, isOwner, company_id, fieldsAllowed, onSelectFiles, onRemove, onSignersUpdate, onRemoveLocalFile, onChangeFileName, onChangeComment, onNewFilesAdded, onChangeFileStatus } = props ;
    const CellRendererCollection = useMemo(
        ()=> CellRenderers({
            t,
            isOwner,
            isReadOnly,
            disableSignersSelection,
            disableFileStatusSelection,
            company_id,
            onRemove,
            onSignersUpdate,
            onRemoveLocalFile,
            onChangeFileName,
            onChangeComment,
            onChangeFileStatus
        }),
        [ i18n, isReadOnly ]
    )
    const columns = fieldsAllowed.map( ( id, key ) => ({
        key,
        width: TABLE_CELL_WIDTH_DEFAULT,
        title: (
            <div className="header">
                <div className="title">
                    { t( `FilesExplorer.columns.${ id }` ) }
                </div>
            </div>
        ),
        showSorterTooltip: false,
        dataIndex: id,
        render: ( value_, data_, index ) => {
            const renderer = get( CellRendererCollection, id, null );
            return (
                ! renderer
                ? value_
                : renderer({ id, key, index, value: value_, data: data_ })
            )
        }
    }) ) ;
    const rowSelection = isMultipleSelectionEnabled ? {
        type: "checkbox",
        onChange: onSelectFiles,
        getCheckboxProps: (record) => ({
            disabled: record.name === 'Disabled User',
            // Column configuration not to be checked
            name: record.name,
        }),
    } : null ;


    return (
        <>
            <div className="FilesExplorer">
                <Table
                    size="small"
                    tableLayout="auto"
                    pagination={ false }
                    rowKey="hash"
                    rowSelection={ rowSelection }
                    className="FilesExplorerTable"
                    columns={ columns }
                    rowClassName={ ( record ) => ( record?.disabled === true ? "disabled" : null ) }
                    dataSource={ files }
                />
            </div>
            { ! isReadOnly && (
                <DragAndDropFiles className="add-files" onOpen={ onNewFilesAdded }>
                    <Tooltip placement="left" title={ t("FilesExplorer.tooltips.addFiles", {
                        extensions: SUPPORTED_FILES_EXTENSIONS,
                        size: [
                            MAX_FILESIZE.size,
                            t(`common.dataSizes.${ MAX_FILESIZE.type }`)
                        ].join(" ") })
                    }>
                        <PlusCircleFilled />
                    </Tooltip>
                </DragAndDropFiles>
            ) }
        </>
    )
}
FilesExplorer.defaultProps = {
    isOwner: false,
    isReadOnly: false,
    isMultipleSelectionEnabled: false,
    disableSignersSelection: false,
    disableFileStatusSelection: false,
    fieldsAllowed: [
        FilesExplorerColumns.name,
        FilesExplorerColumns._comment,
        FilesExplorerColumns._sign,
        FilesExplorerColumns._owner,
        FilesExplorerColumns.size,
        FilesExplorerColumns.status_id,
        FilesExplorerColumns._toolbar
    ]
}
FilesExplorer.propTypes = {
    files: PropTypes.any,
    isMultipleSelectionEnabled: PropTypes.bool,
    isOwner: PropTypes.bool,
    isReadOnly: PropTypes.bool,
    disableSignersSelection: PropTypes.bool,
    disableFileStatusSelection: PropTypes.bool,
    company_id: PropTypes.number,
    fieldsAllowed: PropTypes.array,
    onSignersUpdate: PropTypes.func,
    onRemove: PropTypes.func,
    onRemoveLocalFile: PropTypes.func,
    onNewFilesAdded: PropTypes.func,
    onChangeFileName: PropTypes.func,
    onChangeComment: PropTypes.func,
    onSelectFiles: PropTypes.func,
    onChangeFileStatus: PropTypes.func
}

export default FilesExplorer ;