import { createSlice } from "@reduxjs/toolkit";
import SessionHelper from "../../helpers/session";
import Logger from "../../helpers/logger";
import { AuthData_I } from "../../types/intarfaces";

const initialState: AuthData_I = {
    access_token: SessionHelper.getToken()
}

export const authenticationSlice = createSlice({
    name: 'authentication',
    initialState,
    reducers: {
        set( state, { payload }) {
            /* debug */ Logger.reducer( "authenticationSlice.set([ payload ])", payload ) ;
            SessionHelper.setToken( payload ) ;
            state.access_token = payload;
        },
        destroy( state ) {
            /* debug */ Logger.reducer( "authenticationSlice.destroy()" ) ;
            SessionHelper.destructor() ;
            state.access_token = null ;
        }
    }
})

const { reducer, actions } = authenticationSlice;
export const authenticationActions = actions;
export default reducer;
