import React, { useCallback, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { PERMISSIONS, SORT_DIRECTIONS, TICKET_STATUSES, UI } from "../../../dto";
import Logger from "../../../helpers/logger";
import { find, first, get, map, uniqueId } from "lodash";
import Button from "../../ui-kit/button";
import Permission from "../../ui-kit/permission";
import { useTranslation } from "react-i18next";
import { BorderlessTableOutlined, CaretDownOutlined, CaretUpOutlined, CloseCircleFilled, LayoutFilled, PlusCircleFilled, SearchOutlined } from '@ant-design/icons';
import { Input, Table } from 'antd';
import { PTPaginator } from "../../../types/prop-types";
import useI18N from "../../../hooks/useI18N.hook";
import CellRenderers, { MyTicketColumns } from "./CellRenderers";
import { useReduxSelector } from "../../../hooks/redux.hook";
import useResizeObserver from "../../../hooks/useResizeObserver";
import { Link } from "react-router-dom";
import ClassNames from "classnames";
import { TABLE_CELL_WIDTH_DEFAULT, TicketPropertyViewModes } from "../../../data/constants";
import "../../../styles/components/my_tickets.scss";

const { Search } = Input;

function SearchKeywords({ keyword, placeholder, onSearch }) {
    const [ value, setValue ] = useState( keyword ) ;
    function onClearSearchHandler() {
        const value = null ;
        setValue( value )
        onSearch( value ) ;
    }
    function onChangeHandler({ target }) {
        setValue( target?.value ) ;
    }
    function onSearchHandler() {
        onSearch( value ) ;
    }

    return (
        <span className="search-wrapper">
            <Search
                className="search"
                placeholder={ placeholder }
                enterButton={ <SearchOutlined className="icon-search" /> }
                size="middle"
                autoFocus={ true }
                value={ value }
                suffix={ value && value.length && <CloseCircleFilled className="icon-clear" onClick={ onClearSearchHandler } /> }
                onChange={ onChangeHandler }
                onSearch={ onSearchHandler }
            />
        </span>
    )
}
function MyTicketsComponent({ menu, fieldsAllowed, data, paginator, fetching, search, filter, onPageSize, onMenuChange, onPage, onSearch, onSort, onRemove }) {

    const [ dimensions, setDimensions ] = useState({ width:0, height: 0 });
    const id = UI.MY_TICKETS ;
    const { value: i18n } = useI18N() ;
    const { t } = useTranslation() ;
    const { data: profile } = useReduxSelector( ({ profile }) => profile ) ;
    const { data: deps } = useReduxSelector( ({ deps }) => deps ) ;
    const { data: permissions } = useReduxSelector( ({ permissions }) => permissions ) ;
    const CellRendererCollection = useMemo( ()=> CellRenderers({ i18n, profile, deps, t, permissions, onRemove }), [ i18n ] )
    const activeMenuItem = getMenuItemByStatusID( filter ) ;

    function getMenuItemByStatusID( options ) {
        const defaults = first( menu ) ;
        const { status_id } = options || {} ;

        if( ! status_id ) return defaults ;

        const item = find( menu, { status_id } ) ;
        return item || defaults ;
    }
    function getNewTicketLink() {
        const { path } = find( permissions, { ui_section_id: UI.CREATE_NEW_TICKET } ) || {} ;
        return path ;
    }

    const createNewTicketLinkURL = getNewTicketLink() ;
    const onResizeHandler = useCallback( ( target ) => {
        setDimensions({
            width: target.offsetWidth,
            height: target.offsetHeight
        }) ;
    }, []);

    function onSubMenuItemClickHandler( id ) {
        return () => ( onMenuChange && onMenuChange( find( menu, { id } ) ) )
    }
    function onSearchHandler( value ) {
        onSearch && onSearch( value ) ;
    }
    function onSortHandler( sortBy, sortDirection ) {
        return () => {
            if( sortBy === paginator?.sortBy && sortDirection === paginator?.sortDirection ) return null ;
            onSort && onSort( sortBy, sortDirection ) ;
        }
    }

    const menuItems = map( menu, ({ id, i18n, icon }) => (
        <div key={ id } data-active={ activeMenuItem?.id === id } className="sub-menu-item" onClick={ onSubMenuItemClickHandler( id ) }>
            { icon }
            { t( i18n ) }
        </div>
    ) )
    const columns = fieldsAllowed.map( ( id, key ) => {
        const title = t( `MyTickets.columns.${ id }` ) ;
        const isSortable =  ! Boolean( id.split("$").length - 1 ) ;
        const isSelected = id === paginator?.sortBy ;
        return ({
            key,
            width: TABLE_CELL_WIDTH_DEFAULT,
            title: (
                <div className="header" data-sort={ isSortable }>
                    <div className="title" data-selected={ isSelected }>
                        { title }
                    </div>
                    { isSortable && (
                        <div className="toolbar">
                            <CaretUpOutlined
                                key="asc"
                                className={ ClassNames("sort", isSelected && paginator?.sortDirection === SORT_DIRECTIONS.ASC ? "active" : "" ) }
                                onClick={ onSortHandler( id, SORT_DIRECTIONS.ASC ) }
                            />
                            <CaretDownOutlined
                                key="desc"
                                className={ ClassNames("sort", isSelected && paginator?.sortDirection === SORT_DIRECTIONS.DESC ? "active" : "" ) }
                                onClick={ onSortHandler( id, SORT_DIRECTIONS.DESC ) }
                            />
                        </div>
                    ) }
                </div>
            ),
            showSorterTooltip: false,
            dataIndex: id,
            render: ( value_, data_, index ) => {
                const renderer = get( CellRendererCollection, id, null );
                return (
                    ! renderer
                    ? value_
                    : renderer({ id, key, index, value: value_, data: data_ })
                )
            }
        })
    } ) ;
    const stageRef = useResizeObserver( onResizeHandler );

    return (
        <div className="MyTickets">
            <div className="sub-menu">{ menuItems }</div>
            <div className="context">
                <div className="wrapper">
                    <div className="toolbar">
                        <Permission.Verifycator
                            id={ id }
                            name={ PERMISSIONS.CREATE }
                            value="1"
                            whileFailed={
                                <Button
                                    className={ ClassNames( "create-new-ticket", "disabled" ) }
                                    icon={ <PlusCircleFilled /> }
                                    label={ t( "MyTickets.create" ) }
                                    showArrow={ false }
                                    ghost={ true }
                                />
                        }>
                            <Link to={ `/${ createNewTicketLinkURL }` } state={{ mode: TicketPropertyViewModes.Update }} >
                                <Button
                                    className={ ClassNames( "create-new-ticket" ) }
                                    icon={ <PlusCircleFilled /> }
                                    label={ t( "MyTickets.create" ) }
                                    showArrow={ false }
                                    ghost={ true }
                                />
                            </Link>

                        </Permission.Verifycator>
                        <SearchKeywords
                            placeholder={ t( "common.search" ) }
                            keyword={ search }
                            onSearch={ onSearchHandler }
                        />
                    </div>
                    <div className="stage" ref={ stageRef }>
                        { dimensions.height && (
                            <Table
                                // bordered={ true }
                                sortDirections={[ "ascend", "descend" ]}
                                tableLayout="auto"
                                className="MyTicketsTable"
                                size="small"
                                rowKey="id"
                                rowClassName={ ( record, index ) => {
                                    const isNotOwner = ! Boolean( record.is_owner ) ;
                                    return isNotOwner ? "not-owner" : null ;
                                }}
                                pagination={{
                                    locale:{ items_per_page: `/ ${ t( "components.paginator.pagePerLimit" ).toLowerCase() }` },
                                    size: "small",
                                    current: parseInt( paginator?.pageNo ) + 1 ,
                                    showTitle: false,
                                    showTotal: ( total ) => ( t( "components.paginator.total", { total }) ),
                                    total: paginator?.total,
                                    pageSize: paginator?.perPageLimit,
                                    pageSizeOptions: [ 5, 15, 30, 50 ],
                                    showSizeChanger: true,
                                    position: [ "bottomLeft" ],
                                    onShowSizeChange: onPageSize,
                                    onChange: onPage
                                }}
                                scroll={{ y: `${ dimensions.height - 125 }px` }}
                                dataSource={ data }
                                columns={ columns }
                            />
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}
MyTicketsComponent.defaultProps = {
    menu: [
        { id: uniqueId(), status_id: null, i18n: "MyTickets.menu.all", icon: <LayoutFilled /> },
        { id: uniqueId(), status_id: TICKET_STATUSES.DRAFT, i18n: `MyTickets.menu.${ TICKET_STATUSES.DRAFT }`, icon: <BorderlessTableOutlined /> },
        { id: uniqueId(), status_id: TICKET_STATUSES.PROCESSED, i18n: `MyTickets.menu.${ TICKET_STATUSES.PROCESSED }`, icon: <BorderlessTableOutlined /> },
        { id: uniqueId(), status_id: TICKET_STATUSES.NEW, i18n: `MyTickets.menu.${ TICKET_STATUSES.NEW }`, icon: <BorderlessTableOutlined /> },
        { id: uniqueId(), status_id: TICKET_STATUSES.SUCCESS, i18n: `MyTickets.menu.${ TICKET_STATUSES.SUCCESS }`, icon: <BorderlessTableOutlined /> },
        { id: uniqueId(), status_id: TICKET_STATUSES.REJECTED, i18n: `MyTickets.menu.${ TICKET_STATUSES.REJECTED }`, icon: <BorderlessTableOutlined /> },
        { id: uniqueId(), status_id: TICKET_STATUSES.SIGNATURE_PENDING, i18n: `MyTickets.menu.${ TICKET_STATUSES.SIGNATURE_PENDING }`, icon: <BorderlessTableOutlined /> },
        { id: uniqueId(), status_id: TICKET_STATUSES.DEP_REQUEST, i18n: `MyTickets.menu.${ TICKET_STATUSES.DEP_REQUEST }`, icon: <BorderlessTableOutlined /> },
        { id: uniqueId(), status_id: TICKET_STATUSES.WAIT_FOR_UPDATE, i18n: `MyTickets.menu.${ TICKET_STATUSES.WAIT_FOR_UPDATE }`, icon: <BorderlessTableOutlined /> },
    ],
    data: [],
    fieldsAllowed: [
        MyTicketColumns.id,
        MyTicketColumns.status_id,
        MyTicketColumns.subject,
        MyTicketColumns.description,
        MyTicketColumns.owner_id,
        MyTicketColumns.company_id,
        MyTicketColumns.dep_id,
        MyTicketColumns.createdAt,
        MyTicketColumns.updatedAt,
        "$toolbar"
    ],
    onMenuChange: ( ... rest ) => Logger.warn( "MyTicketsComponent::onMenuChange([ ... rest ])", ... rest ),
    onPage: ( ... rest ) => Logger.warn( "MyTicketsComponent::onPage([ ... rest ])", ... rest ),
    onPageSize: ( ... rest ) => Logger.warn( "MyTicketsComponent::onPageSize([ ... rest ])", ... rest ),
    onSearch: ( ... rest ) => Logger.warn( "MyTicketsComponent::onSearch([ ... rest ])", ... rest ),
    onSort: ( ... rest ) => Logger.warn( "MyTicketsComponent::onSort([ ... rest ])", ... rest ),
    onRemove: ( ... rest ) => Logger.warn( "MyTicketsComponent::onRemove([ ... rest ])", ... rest ),
}
MyTicketsComponent.propTypes = {
    menu: PropTypes.array,
    data: PropTypes.array,
    fieldsAllowed: PropTypes.array,
    fetching: PropTypes.bool,
    paginator: PTPaginator,
    filter: PropTypes.object,
    search: PropTypes.string,
    onMenuChange: PropTypes.func,
    onPage: PropTypes.func,
    onPageSize: PropTypes.func,
    onSearch: PropTypes.func,
    onSort: PropTypes.func,
    onRemove: PropTypes.func
}

export default MyTicketsComponent ;