import {FIELDS, TEndPointsManagerComponentOptions} from "../../../fields.dto";
import {RULES} from "../../../../../../dto";
import {CheckSquareFilled, CloseSquareFilled, MinusSquareOutlined} from '@ant-design/icons';
import classNames from "classnames";
import {get, filter } from "lodash";
import Logger from "../../../../../../helpers/logger";

const DefaultRenderer = ( options: TEndPointsManagerComponentOptions ) => ( props: any ) => {

    const route = get( props?.data, FIELDS.NAME ) ;
    const role = props.column.colId ;
    const { value } = props ;
    const { onClick, data:{ routes } } = options ;
    const isEnabled = filter( routes, ({ id, rule_id }) => ( id === route && rule_id === RULES.ALLOW ) )?.length ;

    /* debug */ Logger.info( "EndPointsComponent([ isEnabled ])", route, role, isEnabled ) ;

    let $component ;

    switch (value) {
        case RULES.ALLOW: {
            $component = <CheckSquareFilled /> ;
            break ;
        }
        case RULES.DENIED: {
            $component = <CloseSquareFilled /> ;
            break ;
        }
        default: { $component = <MinusSquareOutlined /> }
    }

    function onClickHandler() {
        const $value = value !== RULES.ALLOW ;
        onClick && onClick( route, role, $value ) ;
    }

    return (
        <div className={ classNames( "DefaultRenderer", ! isEnabled && "disabled" ) } onClick={ onClickHandler }>
            <div data-cell-renderer={ role } data-rule={ value || "unset" }>
                { $component }
            </div>
        </div>
    )
}
export default DefaultRenderer ;