import React from 'react';
import Application from '../../components/application';
import { Provider as StoreProvider } from 'react-redux';
import { setupStore } from '../../store/store';
import Bootstrap from "./Bootstrap";
import { NotificationContainer } from 'react-notifications';
import '../../styles/pages/main.scss';
import Logger from "../../helpers/logger";

export default function Main( props ) {
    /* debug */ Logger.info( "Main([ props ])", props ) ;
    return (
        <div className="Main">
            <StoreProvider store={ setupStore() }>
                <Bootstrap>
                    <Application { ... props } />
                    <NotificationContainer/>
                </Bootstrap>
            </StoreProvider>
        </div>
    )
}
