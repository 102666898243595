import { useDropzone } from "react-dropzone";
import { useEffect } from "react";
import PropTypes from 'prop-types';

function DragAndDropFiles({ focused, disabled, children, className, onOpen, onDragOver, onDragLeave }) {
    const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
        accept: {
            'image/jpeg': [".jpg", ".png", ".tiff"],
            'application/vnd.etsi.asic-e+zip':[ ".asice" ],
            'application/msword':[ ".doc" ],
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [ ".docx" ],
            'application/vnd.ms-excel': [ ".xls" ],
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [ ".xlsx" ],
            'application/vnd.ms-powerpoint': [ ".ppt" ],
            'application/vnd.openxmlformats-officedocument.presentationml.presentation': [ ".pptx" ],
            "application/pdf": [".pdf"],
            "application/x-pkcs7-signature": [".p7s"]
        }
    }) ;
    const settings = {
        dragNdrop: {
            ... getRootProps({ className }),
            onDragOver,
            onDragLeave
        },
        input: getInputProps()
    }

    useEffect( () => { onOpen && onOpen( acceptedFiles ) }, [ acceptedFiles ] ) ;

    return (
        <div { ... settings.dragNdrop }>
            <div className="wrapper" data-focused={ focused }>
                <input { ... settings.input } />
                { children }
            </div>
        </div>
    )
}
DragAndDropFiles.defaultProps = {
    disabled: false
}
DragAndDropFiles.propTypes = {
    focused: PropTypes.bool,
    disabled: PropTypes.bool,
    className: PropTypes.string,
    onOpen: PropTypes.func,
    onDragLeave: PropTypes.func,
    onDragOver: PropTypes.func
}

export default DragAndDropFiles ;