import React, {useEffect, useState} from "react";
import Logger from "../../../helpers/logger";
import PropTypes from "prop-types";
import classNames from "classnames";
import {get, filter, map, clone} from "lodash";
import CellRenderers from "./CellRenderers/cells";
import HeaderRenderer from "./CellRenderers/headers";
import {FIELDS} from "./fields.dto";
import Toolbar from "./Toolbar";
import {AgGridReact} from 'ag-grid-react';
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";

function findAllEndPointsByRouteID( data, id ) {
    return filter( data, ({ routes_id }) => ( routes_id === id ) ) ;
}
function setRoleState( data, values ) {

    let $values = clone( values ) ;
    for( let i = 0 ; i < data.length ; i++ ) {
        const { role_id, rule_id } = data[i] ;
        $values[ role_id ] = rule_id ;
    }

    /* debug */ Logger.log( "EndPointsComponent.setRoleState([ data, values, $values ])", { data, values, $values } ) ;


    return $values ;
}

function EndPointsComponent( props ) {
    const { data, meta, onUpdate, onRefresh } = props ;
    const [ state, setState ] = useState([]);
    const [ metadata, setMetadata ] = useState();

    /* debug */ Logger.info( "EndPointsComponent([ props ])", props ) ;

    function initialize() {
        /* debug */ Logger.log( "EndPointsComponent.initialize([ props ])", props ) ;

        refresh() ;
    }
    function refresh() {
        const $data = buildData() ;
        /* debug */ Logger.log( "EndPointsComponent.initialize([ $data ])", $data ) ;
        const $matadata = buildMetadata() ;
        setMetadata( $matadata ) ;
        setState( $data ) ;
    }
    function buildData() {
        /* debug */ Logger.log( "EndPointsComponent.buildData([ data ])", data ) ;

        const { endpoints, rbac, roles, routes } = data ;

        let $roles = {} ;
        for( let i = 0 ; i < roles?.length || 0 ; i++ ) {
            const role = roles[i]?.id ;
            $roles[ role ] = null ;
        }

        let $data = [] ;

        for( let i = 0 ; i < endpoints?.length || 0 ; i++ ) {
            const endpoint = endpoints[i] ;
            const routes_id = endpoint?.id ;
            const routes = findAllEndPointsByRouteID( rbac, routes_id ) ;
            const $cellData = setRoleState( routes, $roles ) ;

            /* debug */ Logger.log( "EndPointsComponent.buildData([ $cellData ])", { $cellData } ) ;

            $data.push({
                [ FIELDS.NAME ]: routes_id,
                ... $cellData
            }) ;
        }

        return $data ;
    }
    function buildMetadata() {
        return map( meta, ({ id, props: $props, data: variants }) => {
            const headerComponent = HeaderRenderer({ data, meta, variants }) ;
            const renderers = CellRenderers({ data, meta, variants, onClick: onCellClickHandler }) ;
            const cellRenderer = get( renderers, id, renderers.default ) ;

            return { ... $props, cellRenderer, headerComponent } ;
        })
    }

    function onCellClickHandler( rule, role, value ) {
        /* debug */ Logger.log( "EndPointsComponent.onCellClickHandler([ rule, role, value ])", { rule, role, value } ) ;
        onUpdate({ rule, role, value }) ;
    }
    function onRefreshHandler() {
        onRefresh && onRefresh() ;
    }

    useEffect( () => { initialize() }, []);
    useEffect( () => { refresh() }, [ data ]);

    return (
        <div className="EndPointsComponent">
            <Toolbar onRefresh={ onRefreshHandler }/>
            <div className={ classNames( "ag-theme-quartz", "EndPointsTable" ) } style={{ height: "100%" }}>
                <AgGridReact rowData={ state } columnDefs={ metadata } rowHeight={ 75 } />
            </div>
        </div>
    )
}
EndPointsComponent.defaultProps = {};
EndPointsComponent.propTypes = {
    data: PropTypes.shape({
        endpoints: PropTypes.array,
        rbac: PropTypes.array,
        roles: PropTypes.array,
        routes: PropTypes.any
    }),
    meta: PropTypes.any,
    onUpdate: PropTypes.func,
    onRefresh: PropTypes.func
};

export default EndPointsComponent;