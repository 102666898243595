import React, {useEffect} from "react";
import Logger from "../../../helpers/logger";
import {useReduxSelector} from "../../../hooks/redux.hook";
import useEndPointsHook from "../../../hooks/useEndPoints.hook";
import {useTranslation} from "react-i18next";
import Spinner from "../../ui-kit/spinner";
import "../../../styles/components/endpoints.scss";
import EndPointsComponent from "./EndPointsComponent";

export default function EndPoints( props ) {
    /* debug */ Logger.info( "EndPoints([ props ])", props ) ;

    const endPointsController = useEndPointsHook() ;
    const { t } = useTranslation() ;
    const { data, meta, error, fetching, fetched } = useReduxSelector( ({ endpoints }) => endpoints ) ;
    const isInitialize = ! fetching && fetched ;

    function initialize() { ! isInitialize && endPointsController.load() ; }
    useEffect( () => { initialize() }, [] ) ;

    function onUpdateHandler( data ) {
        try {
            endPointsController.update( data ) ;
            endPointsController.load( true, true ) ;
        } catch ( exception ) {}
    }

    function onRefreshHandler() {
        endPointsController.load() ;
    }

    return (
        <div className="EndPoints">
            <div className="wrapper">
                {
                    ! isInitialize
                    ? (
                        <Spinner spinVisible={ true } label={ t("common.loading") }>
                            <span/>
                        </Spinner>
                    ) : (
                        <EndPointsComponent
                            data={ data }
                            meta={ meta }
                            onUpdate={ onUpdateHandler }
                            onRefresh={ onRefreshHandler }
                        />
                    )
                }
            </div>
        </div>
    )
}