import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { clone, keys, map } from "lodash";
import Logger from "../../../../helpers/logger";
import FileDownloader from "./FileDownloader";
import { updateFileProperties } from "../../../../helpers/api.services";
import '../../../../styles/components/files_signer.scss';
import Label from "../../../ui-kit/label";
import { useTranslation } from "react-i18next";

function CountersCalculationController( files ) {
    /* debug */ Logger.info( "CountersCalculationController([ files ])", files ) ;

    function calcFiles() {
        if( ! files ) return 0 ;
        return files.length ;
    }
    function calcSigned() {
        let count = 0 ;
        if( ! files ) return count ;
        ( files || [] ).forEach(( file ) => ( file?.isSigned === true ? count++ : null ) )
        return count ;
    }
    function calcErrors() {
        let count = 0 ;
        if( ! files ) return count ;
        ( files || [] ).forEach(( file ) => ( file?.$error ? count++ : null ) )
        return count ;
    }
    function calcUploaded() {
        let count = 0 ;
        if( ! files ) return count ;
        ( files || [] ).forEach(( file ) => ( file?.$data ? count++ : null ) )
        return count ;
    }

    return {
        files: calcFiles(),
        signed: calcSigned(),
        uploaded: calcUploaded(),
        errors: calcErrors()
    }
}
function Signing( props ) {
    /* debug */ Logger.info( "Signing([ props ])", props ) ;

    const { t } = useTranslation() ;
    const { doSign, onRemoteFileUpdate, onSuccess } = props ;
    const [ files, setFiles ] = useState( clone( props.files ) ) ;
    const [ succeededAmount, setSucceededAmount ] = useState(0) ;
    const [ finished, setFinished ] = useState(0) ;
    const [ isAllDone, setIsAllDone ] = useState( false ) ;

    const isAllReadyForStartSigning = Boolean( files && succeededAmount >= files?.length ) ;
    const counters = CountersCalculationController( files ) ;

    function onDownloadFile( file, $error, $data ) {
        /* debug */ Logger.warn( "Signing() onDownloadFile([ file, $data ])", { file, $data } ) ;
        setFiles( value => map( value, ( meta ) => ({
            ... meta,
            ... ( ! $error && meta?.hash === file?.hash ? { $data } : {} ),
            ... ( $error && meta?.hash === file?.hash ? { $error } : {} )
        }) ) ) ;
        setSucceededAmount( value => ( value + 1 )) ;
    }
    function checkIfAllFinished() {
        if( finished === counters.files ) {
            setIsAllDone( true ) ;
            onSuccess( counters ) ;
        }
    }
    async function beginSigning() {
        if( ! isAllReadyForStartSigning ) return ;
        for( let i = 0 ; i < files.length ; i++ ) {
            const file = files[ i ] ;
            const data = file?.$data ;
            if( data ) {
                const isDataReady = ArrayBuffer.prototype.isPrototypeOf( data ) ;
                if( ! isDataReady ) continue ;

                let $sign, error ;
                try { $sign = await doSign( data ) }
                catch ( exception ) { error = exception }

                setFiles( value => map( value, ( meta ) => ({
                    ... meta,
                    ... ( ! error && meta?.hash === file?.hash ? { $sign } : {} ),
                    ... ( error && meta?.hash === file?.hash ? { $error: error } : {} ),
                }) ) )

                if( $sign ) {
                    let payload, error ;
                    try { payload = await updateFileProperties( file.hash, { sign: $sign } ) }
                    catch ( exception ) { error = exception }

                    setFiles( value => map( value, ( meta ) => ({
                        ... meta,
                        ... ( ! error && meta?.hash === file?.hash ? { isSigned: true } : {} ),
                        ... ( error && meta?.hash === file?.hash ? { $error: error } : {} ),
                    }) ) ) ;

                    if( ! error && payload?.data ) {
                        const { data } = payload ;
                        onRemoteFileUpdate( data ) ;
                    }
                }
            }

            setFinished( value => ( value + 1 )) ;
        }
    }

    const items = map( files, ( file, key ) => <FileDownloader key={ key } file={ file } onDownload={ onDownloadFile }/> )
    const statusBarItems = map( keys( counters ), ( key ) => {
        const value = counters[ key ] ;
        return (
            <Label key={ key } value={
                <>
                    { t(`IIT.counters.${ key }`) }:
                    <Label value={ value } />
                </>
            } />
        )
    })

    useEffect( () => { ( async () => ( await beginSigning() ))() }, [ isAllReadyForStartSigning ] ) ;
    useEffect( () => { checkIfAllFinished() }, [ finished ] ) ;

    /* debug */ Logger.warn( "Signing([ finished, counters ])", finished, counters ) ;

    return (
        <div className="Signing">
            <div className="list">
                { items }
            </div>
            <div className="status-bar">
                { statusBarItems }
            </div>
        </div>
    )
}
Signing.defaultProps = {} ;
Signing.propTypes = {
    files: PropTypes.array,
    onRemoteFileUpdate: PropTypes.func,
    doSign: PropTypes.func,
    onSuccess: PropTypes.func,
    onError: PropTypes.func
}

export default Signing ;