import { LANGUAGES_SUPPORTED } from "../dto";
import { TLanguagesSupported } from "../types";

export enum EnvironmentsSupported {
    DEVELOP = "development",
    STAGE = "stage",
    PRODUCTION = "production"
}
export enum LocalStoreKeys {
    I18N = "i18n",
    OAUTH = "oauth"
}
export enum ButtonArrows {
    LEFT = "←",
    RIGHT = "→"
}

export enum TicketPropertyViewModes {
    ReadOnly = "read",
    Update = "update",
    Create = "create"
}

export const DATE_FORMAT = "DD.MM.YYYY" ;
export const TIME_FORMAT = "HH:mm" ;
export const DATE_TIME_FORMAT = `${ DATE_FORMAT } ${ TIME_FORMAT }` ;
export const TABLE_CELL_WIDTH_DEFAULT = "3em" ;
export const TIME_POPUP_DISPLAY_DURATION_MS = 10000 ;

export const APIAccessTokenPrefix: string = "Bearer" ;
export const LanguageDefault: TLanguagesSupported = LANGUAGES_SUPPORTED.UA ;