import { useEffect } from "react";
import md5 from "md5";
import { uniqueId } from "lodash";
import { TEvent } from "../types";
import CrossMessages, { TCrossMessagesOptions } from "../helpers/cross-messages";

export type TIFramesMessengerHook = {
    send: Function
}
export type TFrameMessageBody = TEvent & {
    id?: string | number,
    namespace?: string,
}
export type TIFramesMessengerEvent = {
    data: TFrameMessageBody
    currentTarget?: any
}

function logger( enabled: boolean ): Function {
    const color = "#00ffa6" ;
    const icon = "👽" ;
    return function( text: string, ...variables: any[] ): void {
        enabled && console.log( `%c${ icon } ${ text }`, `color: ${ color };`, ...variables );
    }
}

function useIFramesMessengerHook( options: TCrossMessagesOptions ): TIFramesMessengerHook {

    const log = logger( options.debugEnabled ) ;

    function createID( data: any ): string {
        return md5( `${ uniqueId( Math.random().toString() ) }${ JSON.stringify( data ) }` ).toString() ;
    }
    function onMessage( event: TIFramesMessengerEvent ) {
        if( event?.data?.namespace !== options.namespace ) {
            return ; // log( `[ ${ client } ][ WARNING ] onMessage()`, "Unknown namespace", event ) ;
        }

        const id: string = <string> event.data.id ;
        if( id && useIFramesMessengerHook.sent.includes( id ) ) {
            return ; // log( `[ ${ client } ][ WARNING ] onMessage()`, "Hereself message", event ) ;
        }

        /* debug */ log( `[ ${ options.client } ] onMessage([ ${ event.data.type } ])`, event?.data?.data ) ;

        const handler = options.onEvent || useIFramesMessengerHook.onEventHandler ;
        const _event: TEvent = event.data ;
        handler( _event ) ;
    }
    function send( data: TFrameMessageBody ) {
        /* debug */ log( `[ ${ options.client } ] send([ ${ data.type } ])`, data.data ) ;

        let path: string = "" ;
        switch ( options.client ) {
            case CrossMessages.CLIENT_TYPES.CHILD: {
                path = "parent.postMessage" ;
                break ;
            }
            case CrossMessages.CLIENT_TYPES.PARENT: {
                path = `frames.${ options.namespace }.postMessage` ;
                break;
            }
        }

        const id = data?.id || createID( data?.data ) ;
        const payload = { ... data, namespace: options.namespace, id } ;

        useIFramesMessengerHook.sent.push( id ) ;

        if( options.client === CrossMessages.CLIENT_TYPES.CHILD ) {
            window.parent.postMessage( payload, "*" ) ;
        } else if( options.client === CrossMessages.CLIENT_TYPES.PARENT ) {
            for( let c = 0 ; c < window.frames.length ; c++ ) {
                const frame = window.frames[c] ;
                frame.postMessage( payload, "*" ) ;
            }
        } else {
            /* debug */ log( `[ WARNING ]`, `"${ options.client }" has unknown client type` ) ;
        }
    }
    function constructor() {
        window.addEventListener( 'message', onMessage );
    }
    function destructor() {
        window.removeEventListener( 'message', onMessage );
    }

    useEffect( ()=>{
        constructor() ;
        return () => { destructor() }
    }, [])

    return { send }
}
useIFramesMessengerHook.onEventHandler = () => {}
useIFramesMessengerHook.sent = <any[]>[] ;

export default useIFramesMessengerHook ;