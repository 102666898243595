import React from "react";
import Header from "../../ui-kit/header";
import Label from "../../ui-kit/label";
import { map } from "lodash";
import { useReduxSelector } from "../../../hooks/redux.hook";
import KeyIcon from "../../ui-kit/key_icon";
import useI18N from "../../../hooks/useI18N.hook";
import { jsonToObject } from "../../../helpers/common";
import "../../../styles/components/profile.scss";

export default function Profile( props ) {

    let companies = null,
        dep = null ;

    const { data: profile } = useReduxSelector( ({ profile }) => profile ) ;
    const { fromI18N } = useI18N() ;

    if( profile?.companies ) {
        companies = map( profile.companies || [], ({ id, name, position, sign }) => {
            // const isSigner = Boolean( sign ) ;
            return (
                <div key={ id } className="company">
                    <Label value={ position }/>
                    <Header size={ Header.sizes.small } text={
                        <>
                            { name }
                            { <KeyIcon signRights={ sign } /> }
                        </>
                    } />
                </div>
            )
        } )
    }
    if( profile?.dep_name ) {
        dep = {
            title: fromI18N( jsonToObject( profile.dep_title ) ),
            description: fromI18N( jsonToObject( profile.dep_description ) )
        }
    }

    return (
        <div className="Profile">
            <div className="wrapper">
                <div key="name" className="row" data-direction="column">
                    <div key="1" className="id">
                        <Header text={ profile?.$id } size={ Header.sizes.middle }/>
                    </div>
                    <div key="2" className="id">
                        <Header text={ profile?.id } size={ Header.sizes.middle }/>
                    </div>
                    <div key="3" className="personal">
                        <Label value={ `${ dep?.title || "" } (${ profile.position } )`.trim() }/>
                        <Header className="fullname" text={
                            <>
                                { profile.fullname  }
                                { <KeyIcon signRights={ profile?.signer } /> }
                            </>
                        } size={ Header.sizes.middle }/>
                        <Label className="username" value={ profile.name }/>
                    </div>
                </div>
                { companies && (
                    <div key="companies" className="row" data-direction="column">
                        { companies }
                    </div>
                ) }
            </div>
        </div>
    )
}