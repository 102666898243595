import { TOAuthLocalStoreData, TString } from "../types";
import { LocalStore_I } from "../types/intarfaces";
import LocalStorage from "./local-storage";
import { LocalStoreKeys } from "../data/constants";

class SessionHelper {
    private _store: LocalStore_I ;
    constructor() {
        this._store = LocalStorage( LocalStoreKeys.OAUTH ) ;
    }
    getToken(): TString {
        const data: TOAuthLocalStoreData = this._store.getState() ;
        return data?.oauth ;
    }
    setToken( token: string ): void {
        this._store.setState({ oauth: token })
    }
    destructor(): void {
        this._store.clear() ;
    }
}

export default new SessionHelper() ;
