import { jsonToObject } from "./common";
import { TObject } from "../types";
import { LocalStore_I } from "../types/intarfaces";

const LocalStorage = ( name: string ): LocalStore_I => ({
	getState: (): TObject => jsonToObject( localStorage.getItem( name ) as string ),
	setState: ( data: object ): void => localStorage.setItem( name, JSON.stringify( data ) ),
	clear: (): void => localStorage.removeItem( name )
})

export default LocalStorage ;
