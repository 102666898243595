import React, { useMemo } from "react";
import * as RouterPages from "./routes";
import { createBrowserRouter, createRoutesFromElements, RouterProvider } from "react-router-dom";
import { getSettings } from "../../data/settings";
import { isEmpty } from "lodash";
import { useReduxSelector } from "../../hooks/redux.hook";

const settings = getSettings() ;

function ApplicationRouter({ children }) {
    const { profile: { data: profile }, permissions:{ data: permissions } } = useReduxSelector( ({ profile, permissions }) => ({ profile, permissions }) ) ;
    const routes = useMemo( () => ApplicationRouter.pagesBuilder( profile, permissions ), [ profile ]) ;
    return (
        <>
            <RouterProvider router={ routes } />
            { children}
        </>
    )
}
ApplicationRouter.pagesBuilder = ( profile, permissions ) => {
    const pages = RouterPages.BootstrapRoutes( profile, permissions ) ;
    return createBrowserRouter(
        createRoutesFromElements( pages ),
        ! isEmpty( settings.subRoute )
        ? { basename: settings.subRoute }
        : undefined
    )
}
ApplicationRouter.defaultProps = {}
ApplicationRouter.propTypes = {}

export default ApplicationRouter;
