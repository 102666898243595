import Logger from "../../../../../../../helpers/logger";
import Header from "../../../../../../ui-kit/header";
import Button from "../../../../../../ui-kit/button";
import React, {useState} from "react";
import {TLayoutRenderer, UsersFields} from "../../../../fields.dto";
import {Input, Space} from 'antd';
import PasswordValidator from 'password-validator';
import DataCaption from "../../../DataCaption";
import {TString} from "../../../../../../../types";
import {get, isNil} from "lodash";
import classNames from "classnames";
import { Checkbox } from 'antd';

enum INPUT_SOURCES {
    PASSWORD = "password",
    CONFIRM = "confirm"
}
type TPasswordVisibility = {
    [ INPUT_SOURCES.PASSWORD ]: boolean,
    [ INPUT_SOURCES.CONFIRM ]: boolean
}
type TPasswordData = {
    [ INPUT_SOURCES.PASSWORD ]: TString,
    [ INPUT_SOURCES.CONFIRM ]: TString
}

export default ( props: TLayoutRenderer ) => {
    /* debug */ Logger.success( `ChangePassword([ ${ props.context.id } ])`, props ) ;

    const validator = ( new PasswordValidator() )
        .is().min(8)                                    // Minimum length 8
        .is().max(100)                                  // Maximum length 100
        .has().uppercase()                              // Must have uppercase letters
        .has().lowercase()                              // Must have lowercase letters
        .has().digits(2)                                // Must have at least 2 digits
        .has().not().spaces();                          // Should not have spaces

    const { context:{ data, meta, id: $id, mode }, options } = props ;
    const { t, fromI18N, onOkay, onCancel } = options ;

    const [ state, setState ] = useState<TPasswordData>({
        [ INPUT_SOURCES.PASSWORD ]: null,
        [ INPUT_SOURCES.CONFIRM ]: null
    }) ;
    const [ disabled, setDisabled ] = useState<boolean>( false ) ;
    const [ visible, onVisibleChange ] = useState<TPasswordVisibility>({
        [ INPUT_SOURCES.PASSWORD ]: false,
        [ INPUT_SOURCES.CONFIRM ]: false
    }) ;

    function onChangeVisibleHandler( id: INPUT_SOURCES ) {
        return ( value: boolean ) => onVisibleChange( ( state_ ) => ({ ... state_, [ id ]: value }) )
    }
    function onChangeInput( id: INPUT_SOURCES, value: string ) {

        /* debug */ Logger.log( `ChangePassword.onChangeInput([ validator ])`,  ) ;

        setState( ( state_ ) => ({ ... state_, [ id ]: value }) ) ;
    }
    function onCancelHandler() {
        onCancel && onCancel() ;
    }
    function onOkayHandler() {
        onOkay && onOkay( password, mode ) ;
    }

    const createInput = ( id: INPUT_SOURCES, enabled: boolean = true ) => (
        <DataCaption label={ t( `Users.modal.changePassword.title.${ id }` ) } bordered={false}>
            <Input.Password
                disabled={ ! enabled }
                placeholder={ t( `Users.modal.changePassword.placeholder.${ id }` ) }
                visibilityToggle={{
                    visible: visible[ id ],
                    onVisibleChange: onChangeVisibleHandler( id )
                }}
                onChange={ ({ target:{ value } }) => onChangeInput( id, value ) }
            />
        </DataCaption>
    )

    function getPassword(): TString {
        return state[ INPUT_SOURCES.PASSWORD ] ;
    }
    function hasPasswordEnteredCorrectly(): boolean {
        const value = getPassword() ;
        return value ? Boolean( validator.validate( value ) ) : false ;
    }
    function hasPasswordConfirmed(): boolean {
        const r = {
            a: get( state, INPUT_SOURCES.PASSWORD, null ),
            b: get( state, INPUT_SOURCES.CONFIRM, null ),
        }
        return hasPasswordEnteredCorrectly() && ! isNil( r.a ) && r.a === r.b ;
    }

    const password = getPassword() ;
    const isPasswordEnteredCorrectly = hasPasswordEnteredCorrectly() ;
    const isPasswordConfirmed = hasPasswordConfirmed() ;

    const $password = createInput( INPUT_SOURCES.PASSWORD, true ) ;
    const $confirm = createInput( INPUT_SOURCES.CONFIRM, isPasswordEnteredCorrectly ) ;

    function hasSaveEnabled() {
        if( disabled ) return true ;
        else return isPasswordConfirmed ;
    }

    const isSaveEnabled = ! hasSaveEnabled() ;

    return (
        <div className="ChangePasswordDataLayout" data-mode={ mode }>
            { ! disabled && (
                <>
                    <Header className="title" text={ t("Users.modal.changePassword.header") } size={ Header.sizes.small }/>
                    <div className="data">
                        { $password }
                        { $confirm }
                    </div>
                    {
                        ! isPasswordEnteredCorrectly && (
                            <p className="hint">
                                { t("Users.modal.changePassword.rules") }
                            </p>
                        )
                    }
                    {
                        isPasswordConfirmed && (
                            <b className="result">
                                { t("Users.modal.changePassword.confirmed") }
                            </b>
                        )
                    }
                </>
            ) }

            {
                ! isPasswordConfirmed && (
                    <Checkbox className="remove" checked={ disabled } onChange={ ({ target:{ checked: value } }) => setDisabled( value ) }>
                        <p>{ t("Users.modal.changePassword.remove") }</p>
                    </Checkbox>
                )
            }

            <div className="footer">
                <Button ghost className="close" label={ t("common.cancel") } onClick={ onCancelHandler } />
                <Button className={ classNames( "save", isSaveEnabled && "disabled" ) } label={ t("common.saveChanges") } onClick={ onOkayHandler }/>
            </div>
        </div>
    )
}       