import React from "react";
import StatusLabel from "../label";
import PropTypes from "prop-types";
import { find, map, reject, valuesIn } from "lodash";
import { TICKET_STATUSES } from "../../../../dto";
import Logger from "../../../../helpers/logger";
import { Dropdown } from 'antd';
import { useTranslation } from "react-i18next";
import { CaretDownOutlined } from "@ant-design/icons";
import "../../../../styles/components/ui-kit/status_selector.scss";

function StatusSelector( props ) {
    /* debug */ Logger.info( "StatusSelector([ props ])", props ) ;

    const { t } = useTranslation() ;
    const { id, disabled, placement, statuses, excluded, showExcluded, onChange } = props ;
    const items = reject(
        map( statuses, ( status ) => ({
            key: status,
            value: status,
            label: t(`StatusSelector.statuses.${ status }`),
            disabled: excluded.includes( status ),
            danger: status === id
        })),
        ({ disabled }) => {
            if( showExcluded ) return false ;
            return disabled ;
        }
    );
    const value = find( items, { value: id }) ;

    /* debug */ Logger.log( "StatusSelector([ value, items ])", { value, items } ) ;

    function onChangeHandler({ key: value }) {
        /* debug */ Logger.warn( "StatusSelector() onChangeHandler([ value ])", value ) ;
        onChange( value ) ;
    }

    return (
        ! disabled
        ? <Dropdown className="StatusSelector" menu={{ items, onClick: onChangeHandler }} placement={ placement } autoAdjustOverflow arrow>
            <div>
                { t(`StatusSelector.statuses.${ id }`) }
                <CaretDownOutlined />
            </div>
        </Dropdown> : <StatusLabel id={ id }>{ t(`StatusSelector.statuses.${ id }`) }</StatusLabel>
    )

    /*
    return (
        ! disabled
        ? <Select size="large" className="StatusSelector" labelInValue value={ value } onChange={ onChangeHandler } options={ items }/>
        : <StatusLabel id={ id }>{ t(`StatusSelector.statuses.${ id }`) }</StatusLabel>
    )

     */
}
StatusSelector.defaultProps = {
    disabled: false,
    showExcluded: true,
    placement: "topRight",
    excluded: [
        TICKET_STATUSES.DEP_REQUEST,
        TICKET_STATUSES.SIGNATURE_PENDING,
        TICKET_STATUSES.NEW
    ],
    statuses: valuesIn( TICKET_STATUSES ),
    onChange: ( status_id ) => ( status_id )
};
StatusSelector.propTypes = {
    id: PropTypes.string,
    showExcluded: PropTypes.bool,
    placement: PropTypes.string,
    excluded: PropTypes.arrayOf( PropTypes.string ).isRequired,
    statuses: PropTypes.arrayOf( PropTypes.string ).isRequired,
    disabled: PropTypes.bool,
    onChange: PropTypes.func
}

export default StatusSelector ;