import Logger from "../../../../../../../helpers/logger";
import {TLayoutRenderer, UsersFields} from "../../../../fields.dto";
import RulesRenderer from "../../../CellRenderers/./renders/rule_id";
import RolesRenderer from "../../../CellRenderers/./renders/role_id";
import DepsRenderer from "../../../CellRenderers/./renders/dep_id";
import SignerRenderer from "../../../CellRenderers/./renders/signer";
import CreatedAtRenderer from "../../../CellRenderers/./renders/createdAt";
import CompaniesRenderer from "../../../CellRenderers/./renders/companies";
import Header from "../../../../../../ui-kit/header";
import Button from "../../../../../../ui-kit/button";
import DataCaption from "../../../DataCaption";

export default ( props: TLayoutRenderer ) => {
    /* debug */ Logger.info( `read([ ${ props.context.id } ])`, props ) ;

    const { context:{ data, meta, id: $id, mode }, options } = props ;
    const { t, fromI18N, onOkay, onCancel } = options ;

    const id = (
        <div data-field={ UsersFields.id }>
            <b>{ data.id }</b>
        </div>
    )
    const uid = (
        <div data-field={ UsersFields.uid }>
            <b>{ data.uid }</b>
        </div>
    )

    const position = (
        <div data-field={ UsersFields.position }>{ data.position }</div>
    )

    const name = (
        <div data-field={ UsersFields.name }>{ data.name }</div>
    )

    const RulesRendererComponent = RulesRenderer({ ... props.options, data: meta[ UsersFields.rule_id ]?.data }) ;
    const rule_id = (
        <div data-field={ UsersFields.rule_id }>
            <RulesRendererComponent data={ data }/>
        </div>
    )

    const RolesRendererComponent = RolesRenderer({ ... props.options, data: meta[ UsersFields.role_id ]?.data }) ;
    const role_id = (
        <div data-field={ UsersFields.role_id }>
            <RolesRendererComponent data={ data }/>
        </div>
    )

    const fullname = (
        <div data-field={ UsersFields.fullname }>{ data.fullname }</div>
    )

    const hosts_id = (
        <div data-field={ UsersFields.hosts_id }>
            <b>{ data.host }</b>
        </div>
    )

    const DepsRendererComponent = DepsRenderer({ ... props.options, data: meta[ UsersFields.dep_id ]?.data }) ;
    const dep_id = (
        <div data-field={ UsersFields.dep_id }>
            <DepsRendererComponent data={ data }/>
        </div>
    )

    const $signer = meta[ UsersFields.signer ] ;
    const SignerRendererComponent = SignerRenderer({ ... props.options, data: $signer?.data }) ;
    const signer = (
        <div data-field={ UsersFields.signer }>
            <SignerRendererComponent data={ data }/>
        </div>
    )

    const CompaniesRendererComponent = CompaniesRenderer({ ... props.options, data: meta[ UsersFields.companies ]?.data }) ;
    const companies = (
        <div data-field={ UsersFields.companies }>
            <CompaniesRendererComponent data={ data }/>
        </div>
    )

    const CreatedAtRendererComponent = CreatedAtRenderer({ ... props.options, data: meta[ UsersFields.createdAt ]?.data }) ;
    const createdAt = (
        <div data-field={ UsersFields.createdAt }>
            <CreatedAtRendererComponent data={ data }/>
        </div>
    )

    function onCloseHandler() {
        onCancel && onCancel() ;
    }

    return (
        <div className="ReadUserDataLayout" data-mode={ mode }>
            <Header className="title" text={t("Users.modal.read.header")} size={ Header.sizes.middle }/>
            <div className="wrapper">
                <span data-row="3">
                    <DataCaption label={t(`Users.columns.${UsersFields.name}`)} bordered={true}>{name}</DataCaption>
                    <DataCaption label={t(`Users.columns.${UsersFields.fullname}`)} bordered={true}>{fullname}</DataCaption>
                    <DataCaption label={t(`Users.columns.${UsersFields.createdAt}`)} bordered={true}>{createdAt}</DataCaption>
                </span>
                <span data-row="4">
                    <DataCaption label={t(`Users.columns.${UsersFields.id}`)} bordered={true}>{id}</DataCaption>
                    <DataCaption label={t(`Users.columns.${UsersFields.uid}`)} bordered={true}>{uid}</DataCaption>
                    <DataCaption label={t(`Users.columns.${UsersFields.hosts_id}`)} bordered={true}>{hosts_id}</DataCaption>
                    <DataCaption label={t(`Users.columns.${UsersFields.rule_id}`)} bordered={true}>{rule_id}</DataCaption>
                </span>
                <span data-row="4">
                    <DataCaption label={t(`Users.columns.${UsersFields.dep_id}`)} bordered={true}>{dep_id}</DataCaption>
                    <DataCaption label={t(`Users.columns.${UsersFields.role_id}`)} bordered={true}>{role_id}</DataCaption>
                    <DataCaption label={t(`Users.columns.${UsersFields.position}`)} bordered={true}>{position}</DataCaption>
                    <DataCaption label={t(`Users.columns.${UsersFields.signer}`)} bordered={true}>{signer}</DataCaption>
                </span>
                <DataCaption label={t(`Users.columns.${UsersFields.companies}`)} bordered={true}>{companies}</DataCaption>
            </div>
            <div className="footer">
                <Button ghost className="close" label={t("common.cancel")} onClick={onCloseHandler}/>
            </div>
        </div>
    )
}