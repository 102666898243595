import React from "react";
import classNames from "classnames";
import "../../../styles/components/ui-kit/layout_context.scss";

function LayoutContext({ children, className }) {
    return (
        <div className={ classNames( "LayoutContext", className ) }>
            <div className="wrapper">
                { children }
            </div>
        </div>
    )
}

LayoutContext.defaultProps = {}
LayoutContext.propTypes = {}

export default LayoutContext ;
