import Logger from "../../../../../../../helpers/logger";
import {TLayoutRenderer} from "../../../../fields.dto";
import Button from "../../../../../../ui-kit/button";
import { Input } from 'antd';
import {useState} from "react";
import {isEmpty} from "lodash";
import classNames from "classnames";

const { TextArea } = Input;

export default ( props: TLayoutRenderer ) => {
    /* debug */ Logger.info( `SendMessage([ ${ props.context.id } ])`, props ) ;

    const { context:{ data, meta, id, mode }, options } = props ;
    const { t, fromI18N, onOkay, onCancel } = options ;
    const [ message, setMessage ] = useState() ;
    const isReadyNotReady: boolean = isEmpty( message ) ;

    function onCloseHandler() {
        /* debug */ Logger.log( `SendMessage.onCloseHandler([ value ])`, props ) ;
        onCancel && onCancel() ;
    }
    function onChangeHandler( { target:{ value } }: any ) {
        /* debug */ Logger.log( `SendMessage.onChangeHandler([ value ])`, props ) ;
        setMessage( value )
    }
    function onOkayHandler() {
        /* debug */ Logger.warn( `SendMessage.onOkayHandler([ message ])`, message ) ;
        onOkay && onOkay( { message, user_id: id }, mode ) ;
    }

    return (
        <div className="SendMessageToUserLayout" data-mode={mode}>
            <div className="wrapper">
                <TextArea
                    showCount
                    rows={4}
                    placeholder={ t("Chat.placeholder") }
                    maxLength={ 256 }
                    onChange={ onChangeHandler }
                />
            </div>
            <div className="footer">
                <Button ghost className="close" label={t("common.cancel")} onClick={ onCloseHandler }/>
                <Button className={ classNames( "send", isReadyNotReady && "disabled" ) } label={t("Chat.send")} onClick={ onOkayHandler }/>
            </div>
        </div>
    )
}