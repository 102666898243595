import { getSettings } from "../data/settings";
import { TApplicationSettings } from "../types";

enum colors {
    error = '#ff2754',
    warn = '#ff9a00',
    success = '#2aff00',
    info = '#00ecff',
    log = '#ffffff',
    saga = '#ff00bb',
    reducer = '#da4fff',
}

class Logger {
    static enabled: boolean = false ;
    clear: Function = console.clear;
    constructor() {
        const settings: TApplicationSettings = getSettings() ;
        Logger.enabled = true ; // [ EnvironmentsSupported.DEVELOP, EnvironmentsSupported.STAGE ].includes( settings.environment ) ;
    }

    header = ( text: string ): void => {
        Logger.enabled && console.log(`%c${ text }`,"color: white; background-color: black; font-size: 24px; padding: 1em; border-radius: 8px; border-color: white;") ;
    }
    output = ( text: string, color: string, ...variables: any[] ): void => {
        Logger.enabled && console.log( `%c${ text }`, `color: ${ color };`, ...variables );
    };
    error = ( name: string, ...variables: any[] ): void => {
        const color = colors.error;
        this.output( `[1] ${ name || '' }`, color, ...variables );
    };
    warn = ( name: string, ...variables: any[] ): void => {
        const color = colors.warn;
        this.output( `[2] ${ name || '' }`, color, ...variables );
    };
    info = ( name: string, ...variables: any[] ): void => {
        const color = colors.info;
        this.output( `[3] ${ name || '' }`, color, ...variables );
    };
    log = ( name: string, ...variables: any[] ): void => {
        const color = colors.log;
        this.output( `[4] ${ name || '' }`, color, ...variables );
    };
    success = ( name: string, ...variables: any[] ): void => {
        const color = colors.success;
        this.output( `[5] ${ name || '' }`, color, ...variables );
    };
    saga = ( name: string, ...variables: any[] ): void => {
        const color = colors.saga;
        this.output( `[6] ${ name || '' }`, color, ...variables );
    };
    reducer = ( name: string, ...variables: any[] ): void => {
        const color = colors.reducer;
        this.output( `[7] ${ name || '' }`, color, ...variables );
    };
}

const logger: Logger = new Logger() ;
export default logger ;
