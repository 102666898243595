import React from 'react';
import "../../styles/pages/error-page.scss";
import { useTranslation } from "react-i18next";

export default function ErrorPage({ message }) {
    const {t} = useTranslation();
    return (
        <div className="Application">
            <div className="errorPage">
                <div className="errorPage-icon"></div>
                <p className="errorPage-text">{t("errorPage.oops")}</p>
                <p className="errorPage-tryReload">{ message || t("errorPage.reload") }</p>
            </div>
        </div>
    );
}