import Moments from "moment/moment";
import {DATE_TIME_FORMAT} from "../../../../../../data/constants";
import {TUsersManagerComponentOptions} from "../../../fields.dto";

const CreatedAtRenderer = ( options: TUsersManagerComponentOptions ) => ( props: any ) => {
    const { data } = props ;
    const { createdAt } = data ;
    const value = Moments( createdAt ).format( DATE_TIME_FORMAT ) ;
    const [ date, time ] = `${ value || "" }`.split(" ") ;

    return (
        <div className="CreatedAtRenderer">
            <b>{ date }</b>&nbsp;<b>{ time }</b>
        </div>
    )
}
export default CreatedAtRenderer;