import Logger from "../../helpers/logger";
import { createSlice, current } from "@reduxjs/toolkit";
import { findIndex, get, keys, set } from "lodash";
import { ReduxSliceData_I } from "../../types/intarfaces";
import { TTicketRemoteFile } from "../../types";
import { deepClone } from "../../helpers/common";

const initialState: ReduxSliceData_I = {
    fetched: false,
    fetching: false,
    error: null,
    data: {}
}

export const ticketPropertySlice = createSlice({
    name: 'ticketProperty',
    initialState,
    reducers: {
        reset( state ) {
            state.fetched = initialState.fetched ;
            state.fetching = initialState.fetching ;
            state.error = initialState.error ;
            state.data = initialState.data ;
        },
        setFetch( state, { payload:{ fetched, fetching } }) {
            /* debug */ Logger.reducer( "ticketProperty.setFetch([ fetched, fetching ])", { fetched, fetching }) ;
            state.fetched = fetched ;
            state.fetching = fetching ;
        },
        setData( state, { payload:{ error, data } }) {
            /* debug */ Logger.reducer( "ticketProperty.setData([ error, data ])", { error, data }) ;
            state.error = error ;
            state.data = data ;
        },
        update( state, { payload } ) {
            /* debug */ Logger.reducer( "ticketProperty.update([ payload ])", payload ) ;
            if( payload ) keys( payload ).forEach(( key ) => {
                set( state.data, key, get( payload, key, null ) ) ;
            })
        },
        setFiles( state, { payload } ) {

            /* debug */ Logger.reducer( "ticketProperty.setFiles([ state, payload ])", { state, payload } ) ;

            const files: TTicketRemoteFile[] = payload.files ;

            /* debug */ Logger.reducer( "ticketProperty.setFiles([ files ])", files ) ;

            if( files && files.length) {
                state.data.$files = [ ... files ] ;
            }

            /*

            if( ! state.data.$files ) {
                Logger.reducer( "ticketProperty.setFiles([ A ])" ) ;

                // set( state.data, "$files", files ) ;
                state.data.$files = [ ... files ] ;

            } else {
                Logger.reducer( "ticketProperty.setFiles([ B ])" ) ;
                state.data.$files = [
                    ... files || [],
                    ... state.data.$files || []
                ]
            }
             */
        },
        setSigners( state, { payload } ) {
            let $state: any = deepClone( current( state ) ) ;
            const hash = payload?.file?.hash ;
            const index = findIndex( $state.data?.$files, { hash } ) ;

            if( index >= 0 ) {
                const file: any = $state.data?.$files[ index ] ;
                const signersCache:any = { ... file.signers || {} } ;

                /* debug */ Logger.reducer( "ticketProperty.setSigners([ alreadySigned ])", signersCache ) ;

                file.signers = {} ;
                file.$signers = payload.signers ;
                file.$signers.forEach( ({ id }: any ) => {
                    const isAlreadySigned = Boolean( get( signersCache, id ) ) ;
                    file.signers[ id ] = +isAlreadySigned ;
                } ) ;
            }

            /* debug */ Logger.reducer( "ticketProperty.setSigners([ $files ])", $state?.data.$files ) ;

            state.data.$files = $state?.data.$files ;
        },
        updateFileProperty( state, { payload }) {
            let $state: any = deepClone( current( state ) ) ;
            /* debug */ Logger.reducer( "ticketProperty.updateFileProperty([ payload ])", payload ) ;
            const { hash } = payload ;
            const index = findIndex( $state.data?.$files, { hash } ) ;

            if( index >= 0 ) $state.data.$files[ index ] = payload ;
            state.data.$files = $state?.data.$files ;
        }
    }
})

const { reducer, actions } = ticketPropertySlice ;
export const ticketPropertyActions = actions;
export default reducer;
