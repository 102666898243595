import { find, get } from "lodash";
import { jsonToObject, shortenString } from "../../../helpers/common";
import { DeleteFilled, EditFilled, EyeFilled, EyeInvisibleFilled } from '@ant-design/icons';
import { Profile_I } from "../../../types/intarfaces";
import Moments from "moment";
import ClassNames from "classnames";
import { PERMISSIONS, TICKET_STATUSES, UI } from "../../../dto";
import { Tooltip } from 'antd';
import { DATE_TIME_FORMAT, TicketPropertyViewModes } from "../../../data/constants";
import Permission from "../../ui-kit/permission";
import React from "react";
import { Link } from "react-router-dom";
import StatusLabel from "../../ui-kit/status/label";

export enum MyTicketColumns {
    id = "id",
    status_id = "status_id",
    owner_id = "owner_id",
    subject = "subject",
    description = "description",
    dep_id="dep_id",
    company_id="company_id",
    createdAt="createdAt",
    updatedAt="updatedAt"
}
export type TCellRendererWrapperProps = {
    id: string,
    value: string,
    key: number,
    data: any,
    index: number
    children?: any
}
export function CellRendererWrapper( props: TCellRendererWrapperProps ) {
    return (
        <span key={ `${ props.id }-${ props.index }`} data-field-id={ props.id }>
            { props?.children }
        </span>
    )
}

type TMyTicketsCellRendererParams = {
    i18n: string,
    deps: any[],
    profile: Profile_I,
    t: Function,
    permissions: any[],
    onRemove: Function
}
export default ( params: TMyTicketsCellRendererParams ) => {
    const { t, i18n, deps, permissions, profile, onRemove } = params ;
    return {
        [ MyTicketColumns.id ]: ( params: TCellRendererWrapperProps ): any => {
            const isOwner = Boolean( params?.data?.is_owner ) ;
            return (
                <CellRendererWrapper { ...params }>
                    <b data-value={ params.value } data-owner={ isOwner }>
                        { params.value.toString().trim() }
                    </b>
                </CellRendererWrapper>
            )
        },
        [ MyTicketColumns.status_id ]: ( params: TCellRendererWrapperProps ): any => {
            const dictionary = jsonToObject( params?.data?.status_i18n ) ;
            const status = get( dictionary, i18n, null )

            if( ! status ) return params?.value ;

            return (
                <CellRendererWrapper { ...params }>
                    <StatusLabel id={ params.value }>
                        { status }
                    </StatusLabel>
                </CellRendererWrapper>
            )
        },
        [ MyTicketColumns.owner_id ]: ( params: TCellRendererWrapperProps ): any => {
            return (
                <CellRendererWrapper { ...params }>
                    <b>
                        <p className="position">{ params?.data?.$owner?.position || t("errors.userPositionIsUndeclared") }</p>
                        <br/>{ params?.data?.$owner?.fullname }
                    </b>
                </CellRendererWrapper>
            )
        },
        [ MyTicketColumns.company_id ]: ( params: TCellRendererWrapperProps ): any => {
            return (
                <CellRendererWrapper { ...params }>
                    <b>{ params?.data?.$company?.name }</b>
                </CellRendererWrapper>
            )
        },
        [ MyTicketColumns.dep_id ]: ( params: TCellRendererWrapperProps ): any => {

            const id = params.value ;
            if( ! id ) return null ;

            const item: any = find( deps, { id }) ;
            if( ! item ) return null ;

            const title = get( jsonToObject( item?.title ), i18n, null ) ;
            const description = get( jsonToObject( item?.description ), i18n, null ) ;

            return (
                <CellRendererWrapper { ...params }>
                    <Tooltip placement="top" title={ description }>
                        <b>{ title }</b>
                    </Tooltip>
                </CellRendererWrapper>
            )
        },
        [ MyTicketColumns.subject ]: ( params: TCellRendererWrapperProps ): any => {

            const limit = 24 ;
            const value = `${ params?.value || "" }`.trim() ;
            const cut = shortenString( value, limit, "..." ) ;
            const isLengthChanged = cut.length < value.length ;

            return (
                <CellRendererWrapper { ...params }>
                    {
                        ! isLengthChanged
                        ? cut
                        : (
                            <Tooltip placement="top" title={ value }>
                                <p>{ cut }</p>
                            </Tooltip>
                        )
                    }
                </CellRendererWrapper>
            )
        },
        [ MyTicketColumns.description ]: ( params: TCellRendererWrapperProps ): any => {

            const limit = 24 ;
            const value = `${ params?.value || "" }`.trim() ;
            const cut = shortenString( value, limit, "..." ) ;
            const isLengthChanged = cut.length < value.length ;

            return (
                <CellRendererWrapper { ...params }>
                    {
                        ! isLengthChanged
                        ? cut
                        : (
                            <Tooltip placement="top" title={ value }>
                                <p>{ cut }</p>
                            </Tooltip>
                        )
                    }
                </CellRendererWrapper>
            )
        },
        [ MyTicketColumns.updatedAt ]: ( params: TCellRendererWrapperProps ): any => {
            return (
                <CellRendererWrapper { ...params }>
                    <p>{ Moments( params.value ).format( DATE_TIME_FORMAT ) }</p>
                </CellRendererWrapper>
            )
        },
        [ MyTicketColumns.createdAt ]: ( params: TCellRendererWrapperProps ): any => {
            return (
                <CellRendererWrapper { ...params }>
                    <p>
                        { Moments( params.value ).format( DATE_TIME_FORMAT ) }
                    </p>
                </CellRendererWrapper>
            )
        },
        [ "$toolbar" ]: ( params: TCellRendererWrapperProps ): any => {

            const isOwner = Boolean( params?.data?.is_owner ) ;
            const status = params?.data?.status_id ;
            const ready_state = params?.data?.ready_state ;
            const getRoutePath = get( find( permissions, { ui_section_id: UI.TICKET_PROPERTY } ), "path", "" ).replace( ":ticket_id", params?.data?.id ) ;

            function onRemoveHandler() {
                onRemove( params?.data ) ;
            }

            const view = (
                <Tooltip key="view" placement="top" title={ t("common.view") }>
                    <Link to={ `/${ getRoutePath }` } state={{ mode: TicketPropertyViewModes.ReadOnly }} >
                        <EyeFilled className="button-ticket-open" />
                    </Link>
                </Tooltip>
            )

            const isCanEdit = ( isOwner && [
                TICKET_STATUSES.DRAFT,
                TICKET_STATUSES.SIGNATURE_PENDING,
                TICKET_STATUSES.WAIT_FOR_UPDATE,
                /* TICKET_STATUSES.REJECTED */ // заявка создана клиентом и отменена оператором

            ].includes( status ) ) || ( [
                TICKET_STATUSES.DEP_REQUEST,
                TICKET_STATUSES.WAIT_FOR_UPDATE
            ].includes( status ) )
            const edit = isCanEdit && (
                <Permission.Verifycator
                    key="update"
                    id={ UI.MY_TICKETS }
                    name={ PERMISSIONS.UPDATE }
                    value="1"
                    whileFailed={
                        <EditFilled
                            key="update"
                            className={ ClassNames( "button-ticket-update", "disabled" ) }
                        />
                    }
                >
                    <Tooltip placement="top" title={ t("common.update") }>
                        <Link to={ `/${ getRoutePath }` } state={{ mode: TicketPropertyViewModes.Update }} >
                            <EditFilled
                                key="update"
                                className={ ClassNames( "button-ticket-update" ) }
                            />
                        </Link>
                    </Tooltip>
                </Permission.Verifycator>
            )

            const remove = isOwner && [
                TICKET_STATUSES.DRAFT,
                TICKET_STATUSES.SIGNATURE_PENDING

            ].includes( status ) && (
                <Permission.Verifycator
                    key="delete"
                    id={ UI.MY_TICKETS }
                    name={ PERMISSIONS.DELETE }
                    value="1"
                    whileFailed={
                        <DeleteFilled
                            key="remove"
                            className={ ClassNames( "button-ticket-delete", "disabled" ) }
                        />
                    }
                >
                    <Tooltip placement="top" title={ t("common.remove") }>
                        <DeleteFilled
                            key="remove"
                            className={ ClassNames( "button-ticket-delete", ready_state > 0 ? "disabled" : null ) }
                            onClick={ onRemoveHandler }
                        />
                    </Tooltip>
                </Permission.Verifycator>
            )

            const unsubscribe = (
                <Tooltip placement="top" title={ t("common.unsubscribe") }>
                    <EyeInvisibleFilled
                        key="unsubscribe"
                        className={ ClassNames( "button-ticket-unsubscribe" ) }
                    />
                </Tooltip>
            )

            return (
                <CellRendererWrapper { ...params }>
                    <div data-value>
                        { edit }
                        {
                            isOwner ? (
                                <>
                                    { remove }
                                    { view }
                                </>
                            ) : (
                                <>
                                    {/* unsubscribe */}
                                    { view }
                                </>
                            )
                        }
                    </div>
                </CellRendererWrapper>
            )
        },
    }
}