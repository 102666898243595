import {isNil, map} from "lodash";
import {jsonToObject} from "../../../../../../helpers/common";
import KeyIcon from "../../../../../ui-kit/key_icon";
import {TUsersManagerComponentOptions} from "../../../fields.dto";

type TClientCompany = {
    name: string,
    company_id: number, 
    position: string,
    sign: number
}
type TClientCompanyData = TClientCompany[] ;

const CompaniesRenderer = ( options: TUsersManagerComponentOptions ) => ( props: any ) => {
    // /* debug */ Logger.info( "CompaniesRenderer([ options, props ])", { options, props } ) ;
    const { data: { companies } } = props ;

    function getData(): TClientCompanyData {
        return jsonToObject( companies ) as TClientCompanyData ;
    }
    
    function getCompany(data: TClientCompany, index: number ) {
        const { company_id, position, name, sign } = data ;
        return (
            <div key={ index } className="item">
                <div>
                    <KeyIcon signRights={ sign }/>
                </div>
                <div>
                    <div className="header">
                        <b className="id">{company_id}</b>
                        <b className="position">{position}</b>
                    </div>
                    <div key="b" className="footer">
                        <p className="name">{ name }</p>
                    </div>
                </div>
            </div>
        )
    }

    const data = getData();

    return (
        ! isNil( data )
        ? <div className="CompaniesRenderer">{ map( data, getCompany ) }</div>
        : null
    )
}
export default CompaniesRenderer;