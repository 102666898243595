import React from "react";
import ClassNames from "classnames";
import PropTypes from "prop-types";
import "../../../styles/components/ui-kit/header.scss";

function Header({ className, text, size }) {
    return (
        <p className={ ClassNames( "Header", className ) } data-size={ size }>
            { text }
        </p>
    )
}
Header.sizes = {
    big: "big",
    middle: "middle",
    small: "small"
}
Header.defaultProps = {
    side: Header.sizes.big,
    text: ""
}
Header.propTypes = {
    size: PropTypes.oneOf([
        Header.sizes.big,
        Header.sizes.middle,
        Header.sizes.small
    ]),
    text: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.element
    ])
}

export default Header ;
