import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Label from "../label";
import { find, isNil, map, reject, remove } from "lodash";
import KeyIcon from "../key_icon";
import Button from "../button";
import { useTranslation } from "react-i18next";
import Logger from "../../../helpers/logger";
import { PID } from "../../../types/prop-types";
import { Checkbox } from 'antd';
import "../../../styles/components/ui-kit/company_signers.scss";

function CompanySigners( props ) {
    const { t } = useTranslation() ;
    const { onFilter, onCancel, onSelect, onInvite } = props ;

    const initialData = onFilter( props?.data ) ;
    const [ data ] = useState( initialData ) ;
    const [ selected, setSelected ] = useState( props?.selected ) ;

    const hasSelected = ( id ) => map( selected || [], ( value ) => ( value.toString() ) ).includes( id.toString() )
    const items = map( data, ( value, key ) => {
        const { id, userId, position, signRights, userLastName, userFirstName, userMiddleName, isExists } = value ;
        const fullname = `${ userFirstName || "" } ${ userMiddleName || "" } ${ userLastName || "" }`.trim() ;
        const isSelected = selected && hasSelected( userId ) ;
        const isDisabled = props?.disabled && props?.disabled.includes( +userId ) ;

        function onSelectHandler() {
            if( hasSelected( userId ) ) {
                const reselect = [ ... selected ] ;
                remove( reselect, ( value ) => value == userId ) ;
                setSelected( reselect ) ;
            } else {
                setSelected( ( _ ) => ([ ... _, userId ]) )
            }
        }
        function onInviteHandler() {
            onInvite && onInvite( value ) ;
        }

        return (
            <div
                key={ key }
                className="item"
                //  data-selected={ isSelected }
                data-disabled={ isDisabled }
                onClick={ isDisabled ? ()=>({}) : onSelectHandler }
            >
                <div className="context">
                    <Checkbox checked={ isSelected } />
                    <div className="wrapper">
                        <b>{ position || t("errors.userPositionIsUndeclared") }</b>
                        <Label value={
                            <>
                                { fullname }
                                { <KeyIcon signRights={ signRights } /> }
                            </>
                        }/>
                    </div>
                </div>
                <div className="actions">
                    { ! isExists && <Button className="invite" label={ t( "common.invite" ) } onClick={ onInviteHandler } />}
                </div>
            </div>
        )
    }) ;

    function initSelected() {
        if( selected && ! selected.length ) {
            const selected_ = map( data, ({ userId }) => userId ) ;
            setSelected( selected_ ) ;
        }
    }
    function componentDidMount() {
        // если нужно (при открытии формы) выделить всех (когда (предварительно) не выделен никто
        // initSelected() ;
    }
    function onSelectHandler() {
        const users = map(
            reject(
                map( selected, ( userId ) => find(
                    data,
                    { userId: +userId }
                ) ), isNil
            ),
            ({ userId, position, userFirstName, userMiddleName, userLastName }) => (
                { id: +userId, position, fullname: `${ userFirstName || "" } ${ userMiddleName || "" } ${ userLastName || "" }` }
            )
        ) ;
        /* debug */ Logger.log( "CompanySigners() onSelectHandler([ data, selected, users ])", { data, selected, users } ) ;
        onSelect && onSelect( users ) ;
    }

    /* debug */ Logger.info( "CompanySigners([ props ])", props ) ;
    /* debug */ Logger.info( "CompanySigners([ data, selected ])", { data, selected } ) ;

    useEffect(() => { componentDidMount() }, [] )

    return (
        <div className="CompanySigners">
            <div className="list">{ items }</div>
            <div className="footer">
                <Button className="add" label={ t( "common.add" ) } onClick={ onSelectHandler } />
                <Button ghost className="cancel" label={ t( "common.cancel" ) } onClick={ onCancel } />
            </div>
        </div>
    )
}
CompanySigners.defaultProps = {
    selected: [],
    disabled: [],
    data: [],
    onFilter: ( data ) => ( data ) ,
    onSelect: ( ... rest ) => Logger.warn( "CompanySigners::onSelect([ ... rest ])", ... rest ),
    onInvite: ( ... rest ) => Logger.warn( "CompanySigners::onInvite([ ... rest ])", ... rest )
}
CompanySigners.propTypes = {
    selected: PropTypes.arrayOf([ PID ]),
    disabled: PropTypes.arrayOf([ PID ]),
    data: PropTypes.arrayOf(
        PropTypes.shape({
            userId: PropTypes.number,
            position: PropTypes.string,
            signRights: PropTypes.number,
            userLastName: PropTypes.string,
            userFirstName: PropTypes.string,
            userMiddleName: PropTypes.string
        })
    ),
    onFilter: PropTypes.func,
    onSelect: PropTypes.func,
    onCancel: PropTypes.func,
    onInvite: PropTypes.func
}

export default CompanySigners ;