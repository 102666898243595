import {find} from "lodash";
import {Tooltip} from 'antd';
import {RULES} from "../../../../../../dto";
import {TUsersManagerComponentOptions} from "../../../fields.dto";
import Logger from "../../../../../../helpers/logger";

type TRuleData = {
    id: RULES.ALLOW | RULES.DENIED,
    description: string
}

const RuleRenderer = ( options: TUsersManagerComponentOptions ) => ( props: any ) => {
    // /* debug */ Logger.info( "RuleRenderer([ options, props ])", { options, props } ) ;

    const { data } = props ;
    const { rule_id } = data ;

    function getRule( id: string ): TRuleData | null {
        return find( options?.data, { id } ) ;
    }

    const id = getRule( rule_id )?.id ;
    const description = getRule( rule_id )?.description ;

    return (
        <div className="RuleRenderer">
            <Tooltip placement="top" title={ rule_id }>
                <b className="title" data-id={ id }>{ description }</b>
            </Tooltip>
        </div>
    )
}
export default RuleRenderer;