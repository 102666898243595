import { find, get } from "lodash";
import { jsonToObject, shortenString } from "../../../helpers/common";
import { ContactsFilled, DeleteFilled, EditFilled, EyeFilled, UsergroupAddOutlined, WechatOutlined } from '@ant-design/icons';
import { Profile_I } from "../../../types/intarfaces";
import Moments from "moment";
import { Tooltip } from 'antd';
import { DATE_TIME_FORMAT, TicketPropertyViewModes } from "../../../data/constants";
import React from "react";
import StatusLabel from "../../ui-kit/status/label";
import Permission from "../../ui-kit/permission";
import { PERMISSIONS, TICKET_STATUSES, UI } from "../../../dto";
import ClassNames from "classnames";
import { Link } from "react-router-dom";
import TicketPropertyComponent from "../ticket_property/TicketPropertyComponent";
import { ReAssignersSelector } from "../../ui-kit/reassigners";
import ReviewStatusIdCellRenderer from "./ReviewStatusId.CellRenderer";

export enum DepTicketsColumns {
    id = "id",
    status_id = "status_id",
    owner_id = "owner_id",
    subject = "subject",
    description = "description",
    dep_id="dep_id",
    company_id="company_id",
    createdAt="createdAt",
    updatedAt="updatedAt",
    userId="user_id",
    review_status_id="review_status_id",
    review_status_i18n="review_status_i18n",
}
export type TCellRendererWrapperProps = {
    id: string,
    value: string,
    key: number,
    data: any,
    index: number
    children?: any
}
export function CellRendererWrapper( props: TCellRendererWrapperProps ) {
    return (
        <span key={ `${ props.id }-${ props.index }`} data-field-id={ props.id }>
            { props?.children }
        </span>
    )
}

type TDepTasksCellRendererParams = {
    i18n: string,
    fromI18N: Function,
    deps: any[],
    profile: Profile_I,
    t: Function,
    permissions: any[],
    onRemove: Function,
    onTakeForWork: Function,
    onCallDepsList: Function,
    onCallEmployeeList: Function
}
export default ( params: TDepTasksCellRendererParams ) => {
    const { t, i18n, deps, fromI18N, permissions, profile, onRemove, onTakeForWork, onCallDepsList, onCallEmployeeList } = params ;
    return {
        [ DepTicketsColumns.review_status_id ]: ( params: TCellRendererWrapperProps ): any => {
            return <ReviewStatusIdCellRenderer { ... params } i18n={ i18n } t={ t } profile={ profile } />
        },
        [ DepTicketsColumns.id ]: ( params: TCellRendererWrapperProps ): any => {
            const isFavoriteTask = params?.data?.is_favorite ;
            return (
                <CellRendererWrapper { ...params }>
                    <b data-value={ params.value } data-owner={ ! isFavoriteTask }>
                        { params.value.toString().trim() }
                    </b>
                </CellRendererWrapper>
            )
        },
        [ DepTicketsColumns.status_id ]: ( params: TCellRendererWrapperProps ): any => {
            const status = t(`StatusSelector.statuses.${ params?.data?.status_id }`)
            return (
                <CellRendererWrapper { ...params }>
                    <StatusLabel id={ params.value }>
                        { status }
                    </StatusLabel>
                </CellRendererWrapper>
            )
        },
        [ DepTicketsColumns.owner_id ]: ( params: TCellRendererWrapperProps ): any => {
            const depName = `${ params?.data?.$company?.name || fromI18N( jsonToObject( profile.dep_title as string ) ) }`.toUpperCase() ;
            return (
                <CellRendererWrapper { ...params }>
                    <b>
                        <p className="position">{ params?.data?.$owner?.position || t("errors.userPositionIsUndeclared") }</p>
                        <br/>{ depName }
                    </b>
                    { params?.data?.$owner?.fullname }
                </CellRendererWrapper>
            )
        },
        [ DepTicketsColumns.dep_id ]: ( params: TCellRendererWrapperProps ): any => {

            const id = params.value ;
            if( ! id ) return null ;

            const item: any = find( deps, { id }) ;
            if( ! item ) return null ;

            const title = get( jsonToObject( item?.title ), i18n, null ) ;
            const description = get( jsonToObject( item?.description ), i18n, null ) ;

            return (
                <CellRendererWrapper { ...params }>
                    <Tooltip placement="top" title={ description }>
                        <b>{ title }</b>
                    </Tooltip>
                </CellRendererWrapper>
            )
        },
        [ DepTicketsColumns.subject ]: ( params: TCellRendererWrapperProps ): any => {

            const limit = 24 ;
            const value = `${ params?.value || "" }`.trim() ;
            const cut = shortenString( value, limit, "..." ) ;
            const isLengthChanged = cut.length < value.length ;

            return (
                <CellRendererWrapper { ...params }>
                    {
                        ! isLengthChanged
                        ? cut
                        : (
                            <Tooltip placement="top" title={ value }>
                                <p>{ cut }</p>
                            </Tooltip>
                        )
                    }
                </CellRendererWrapper>
            )
        },
        [ DepTicketsColumns.description ]: ( params: TCellRendererWrapperProps ): any => {

            const limit = 24 ;
            const value = `${ params?.value || "" }`.trim() ;
            const cut = shortenString( value, limit, "..." ) ;
            const isLengthChanged = cut.length < value.length ;

            return (
                <CellRendererWrapper { ...params }>
                    {
                        ! isLengthChanged
                        ? cut
                        : (
                            <Tooltip placement="top" title={ value }>
                                <p>{ cut }</p>
                            </Tooltip>
                        )
                    }
                </CellRendererWrapper>
            )
        },
        [ DepTicketsColumns.createdAt ]: ( params: TCellRendererWrapperProps ): any => {
            return (
                <CellRendererWrapper { ...params }>
                    <p>
                        { Moments( params?.data?.updatedAt ).format( DATE_TIME_FORMAT ) }
                    </p>
                </CellRendererWrapper>
            )
        },
        [ DepTicketsColumns.userId ]: ( params: TCellRendererWrapperProps ): any => {
            const user = params?.data?.$user ;
            return (
                <CellRendererWrapper { ...params }>
                    {
                        user && (
                            <b>
                                <p className="position">{ user?.position || t("errors.userPositionIsUndeclared") }</p>
                                <br/>{ user?.fullname }
                            </b>
                        )
                    }
                </CellRendererWrapper>
            )
        },
        [ "$toolbar" ]: ( params: TCellRendererWrapperProps ): any => {
            const getRoutePath = get( find( permissions, { ui_section_id: UI.TICKET_PROPERTY } ), "path", "" ).replace( ":ticket_id", params?.data?.id ) ;
            const status = params?.data?.status_id ;
            const isTaskInWorkByMeNow = params?.data?.is_favorite || false ;
            const isHaveExecutor = Boolean( params?.data?.$user?.id ) ;
            const isOwner = Boolean( params?.data?.is_owner ) ;
            const isEditor = params?.data?.owner_id === profile?.$id ;

            function onTakeForWorkHandler() {
                onTakeForWork( params?.data ) ;
            }
            function onRemoveHandler() {
                onRemove( params?.data ) ;
            }
            function onReAssignHandler( variant: string ) {
                switch ( variant ) {
                    case ReAssignersSelector.ITEMS.EMPLOYEE: return onCallEmployeeList && onCallEmployeeList( params.data.id ) ;
                    case ReAssignersSelector.ITEMS.DEP: return onCallDepsList && onCallDepsList( params.data.id ) ;
                }
            }

            return (
                <CellRendererWrapper { ...params }>
                    <div data-value>
                        { ( isEditor || isTaskInWorkByMeNow ) && [
                            TICKET_STATUSES.DRAFT,
                            TICKET_STATUSES.PROCESSED,
                            TICKET_STATUSES.DEP_REQUEST,
                            TICKET_STATUSES.WAIT_FOR_UPDATE,
                            //  TICKET_STATUSES.REJECTED,
                            //  TICKET_STATUSES.SUCCESS,
                            TICKET_STATUSES.SIGNATURE_PENDING

                        ].includes( status ) && (
                            <Permission.Verifycator
                                key={ PERMISSIONS.UPDATE }
                                id={ UI.DEP_TICKETS }
                                name={ PERMISSIONS.UPDATE }
                                value="1"
                                whileFailed={ <EditFilled key={ `${ PERMISSIONS.UPDATE }_disabled` } className={ ClassNames( "button-ticket-update", "disabled" ) } /> }
                            >
                                <Tooltip placement="top" title={ t("common.update") }>
                                    <span>
                                        <Link to={ `/${ getRoutePath }` } state={{ mode: TicketPropertyViewModes.Update }}>
                                            <EditFilled key="update" className={ ClassNames( "button-ticket-update" ) } />
                                        </Link>
                                    </span>
                                </Tooltip>
                            </Permission.Verifycator>
                        )}

                        { isTaskInWorkByMeNow
                        ? ( <>

                                {
                                    ! [ TICKET_STATUSES.REJECTED, TICKET_STATUSES.SUCCESS ].includes( status ) && (
                                        <ReAssignersSelector
                                            t={ t }
                                            onSelect={ onReAssignHandler }
                                            children={
                                                <UsergroupAddOutlined
                                                    key="reassign"
                                                    className="button-ticket-reassign"
                                                />
                                            }/>
                                    )
                                }

                                {
                                    ! [ TICKET_STATUSES.REJECTED, TICKET_STATUSES.SUCCESS ].includes( status ) && (
                                        <Tooltip placement="top" title={ t("DepTickets.goToTheChat") }>
                                            <span>
                                                <Link to={ `/${ getRoutePath }` } state={{ mode: TicketPropertyViewModes.Update, view: TicketPropertyComponent.VIEWS.CHAT }}>
                                                    <WechatOutlined key="chat" className="button-ticket-chat"/>
                                                </Link>
                                            </span>
                                        </Tooltip>
                                    )
                                }

                            </> ) : ( <>
                                { ! isOwner && (
                                    <Tooltip placement="top" title={ t("DepTickets.toWork") }>
                                    <ContactsFilled
                                        key="reassign"
                                        disabled={ isHaveExecutor }
                                        className={ ClassNames( "button-ticket-take-for-work", isHaveExecutor ? "disabled" : "" ) }
                                        onClick={ onTakeForWorkHandler }
                                    />
                                </Tooltip>
                                ) }
                            </>
                        ) }

                        { isEditor && (
                            <>
                                { [ TICKET_STATUSES.DRAFT ].includes( status ) && (
                                    <Permission.Verifycator
                                        key="delete"
                                        id={ UI.DEP_TICKETS }
                                        name={ PERMISSIONS.DELETE }
                                        value="1"
                                        whileFailed={ <DeleteFilled key="remove" className={ ClassNames( "button-ticket-delete", "disabled" ) } /> }
                                    >
                                        <Tooltip placement="top" title={ t("common.remove") }>
                                            <DeleteFilled key="remove" className={ ClassNames( "button-ticket-delete" ) } onClick={ onRemoveHandler } />
                                        </Tooltip>
                                    </Permission.Verifycator>
                                )}
                            </>
                        ) }

                        <Tooltip key="view" placement="top" title={ t("common.view") }>
                            <span>
                                <Link to={ `/${ getRoutePath }` } state={{ mode: TicketPropertyViewModes.ReadOnly }} >
                                    <EyeFilled key="reassign" className="button-ticket-view" />
                                </Link>
                            </span>
                        </Tooltip>

                    </div>
                </CellRendererWrapper>
            )
        },
    }
}