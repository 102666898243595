import Logger from "../../../../../helpers/logger";
import {TToolbarActions, TUsersManagerComponentOptions} from "../../fields.dto";
import Layouts from "./layouts";

export type TDataEditorProviderProps = {
    id: string,
    mode: TToolbarActions,
    data: any,
    meta: any
}

function DataEditorProvider( options: TUsersManagerComponentOptions ) {
    return function( props: TDataEditorProviderProps ) {
        /* debug */ Logger.info( `DataEditorProvider([ options, props ])`, { options, props } ) ;
        const { mode, meta } = props ;
        const LayoutRenderers = Layouts[ mode ] ;
        return LayoutRenderers ? LayoutRenderers({ options, context: props }) : null ;
    }
}
export default DataEditorProvider ;