import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import IIT from "../iit";
import Logger from "../../helpers/logger";
import Signing from './components/Signing';
import { useTranslation } from "react-i18next";
import useIITHook from "../../hooks/useIITHook";
import { getSettings } from "../../data/settings";
import '../../styles/components/files_signer.scss';

const settings = getSettings() ;

function FilesSigner( props ) {
    /* debug */ Logger.info( "FilesSigner([ props ])", props ) ;

    const { t } = useTranslation() ;
    const { files, onRemoteFileUpdate, onSuccess } = props ;
    const options = {
        id: "sign-widget",
        host: settings.iitHistUrl,
        parent: "sign-widget-parent",
    }
    const { initialize, isInitialized, certificates, doSign, reset } = useIITHook( options ) ;
    const [ step, setStep ] = useState(0) ;
    const [ results, addToResult ] = useState([]) ;

    function onSuccessHandler( info ) {
        /* debug */ Logger.success( "FilesSigner() onSuccessHandler([ info, results ])", { info, results } ) ;
        onSuccess( info, results ) ;
    }
    function onRemoteFileUpdateHandler( file ) {
        /* debug */ Logger.info( "FilesSigner() onRemoteFileUpdateHandler([ file ])", file ) ;
        addToResult( _ => [ file, ... _ ] ) ;
        onRemoteFileUpdate( file ) ;
    }

    const items = [
        { title: t("IIT.wizard.selectKey"), component: <span/> },
        { title: t("IIT.wizard.sign"), component: <Signing files={ files } doSign={ doSign } onRemoteFileUpdate={ onRemoteFileUpdateHandler } onSuccess={ onSuccessHandler }/> }
    ]
    const element = items[ step ].component ;

    useEffect( () => { initialize && initialize() ; return () => reset() }, [] ) ;
    useEffect( () => { isInitialized && setStep( s => s + 1 ) }, [ isInitialized ] ) ;

    return (
        <div className="FilesSigner">
            <div className="context">
                <IIT id={ options.parent } init={ initialize } visible={ ! isInitialized } />
                { element }
            </div>
        </div>
    )
}
FilesSigner.defaultProps = {} ;
FilesSigner.propTypes = {
    files: PropTypes.array,
    onRemoteFileUpdate: PropTypes.func,
    onSuccess: PropTypes.func,
    onError: PropTypes.func
} ;

export default FilesSigner ;