import PropTypes from "prop-types";

export const PID = PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
])
export const PTPaginator = PropTypes.shape({
    pageNo: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    perPageLimit: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    search: PropTypes.string,
    sortDirection: PropTypes.string,
    sortBy: PropTypes.string,
    filter: PropTypes.object
})
export const PCompanyUser = PropTypes.shape({
    id: PropTypes.number,
    fullname: PropTypes.string,
    position: PropTypes.string
})
export const PSignVerifier = PropTypes.shape({
    exists: PropTypes.number
})