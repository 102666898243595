import {ToolbarActions, TUsersManagerComponentOptions} from "../../../fields.dto";
import Logger from "../../../../../../helpers/logger";
import {WechatFilled, WechatOutlined} from '@ant-design/icons';
import {Tooltip} from "antd";

const OnlineRenderer = ( options: TUsersManagerComponentOptions ) => ( props: any ) => {
    /* debug */ Logger.info( "OnlineRenderer([ options, props ])", { options, props } ) ;

    const { data } = props ;
    const { id } = data ;
    const { data: variants, onOkay } = options ;
    const isOnline = ( variants || [] ).includes( id ) ;
    const { t } = options ;

    function onClickHandler() {
        onOkay && onOkay({ id, mode: ToolbarActions.SEND_MESSAGE, data })
    }

    return (
        <div className="OnlineRenderer" data-is-online={ isOnline } onClick={ onClickHandler }>
            <Tooltip placement="top" title={ t("Chat.send") }>
            {
                ! isOnline
                ? <WechatOutlined className="disabled" />
                : <WechatFilled />
            }
            </Tooltip>
        </div>
    )

}
export default OnlineRenderer;