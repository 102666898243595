import DefaultCellRenderer from "./renders/default";
import $name from "./renders/$name";
import $access from "./renders/$access";
import {TEndPointsManagerComponentOptions,FIELDS } from "../../fields.dto";
import Logger from "../../../../../helpers/logger";

export default ( options: TEndPointsManagerComponentOptions ) => {
    /* debug */ Logger.info( "EndPointsComponentCellRenderers([ options ])", options ) ;

    return {
        [ FIELDS.NAME ]: $name( options ),
        [ FIELDS.ACCESS ]: $access( options ),
        // ----
        default: DefaultCellRenderer( options ),
    }
}