import { createSlice } from "@reduxjs/toolkit";
import { LanguagesSupported_I } from "../../types/intarfaces";
import Logger from "../../helpers/logger";

const initialState: LanguagesSupported_I = {
    language: null
}

export const i18nSlice = createSlice({
    name: 'i18n',
    initialState,
    reducers: {
        change( state, { payload }) {
            /* debug */ Logger.reducer( "i18nSlice.change([ payload ])", payload ) ;
            state.language = payload;
        },
    }
})

const { reducer, actions } = i18nSlice;
export const i18nActions = actions;
export default reducer;
