import Logger from "../../helpers/logger";
import { createSlice } from "@reduxjs/toolkit";
import {get, keys, set, findIndex} from "lodash";

const initialState = {
    fetched: false,
    fetching: false,
    error: null,
    meta: null,
    data: []
}

export const users = createSlice({
    name: 'users',
    initialState,
    reducers: {
        setFetch( state, { payload:{ fetched, fetching } }) {
            /* debug */ Logger.reducer( "users.setFetch([ fetched, fetching ])", { fetched, fetching }) ;
            state.fetched = fetched ;
            state.fetching = fetching ;
        },
        setData( state, { payload:{ error, data, meta } }) {
            /* debug */ Logger.reducer( "users.setData([ error, data, paginator ])", { error, data }) ;
            state.error = error ;
            state.data = data ;
            state.meta = meta ;
        },
        update( state, { payload:{ error, data } } ) {
            /* debug */ Logger.reducer( "users.update([ state, error, data ])", { state, error, data } ) ;

            state.error = error ;
            const id: number = data?.id ;
            if( id ) {
                const index = findIndex( state.data, ({ id: $id }) => ( $id === id ) ) ;
                /* debug */ Logger.log( "users.update([ index ])", index ) ;
                if( index >= 0 ) set( state.data, index, data ) ;
            }
        },
        create( state, { payload:{ error, data } } ) {
            /* debug */ Logger.reducer( "users.create([ state, error, data ])", { state, error, data } ) ;

            state.error = error ;
            const id: number = data?.id ;
            if( id ) {
                // @ts-ignore
                state.data = [ data, ... state?.data || [] ] ;
            }
        },
        setPassword( state, { payload:{ error, data } } ) {
            state.error = error ;
            const $id: number = +data?.user_id ;
            if( $id ) {
                const index: number = findIndex( state.data, ({ id }) => id === $id ) ;
                if( index >= 0 ) {
                    let $data = get( state.data, index, {} ) ;
                    $data = { ... $data, password: data?.password } ;
                    set( state.data, index, $data ) ;
                }
            }
        },
    }
})

const { reducer, actions } = users ;
export const usersActions = actions;
export default reducer;
