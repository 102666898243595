import React from "react";
import PropTypes from "prop-types";
import ClassNames from "classnames";
import Header from "../header";
import Label from "../label";
import "../../../styles/components/ui-kit/message.scss";

function Message( props ) {
    const { type, header, message, className } = props ;
    return (
        <div className={ ClassNames( "Message", className ) } data-message-type={ type }>
            <div className="wrapper">
                { header && <Header size={ Header.sizes.middle } text={ header } /> }
                { message && <Label value={ message } /> }
            </div>
        </div>
    )
}
Message.types = {
    WARN: "warn",
    ERROR: "error",
    INFO: "info",
    SUCCESS: "success",
    HINT: "hint"
}
Message.defaultProps = {} ;
Message.propTypes = {
    type: PropTypes.string,
    header: PropTypes.string,
    message: PropTypes.string,
    className: PropTypes.string
}

export default Message ;