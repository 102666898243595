import Logger from "../../helpers/logger";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    fetched: false,
    fetching: false,
    error: null,
    data: []
}

export const deps = createSlice({
    name: 'deps',
    initialState,
    reducers: {
        setFetch( state, { payload:{ fetched, fetching } }) {
            /* debug */ Logger.reducer( "deps.setFetch([ fetched, fetching ])", { fetched, fetching }) ;

            state.fetched = fetched ;
            state.fetching = fetching ;
        },
        setData( state, { payload:{ error, data } }) {
            /* debug */ Logger.reducer( "deps.setData([ error, data, paginator ])", { error, data }) ;

            state.error = error ;
            state.data = data ;
        },
    }
})

const { reducer, actions } = deps ;
export const depsActions = actions;
export default reducer;
