import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Logger from "../../../../helpers/logger";
import { Progress, Tooltip } from 'antd';
import axios from 'axios';
import Label from '../../../ui-kit/label';
import { CloseCircleFilled, CloudDownloadOutlined, CloudServerOutlined, LoadingOutlined, SafetyCertificateFilled, SafetyCertificateOutlined } from '@ant-design/icons';
import { getCSSVariableValue } from "../../../../helpers/common";
import { getSettings } from "../../../../data/settings";

const settings = getSettings() ;

function FileDownloader({ file, onDownload }) {
    /* debug */ Logger.info( "FileDownloader() StartSigning([ file ])", file ) ;

    const { name, hash, isSigned, $sign, $error } = file ;
    const [ progress, setProgress ] = useState( 0 ) ;
    const isDownloaded = Boolean( progress === 100 ) ;

    async function download() {
        const { origin } = new URL( settings?.apiHostUrl || "" ) ;
        const fileUrl = `${ origin }/static/${ hash }` ;

        /* debug */ Logger.log( "FileDownloader() download([ fileUrl ])", fileUrl ) ;

        const onDownloadProgress = ( event ) => {
            const progress_ = Math.floor( event.loaded / event.total * 100 ) ;
            setProgress( progress_ ) ;
        }

        let payload, error_ ;
        try { payload = await axios.get(fileUrl, { responseType: 'arraybuffer', onDownloadProgress }) }
        catch ( exception ) { error_ = exception }

        const data = payload?.data || error_ ;

        ! error_
        ? /* debug */ Logger.success( "FileDownloader() download([ data ])", payload )
        : /* error */ Logger.error( "FileDownloader() download([ error ])", error_.message ) ;

        onDownload( file, error_, data ) ;
    }
    function getIconClass() {
        if( isSigned ) return SafetyCertificateFilled ;
        if( $sign ) return SafetyCertificateOutlined ;
        if( ! $error ) return isDownloaded ? CloudDownloadOutlined : CloudServerOutlined ;
        else return CloseCircleFilled ;

        return LoadingOutlined ;
    }

    useEffect( () => { ( async () => ( await download() ))() }, [] ) ;

    const IconClass = getIconClass() ;

    return (
        <div className="FileDownloader" data-error={ Boolean( $error ) }>
            <Tooltip placement="top" title={ name }>
                {
                    ! $error
                    ? (
                        <>
                            {
                                ! isDownloaded
                                ? <IconClass className="file-icon" />
                                : <IconClass className={ $sign ? "file-icon-signed" : "file-icon-downloaded" }/>
                            }
                            { ! isDownloaded && (
                                <Progress
                                    percent={ progress }
                                    size="small"
                                    strokeColor={ getCSSVariableValue("--cssDefaultPrimaryBackgroundColor") }
                                    trailColor={ getCSSVariableValue("--cssDefaultDisabledBackgroundColor") }
                                />
                            ) }
                        </>
                    ) : (
                        <>
                            <IconClass className="file-icon" />
                            <Label className="error" value={ $error?.message }/>
                        </>
                    )
                }
            </Tooltip>
        </div>
    )
}
FileDownloader.defaultProps = {} ;
FileDownloader.propTypes = {
    file: PropTypes.object,
    onDownload: PropTypes.func
}

export default FileDownloader ;