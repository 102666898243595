import React from "react";
import ClassNames from "classnames";
import PropTypes from "prop-types";
import { ButtonArrows } from "../../../data/constants";
import "../../../styles/components/ui-kit/button.scss";

function Button({ className, icon, label, showArrow, arrow, arrowPosition, disabled, onClick, ghost }) {

    function onClickHandler() {
        onClick && onClick() ;
    }

    const arrowElement = (
        ! showArrow
        ? null
        : ( <span className="arrow">
                {
                   `${ arrowPosition === Button.arrowPositions.Right ? ' ' : "" }
                    ${ arrow }
                    ${ arrowPosition === Button.arrowPositions.Left  ? ' ' : "" }`
                }
            </span>
        )
    )

    return (
        <button disabled={ disabled } className={ ClassNames( ghost ? "GhostButton": "Button", className, disabled ? "disabled" : null ) } onClick={ onClickHandler }>
            { icon }
            { showArrow && arrowPosition === Button.arrowPositions.Left  && arrowElement }
            <p className="label">{ label }</p>
            { showArrow && arrowPosition === Button.arrowPositions.Right && arrowElement }
        </button>
    )
}
Button.arrowPositions = {
    Left: "left",
    Right: "right"
}
Button.defaultProps = {
    ghost: false,
    arrow: ButtonArrows.RIGHT,
    arrowPosition: Button.arrowPositions.Right,
    disabled: false,
    showArrow: false,
    label: ""
}
Button.propTypes = {
    className: PropTypes.string,
    icon: PropTypes.element,
    ghost: PropTypes.bool,
    arrow: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.string
    ]),
    arrowPosition: PropTypes.oneOf([
        Button.arrowPositions.Left,
        Button.arrowPositions.Right
    ]),
    disabled: PropTypes.bool,
    showArrow: PropTypes.bool,
    label: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    onClick: PropTypes.func
}

export default Button ;
