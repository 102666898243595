import React from 'react';
import "../../styles/components/application.scss";
import "../../styles/pages/not-found-page.scss";
import { useTranslation } from "react-i18next";

export default function NotFoundPage() {
    const i18n = useTranslation();
    const { t, i18n:{ language } } = i18n ;

    return (
        <div className="Application">
            <div className="notFound">
                <p className="notFound-404">404</p>
                <p className="notFound-caption">{ t( "errorPage.pageNotFound" )}</p>
            </div>
        </div>
    )
}
