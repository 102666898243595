import { useState } from "react";
import Logger from "../helpers/logger";
import EuSign from "../helpers/eusign";

const { EndUser } = EuSign ;

type TIITHook = {
    isInitialized: boolean,
    certificates: any[],
    initialize: Function,
    doSign: Function,
    reset: Function
}
type TIITHookOptions = {
    id: string,
    host: string,
    parent: string
}

export default function useIITHook( options: TIITHookOptions ): TIITHook {

    const [ euSign, setEUSign ] = useState<any>() ;
    const [ isInitialized, setIsInitialized ] = useState( false ) ;
    const [ certificates, setCertificates ] = useState([]) ;

    /* debug */ Logger.info( "useIITHook([ euSign, options ])", { euSign, options, certificates } ) ;

    function _destructor() {
        setIsInitialized( false ) ;
        setCertificates( [/* empty */] ) ;
        euSign && euSign.destroy() ;
        setEUSign( undefined );
    }
    function onConfirmKSPOperation( event: any ) {
        /* debug */ Logger.saga( "useIITHook() onConfirmKSPOperation([ event ])", event ) ;
    }
    async function initialize() {

        /* debug */ Logger.saga( "useIITHook() initialize()" ) ;

        _destructor() ;

        const euSign_ = new EndUser(
            options.parent,
            options.id,
            options.host,
            EndUser.FormType.ReadPKey,
            null
        );

        setEUSign( euSign_ ) ;

        let metadata,
            error ;
        try { metadata = await euSign_.ReadPrivateKey() }
        catch ( exception: any ) { error = exception }

        ! error
        ? /* debug */ Logger.success( "useIITHook() initialize([ privateKey ])", metadata )
        : /* error */ Logger.error( "useIITHook() initialize([ error ])", error?.message )

        if( metadata ) {
            setCertificates( metadata ) ;
            setIsInitialized( true ) ;

            euSign_.AddEventListener( EndUser.EventType.ConfirmKSPOperation, onConfirmKSPOperation );
        }
    }
    async function doSign( data: any ): Promise<any> {

        /* debug */ Logger.saga( "useIITHook() doSign([ euSign, data ])", { euSign, data } ) ;

        const external = false;
        const asBase64String = true;
        const signAlgo = EndUser.SignAlgo.DSTU4145WithGOST34311;
        const signType = EndUser.SignType.CAdES_X_Long;
        const sign = await euSign.SignData( data, external, asBase64String, signAlgo, null, signType ) ;

        /* debug */ Logger.success( "useIITHook() doSign([ sign ])", { sign } ) ;

        return Promise.resolve( sign ) ;
    }

    return { initialize, doSign, isInitialized, certificates, reset: _destructor }
}
